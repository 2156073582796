/* eslint-disable camelcase */
import { AxiosRequestConfig } from 'axios';
import ClusterHotelsMarketsModel from '@/modules/cluster/models/cluster-markets.model';
import ClusterHotelsRatesModel from '@/modules/cluster/models/cluster-rates.model';
import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import { event } from 'vue-gtag';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import ClusterRankingModel from '@/modules/cluster/models/cluster-ranking.model';
import ChainGroup from '../chain/interfaces/chain-group.enum';
import DownloadExcelModel from '../rates/models/download-excel.model';
import UserService, { UserServiceS } from '../user/user.service';
import ClusterPromotionsModel from './models/cluster-promotions.model';
import OpenTelemetryService, { OpenTelemetryServiceS } from '../open-telemetry/open-telemetry.service';
import { LOGTYPE } from '../open-telemetry/constants';
import ClusterDeepAnalysisModel from './models/cluster-deep-analysis.model';
import COMPARE_TO from '../deep-analysis/constants/compare-to-filter.constant';

interface Params {
    [key: string]: number | string;
}

type ChainPair = { group: ChainGroup, value: string } | null;

export const ClusterApiServiceS = Symbol.for('ClusterApiServiceS');
@injectable(ClusterApiServiceS as unknown as string)
export default class ClusterApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(OpenTelemetryServiceS) private openTelemetryService!: OpenTelemetryService;

    private getRatesRequestParams(params: Params, chainPair: ChainPair = null) {
        const { defaultFilters } = this.userService.storeState.user!.settings;
        let newParams = { ...params };

        if (!newParams.los) {
            newParams.los = defaultFilters.los;
        }

        if (chainPair) {
            newParams = {
                ...newParams,
                grouped_by: chainPair.group,
                grouped_by_value: chainPair.value,
            };
        }

        return newParams;
    }

    async getRatesClusterData(params: Params, chainPair: ChainPair = null): Promise<{ data: ClusterHotelsRatesModel[], total: number } | null> {
        const _params = this.getRatesRequestParams(params, chainPair);

        if (chainPair) {
            const { data } = await this.apiService.get('rate/cluster-chain-user', _params);

            const clusterHotels = plainToClass(
                ClusterHotelsRatesModel,
                <ClusterHotelsRatesModel[]> data.data,
                { excludeExtraneousValues: true },
            );

            return {
                data: clusterHotels,
                total: data.total_count,
            };
        }

        const { data } = await this.apiService.get('rate/cluster/v2', _params);
        const clusterHotels = plainToClass(
            ClusterHotelsRatesModel,
            <ClusterHotelsRatesModel[]> data.data,
            { excludeExtraneousValues: true },
        );

        const totalCount = data.total_count;

        return { data: clusterHotels, total: totalCount };
    }

    async getRatesCompsetData(hotelId: number, params: Params, chainPair: ChainPair = null) {
        const _params = this.getRatesRequestParams(params, chainPair);

        // TODO (CI-4859) I think here also should be a separate request for chain level user
        //                in case if `chainPair` was defined

        delete _params.skip;
        delete _params.limit;
        delete _params['sort[hotel_name]'];
        delete _params['sort[score.rate]'];

        const { data } = await this.apiService
            .get(`rate/cluster/hotel-data/${hotelId}`, _params);

        return plainToClass(
            ClusterHotelsRatesModel,
            <ClusterHotelsRatesModel> data,
            { excludeExtraneousValues: true },
        );
    }

    getMarketsRequestParams(params: Params, chainPair: ChainPair = null): Record<string, string | number | boolean> {
        return chainPair
            ? {
                ...params,
                grouped_by: chainPair.group,
                grouped_by_value: chainPair.value,
            }
            : { ...params };
    }

    async getMarketsClusterData(params: Params, chainPair: ChainPair = null): Promise<{ data: ClusterHotelsMarketsModel[], total: number } | null> {
        const newParams = this.getMarketsRequestParams(params, chainPair);

        if (chainPair) {
            const { data } = await this.apiService.get('market/chain-hotels', newParams);

            const clusterHotels = plainToClass(
                ClusterHotelsMarketsModel,
                <ClusterHotelsMarketsModel[]> data,
                { excludeExtraneousValues: true },
            );

            return {
                data: clusterHotels,
                total: data.length,
            };
        }

        const { data } = await this.apiService.get('market/cluster/v2', newParams);

        const clusterHotels = plainToClass(
            ClusterHotelsMarketsModel,
            <ClusterHotelsMarketsModel[]> data.data,
            { excludeExtraneousValues: true },
        );

        const totalCount = data.total_count;

        return { data: clusterHotels, total: totalCount };
    }

    async getMarketsCompsetData(hotelId: number, params: Params, chainPair: ChainPair = null) {
        const _params = this.getMarketsRequestParams(params, chainPair);

        // TODO (CI-4859) I think here also should be a separate request for chain level user
        //                in case if `chainPair` was defined

        delete _params.skip;
        delete _params.limit;
        delete _params['sort[score.mv]'];

        const { data } = await this.apiService
            .get(`market/cluster/hotel-data/${hotelId}`, _params);

        return plainToClass(
            ClusterHotelsMarketsModel,
            <ClusterHotelsMarketsModel> data,
            { excludeExtraneousValues: true },
        );
    }

    async getGuestReviewsData(params: Params): Promise<{ data: ClusterRankingModel[], total: number } | null> {
        const paramsData = { ...params, isVisibilityPage: false };
        const { data } = await this.apiService.get('market/cluster/v2', paramsData);

        const clusterHotels = plainToClass(
            ClusterRankingModel,
            <ClusterRankingModel[]> data.data,
            { excludeExtraneousValues: true },
        );

        const totalCount = data.total_count;

        return { data: clusterHotels, total: totalCount };
    }

    async getRankingCompsetData(hotelId: number, params: Params, chainPair: ChainPair = null) {
        const _params = this.getMarketsRequestParams(params, chainPair);

        // TODO (CI-4859) I think here also should be a separate request for chain level user
        //                in case if `chainPair` was defined

        delete _params.skip;
        delete _params.limit;
        _params.isVisibilityPage = false;

        const { data } = await this.apiService
            .get(`market/cluster/hotel-data/${hotelId}`, _params);

        return plainToClass(
            ClusterRankingModel,
            <ClusterRankingModel> data,
            { excludeExtraneousValues: true },
        );
    }

    async getPromotionHotels(params: Params) {
        const {
            provider_name, year, month, ...restParams
        } = params;
        const { data } = await this.apiService
            .get(`promotion-detections/cluster/v2/${provider_name || 'booking'}/${year}/${month}`, restParams);

        const hotels = plainToClass(
            ClusterPromotionsModel,
            <ClusterPromotionsModel[]> data.data,
            { excludeExtraneousValues: true },
        );

        return {
            data: hotels,
            total: data.total_count,
        };
    }

    async getPromotionsDocument(hotelId: number, params: Params) {
        const {
            provider_name, year, month, ...restParams
        } = params;

        delete restParams.skip;
        delete restParams.limit;

        const { data } = await this.apiService
            .get(`promotion-detections/cluster/v2/hotel-data/${hotelId}/${provider_name}/${year}/${month}`, restParams);

        return plainToClass(ClusterPromotionsModel, <ClusterPromotionsModel> data, { excludeExtraneousValues: true });
    }

    async getDeepCompsetData(params: Params) {
        const {
            skip, limit, hotel_name, sort,
        } = params;
        const body = { skip, limit, sort };
        const { data } = await this.apiService.post(`deep_compset/cluster/v2${(hotel_name ? `?hotel_name=${hotel_name}` : '')}`, body);

        const hotels = plainToClass(
            ClusterDeepAnalysisModel,
            <ClusterDeepAnalysisModel[]> data.data,
            { excludeExtraneousValues: true },
        );

        return {
            data: hotels,
            total: data.total_pages,
        };
    }

    async getDeepCompsetDocument(hotelId: number) {
        const { data } = await this.apiService.get(`deep_compset/cluster/v2/hotel-data/${hotelId}`);

        return plainToClass(
            ClusterDeepAnalysisModel,
            <ClusterDeepAnalysisModel> data,
            { excludeExtraneousValues: true },
        );
    }

    async getMarketsExcelDocument(provider: string, startDate: string, endDate: string) {
        event('download_excel', {
            userId: this.userService.id,
            chainId: this.userService.chainId,
        });

        this.openTelemetryService.startSpan({ name: 'market-cluster', prefix: LOGTYPE.DOWNLOAD });
        const { data } = await this.apiService.get(
            `market/cluster-excel/${provider}/${startDate}/${endDate}`,
            {},
        );
        this.openTelemetryService.endSpan({ name: 'market-cluster', prefix: LOGTYPE.DOWNLOAD }, { sendLogs: true });
        if (!data) {
            return null;
        }

        return plainToClass(DownloadExcelModel, <DownloadExcelModel> data, { excludeExtraneousValues: true });
    }

    async getGuestReviewsExcelDocument() {
        event('download_excel', {
            userId: this.userService.id,
            chainId: this.userService.chainId,
        });

        this.openTelemetryService.startSpan({ name: 'ranking-cluster', prefix: LOGTYPE.DOWNLOAD });
        const { data } = await this.apiService.post(
            'market/excel-cluster-guest-reviews',
            {},
        );
        this.openTelemetryService.endSpan({ name: 'ranking-cluster', prefix: LOGTYPE.DOWNLOAD }, { sendLogs: true });

        if (!data) {
            return null;
        }

        return plainToClass(DownloadExcelModel, <DownloadExcelModel> data, { excludeExtraneousValues: true });
    }

    async getDeepAnalysisClusterExcel(settings: Record<string, any>) {
        const url = '/insights/report/cluster-excel-deep_compset_analysis';
        const [scanType, timePeriod] = settings.showBy.split('.');
        const body = {
            provider_name: settings.provider,
            scanType: scanType === 'stay' ? 'next' : 'last',
            timePeriod: parseInt(timePeriod, 10),
            compateTo: settings.compareTo === COMPARE_TO.COMPSET_AVG ? 'comp_set' : 'last_year',
        };
        const config: AxiosRequestConfig = { responseType: 'blob' };

        const { data } = await this.apiService.post(url, body, config);

        return data as Blob;
    }

    async getClusterPos() {
        const { data } = await this.apiService.get('/users/available-pos');
        if (!data) {
            return [] as string[];
        }

        return data as string[];
    }

    async getClusterExcelDocument(provider: string, period:string[], columns: { name: string, value: boolean }[], sendToEmail?: boolean) {
        const query = (columns as {
            name: string;
            value: boolean;
        }[]).reduce((acc, column) => {
            let param = !acc.length ? '?' : '&';
            param += `columnsOptions[${column.name}]=${column.value}`;
            return acc + param;
        }, '');

        let url = `rate/cluster-excel/${provider}/${period[0]}/${period[1]}${query}`;
        if (sendToEmail) {
            url += '&send_to_email=true';
        }

        event('download_excel', {
            userId: this.userService.id,
            chainId: this.userService.chainId,
        });

        const name = sendToEmail ? 'rates-cluster-email' : 'rates-cluster';
        this.openTelemetryService.startSpan({ name, prefix: LOGTYPE.DOWNLOAD });
        const res = await this.apiService.get(url, {}).catch(() => null);
        this.openTelemetryService.endSpan({ name, prefix: LOGTYPE.DOWNLOAD }, { sendLogs: true });

        if (!res || !res.data) {
            return null;
        }

        return plainToClass(DownloadExcelModel, <DownloadExcelModel>res.data, { excludeExtraneousValues: true });
    }
}
