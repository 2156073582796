import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import HotelsRoomTypeModel from '@/modules/rooms-type-manager/models/hotels-room-type.model';
import HotelsRoomTypeStore from './store/hotels-room-type.store';
import RoomsRMSStateModel from './models/rooms-rms-state.model';

export const RoomsTypeManagerApiServiceS = Symbol.for('RoomsTypeManagerApiServiceS');

export interface RMSStatePayload {
    hotelId: number;
    rooms: number[];
}

@injectable(RoomsTypeManagerApiServiceS as unknown as string)
export default class RoomsTypeManagerApiService {
    @Inject(ApiServiceS)
    private apiService!: ApiService;

    async getHotelsRoomType(): Promise<HotelsRoomTypeModel | null> {
        const response = await this.apiService.get('/users/competitors-rooms-types-mapping');

        if (!response) {
            return null;
        }

        return plainToClass(HotelsRoomTypeModel, { providers: response.data }, { excludeExtraneousValues: true });
    }

    async updateHotelsRoomType(mainHotelId: number, changes: HotelsRoomTypeStore['changeData']) {
        await this.apiService.put(
            `/rooms/update-rooms-types-mapping/${mainHotelId}`,
            { mappings: changes },
        );
    }

    async getArchivedRoomList(hotelId: number) {
        const { data } = await this.apiService
            .get(`/users/ignored_rooms_names?fornovaIds=${hotelId}`);

        if (!data) {
            return null;
        }

        return data;
    }

    async updateArchivedRoomList(chainId: string, hotelId: number, newRoomList: string[]) {
        const { data } = await this.apiService.put('/users/ignored_rooms_names', {
            ignored_rooms: {
                [hotelId]: newRoomList,
            },
            chainId,
        });

        if (!data) return {} as Record<number, string[]>;

        return data.ignored_rooms as Record<number, string[]>;
    }

    async getRoomsRMSState(mainHotelId: number) {
        const { data } = await this.apiService
            .get(`/hotels/rms/get-eliminated-rooms/${mainHotelId}`);

        if (!data) return [] as RoomsRMSStateModel[];

        return plainToClass(RoomsRMSStateModel, <RoomsRMSStateModel[]> data, { excludeExtraneousValues: true });
    }

    async eliminateRoomsRMS(mainHotelId: number, newStates: RMSStatePayload[]) {
        await this.apiService
            .put(`/hotels/set-eliminated-rms-rooms/${mainHotelId}`, newStates);
    }

    async restoreRoomsRMS(mainHotelId: number, newStates: RMSStatePayload[]) {
        await this.apiService
            .put(`/hotels/revert-eliminated-rms-rooms/${mainHotelId}`, newStates);
    }

    async getMappingExcel(hotelId: number, provider: string) {
        const res = await this.apiService.get(
            `/report/room-type-manager?fornovaId=${hotelId}&providerName=${provider}`,
            {},
            { responseType: 'blob' },
        );
        return res;
    }
}
