import { injectable, Inject } from 'inversify-props';
import _ from 'lodash';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import InsightsStore from './insights.store';
import HelperService, { HelperServiceS } from '../common/services/helper.service';
import InsightsApiService, { InsightsApiServiceS } from './insights-api.service';
import UserService, { UserServiceS } from '../user/user.service';
import CompsetsService, { CompsetsServiceS } from '../compsets/compsets.service';
import Item from '../common/interfaces/item.interface';
import InsightGroupModel from './models/insight-group.model';
import { InsightDayRoute, InsightType } from './constants';
import InsightDayRateModel from './models/insight-day-rate.model';
import InsightDayRateTrendModel from './models/insight-day-rate-trend.model';
import InsightDayMarketModel from './models/insight-day-market.model';
import InsightDayDeviceModel from './models/insight-day-device.model';
import { Insight, RoomsInsight } from './types';

const INSIGHTS_LIMIT = 36;

interface InsightsPublicInterface {
    /** Getter with dynamic loading to return all insight type items. */
    insightTypeItems: Item<string>[];

    /** Getter to return providers from all compsets. They are collected in the constructor. */
    providerItems: Item<string>[];

    /** Getter with dynamic loading to get insights by filters from the store. */
    groups: InsightGroupModel<Insight>[];

    /** Triggers lazy loading */
    loadMoreInsights: () => void

    /** Getter to return settings of hotel level insight page. */
    settings: {
        dateRange: number;
        insightTypes: Item<string>[];
        providers: Item<string>[];
    };

    /** Method to set setting without reactivity issues. */
    setSettingValue: <T>(name: keyof InsightsStore['settings'], value: T) => void;

    /** Resets day data loading set day data to null and if insight id provided, it is set as new insightId */
    resetDayData: (insightId?: string) => void

    /** Returns current insight id from the store */
    dayInsightId: string | null;

    /** Getter with dynamic loading to get specific insight from the group */
    dayData: InsightDayRateModel | InsightDayMarketModel | InsightDayDeviceModel | InsightDayRateTrendModel | null;

    /** Getter with dynamic loading to get specific insights group */
    dayGroup: InsightGroupModel<Insight | RoomsInsight> | null;

    /** Is insight filters loading. */
    isTypesLoading: boolean;

    /** Is insight document loading. */
    isInsightLoading: boolean;

    /** Is insight lazy loading working. */
    isInsightLoadingMore: boolean;

    /** Is insight day popup loading */
    isDayDataLoading: boolean;

    /** Set group viewed */
    setGroupViewed: (groupId: string) => Promise<boolean>;

    /** Set group unviewed */
    setGroupUnviewed: (groupId: string) => Promise<boolean>;

    /** Permanently delete group */
    deleteGroup: (groupId: string) => Promise<boolean>;
}

// TODO split popup functionality to separate service
export const InsightsServiceS = Symbol.for('InsightsServiceS');
@injectable(InsightsServiceS as unknown as string)
export default class InsightsService implements Stateable, InsightsPublicInterface {
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(InsightsApiServiceS) private insightsApiService!: InsightsApiService;
    @Inject(UserServiceS) private userSerivce!: UserService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(ProvidersServiceS) providersService!: ProvidersService;

    readonly storeState: InsightsStore = this.storeFacade.getState('InsightsStore');

    constructor() {
        // Set providers, in case if comsets already loaded on another page
        this.setProviders();

        // Set providers if compest changes (change view as) or page updates
        // TODO for the cluster level: make sure that watcher hits, when view as changes to cluster
        this.storeFacade.watch(
            () => this.compsetsService.compsets,
            () => this.setProviders(),
        );

        // Lazy loading
        this.storeFacade.watch(
            () => this.storeState.isLoadingMoreInsights,
            () => {
                if (!this.storeState.isLoadingMoreInsights) {
                    return;
                }
                this.storeState.insightsLoading.reset();
            },
        );

        // First loading, filter change
        this.storeFacade.watch(
            () => [this.storeState.settings, this.userSerivce.currentHotelId],
            (n, o) => {
                this.storeState.groups = [];
                this.storeState.count = 0;
                this.storeState.skip = 0;

                const { insightTypes, providers } = this.storeState.settings;
                if (!insightTypes.length || !providers.length || this.compsetsService.isLoading) {
                    return;
                }

                // Sometimes watcher hits even if old and new value is the same
                if (_.isEqual(o, n)) {
                    return;
                }

                this.storeState.insightsLoading.reset();
            },
        );
    }

    get insightTypeItems() {
        this.helperService.dynamicLoading(this.storeState.typesLoading, async () => {
            const insightTypes = await this.insightsApiService.getInsightTypes();
            this.storeState.insightTypeItems = insightTypes;
            this.setSettingValue('insightTypes', insightTypes);
            return true;
        });

        return this.storeState.insightTypeItems;
    }

    // Get providers from all compsets
    private setProviders() {
        const providers = this.compsetsService.compsets
            ? Array.from(this.compsetsService.compsets.reduce((acc, c) => {
                c.rateProviders.forEach(p => acc.add(p));
                c.marketProviders.forEach(p => acc.add(p));
                return acc;
            }, new Set<string>())).filter(p => p !== 'all' && p !== 'cheapest')
            : [];

        const { data: allProviders } = this.providersService;
        const providerItems = providers.map(p => ({
            name: allProviders?.find(providerData => providerData.name === p)?.label || p,
            value: p,
        }));

        this.storeState.providerItems = providerItems;
        this.setSettingValue('providers', providerItems);
    }

    get providerItems() {
        return this.storeState.providerItems;
    }

    get groups() {
        if (this.compsetsService.storeState.loading.isLoading()
            || this.storeState.typesLoading.isLoading()
            || this.userSerivce.storeState.loading.isLoading()) {
            return this.storeState.groups;
        }

        this.helperService.dynamicLoading(this.storeState.insightsLoading, async () => {
            const { providers, insightTypes, dateRange } = this.storeState.settings;

            if (!providers.length || !insightTypes.length || !this.userSerivce.currentHotelId) {
                return false;
            }

            const fornovaId = this.userSerivce.currentHotelId;
            const settings = {
                fornovaId,
                providers: providers.map(p => p.value),
                insightTypes: insightTypes.map(t => t.value),
                dateRange,
            };

            const pagination = { skip: this.storeState.skip, limit: INSIGHTS_LIMIT };
            const res = await this.insightsApiService.getInsightGroups(settings, pagination);

            this.storeState.groups = [...this.storeState.groups, ...res.data];
            this.storeState.isLoadingMoreInsights = false;

            this.storeState.count = res.count;
            this.storeState.skip += INSIGHTS_LIMIT;

            return true;
        });

        return this.storeState.groups;
    }

    get count() {
        return this.storeState.count;
    }

    get skip() {
        return this.storeState.skip;
    }

    get settings() {
        return this.storeState.settings;
    }

    setSettingValue<T>(name: keyof InsightsStore['settings'], value: T) {
        this.storeState.settings = {
            ...this.storeState.settings,
            [name]: value,
        };
    }

    loadMoreInsights() {
        this.storeState.isLoadingMoreInsights = true;
    }

    private async loadDayPopupData() {
        const params = window.location.pathname.split('/');
        const groupId = params[params.length - 1];

        const { dayDataGroupId: lastGroupId } = this.storeState;

        if (lastGroupId !== groupId || !this.storeState.dayGroup) {
            this.storeState.dayGroup = null;
            const group = await this.insightsApiService.getInsightGroup(groupId);

            // Keep only insight with provider from filter
            if (this.settings.providers.length) {
                const insIds = Object.keys(group.insights);
                const providerValues = this.settings.providers.map(p => p.value);
                insIds.forEach(id => {
                    if (!providerValues.includes(group.insights[id].provider)) {
                        delete group.insights[id];
                    }
                });
            }

            this.storeState.dayGroup = group;
        }

        if (!this.storeState.dayGroup) {
            return false;
        }

        // If no insight id in the store or insight id is from another group then take first insight id from the current group
        if (!this.storeState.dayDataInsightId || !Object.keys(this.storeState.dayGroup.insights).includes(this.storeState.dayDataInsightId)) {
            [this.storeState.dayDataInsightId] = Object.keys(this.storeState.dayGroup.insights);
        }

        const { type, date } = this.storeState.dayGroup;

        // No need to load insights for rooms and market leader. All info is in group.
        if (type === InsightType.ROOMS || type === InsightType.MARKET_LEADER) {
            return true;
        }

        const { dayDataInsightId: insightId } = this.storeState;
        const payload = { groupId, date, insightId };

        switch (type) {
            case InsightType.RATE:
            case InsightType.LOS:
                this.storeState.dayData = await this.insightsApiService.getDayInsights(InsightDayRateModel, payload);
                break;
            case InsightType.VISIBILITY:
            case InsightType.PROMOTION:
                this.storeState.dayData = await this.insightsApiService.getDayInsights(InsightDayMarketModel, payload);
                break;
            case InsightType.DEVICE:
                this.storeState.dayData = await this.insightsApiService.getDayInsights(InsightDayDeviceModel, payload);
                break;
            case InsightType.RATE_TRENDS:
                this.storeState.dayData = await this.insightsApiService.getDayInsights(InsightDayRateTrendModel, payload);
                break;
            default:
                throw new Error(`Day data functionality not implemented for ${type} insight type.`);
        }

        return true;
    }

    resetDayData(insightId?: string) {
        this.storeState.dayDataLoading.reset();
        this.storeState.dayData = null;
        if (insightId) {
            this.storeState.dayDataInsightId = insightId;
        }
    }

    get dayInsightId() {
        return this.storeState.dayDataInsightId;
    }

    get dayData() {
        this.helperService.dynamicLoading(this.storeState.dayDataLoading, this.loadDayPopupData.bind(this));
        return this.storeState.dayData;
    }

    get dayGroup() {
        this.helperService.dynamicLoading(this.storeState.dayDataLoading, this.loadDayPopupData.bind(this));
        return this.storeState.dayGroup;
    }

    get isTypesLoading() {
        return !this.storeState.typesLoading.isInitialized || this.storeState.typesLoading.isLoading();
    }

    get isInsightLoading() {
        return !this.storeState.insightsLoading.isInitialized
            || this.storeState.insightsLoading.isLoading()
            || this.isTypesLoading
            || this.compsetsService.isLoading;
    }

    get isInsightInitLoading() {
        return this.isInsightLoading && !this.isInsightLoadingMore;
    }

    get isInsightLoadingMore() {
        return this.isInsightLoading && this.storeState.isLoadingMoreInsights;
    }

    get isDayDataLoading() {
        return !this.storeState.dayDataLoading.isInitialized || this.storeState.dayDataLoading.isLoading();
    }

    async setGroupViewed(groupId: string) {
        const res = await this.insightsApiService.setGroupViewed(groupId);
        const index = this.storeState.groups.findIndex(grp => grp.groupId === groupId);
        if (index === -1) {
            return res;
        }
        this.storeState.groups[index].read = true;
        return res;
    }

    async setGroupUnviewed(groupId: string) {
        const res = await this.insightsApiService.setGroupUnviewed(groupId);
        const index = this.storeState.groups.findIndex(grp => grp.groupId === groupId);
        if (index === -1) {
            return res;
        }
        this.storeState.groups[index].read = false;
        return res;
    }

    async deleteGroup(groupId: string) {
        const res = await this.insightsApiService.deleteGroup(groupId);
        const index = this.storeState.groups.findIndex(grp => grp.groupId === groupId);
        if (index === -1) {
            return res;
        }
        this.storeState.groups[index].deleted = true;
        this.storeState.groups[index].read = true;
        this.storeState.groups = [...this.storeState.groups];
        return res;
    }
}
