import { Module, VuexModule } from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import Item from '../common/interfaces/item.interface';
import InsightDayRateModel from './models/insight-day-rate.model';
import InsightDayMarketModel from './models/insight-day-market.model';
import InsightDayDeviceModel from './models/insight-day-device.model';
import InsightDayRateTrendModel from './models/insight-day-rate-trend.model';
import InsightGroupModel from './models/insight-group.model';
import { Insight, RoomsInsight } from './types';

@Module
export default class InsightsStore extends VuexModule {
    settings = {
        dateRange: 1,
        insightTypes: [] as Item<string>[],
        providers: [] as Item<string>[],
    };

    typesLoading: LoadingModel = new LoadingModel();
    insightTypeItems = [] as Item<string>[];

    insightsLoading: LoadingModel = new LoadingModel();
    groups: InsightGroupModel<Insight>[] = [];
    count: number = 0;
    loadedCount: number = 0;
    skip: number = 0;
    isLoadingMoreInsights = false;

    providerItems: Item<string>[] = [];

    // Data for a opened day popup
    dayGroup: InsightGroupModel<Insight | RoomsInsight> | null = null;
    dayData: InsightDayRateModel | InsightDayMarketModel | InsightDayDeviceModel | InsightDayRateTrendModel | null = null;
    dayDataGroupId: string | null = null;
    dayDataInsightId: string | null = null;
    dayDataLoading = new LoadingModel();
}
