import { Inject, injectable } from 'inversify-props';
import type Day from '@/modules/common/types/day.type';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';

export const BookingPriceServiceS = Symbol.for('BookingPriceServiceS');
@injectable(BookingPriceServiceS as unknown as string)
export default class BookingPriceService {
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;
    @Inject(RatesServiceS) private ratesService!: RatesService;

    bookingPrice(day: Day, hotelId: number): boolean {
        const provider = this.ratesFiltersService.currentProvider;
        const price = this.ratesService.getPrice(day, hotelId);

        if (!price || provider !== 'booking') {
            return false;
        }

        const jsonData = JSON.stringify({ day, price, hotelId });
        // eslint-disable-next-line no-bitwise
        const hash = jsonData.split('').reduce((prevHash, currVal) => (((prevHash << 5) - prevHash) + currVal.charCodeAt(0)) | 0, 0);

        return Boolean(hash % 7 === 0);
    }
}
