import { validate, ValidationError } from 'class-validator';
import { Inject, injectable } from 'inversify-props';
import * as _ from 'lodash';
import { $enum } from 'ts-enum-util';

import BadRequestException from '@/modules/common/modules/exception-handler/exceptions/bad-request.exception';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import type Day from '@/modules/common/types/day.type';
import type Month from '@/modules/common/types/month.type';
import type Year from '@/modules/common/types/year.type';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import EVENT_STATUS_SETTINGS from '@/modules/events/constants/event-statuses.constant';
import EVENT_TYPE_SETTINGS from '@/modules/events/constants/event-types-settings.constant';
import EventsApiService, { EventsApiServiceS } from '@/modules/events/events-api.service';
import EventsFilterService, { EventsFilterServiceS } from '@/modules/events/events-filter.service';
import IDayEvent from '@/modules/events/interfaces/day-event.interface';
import IGetEventParams from '@/modules/events/interfaces/get-event.interface';
import EventsModel from '@/modules/events/models/events.model';
import EventsStore from '@/modules/events/store/events.store';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import { checkDateIsFuture } from '@/modules/cars/utils/check-month-is-same';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import CarsFiltersService, { CarsFiltersServiceS } from '../cars/cars-filters.service';
import ClusterService, { ClusterServiceS } from '../cluster/cluster.service';
import CacheService, { CacheServiceS, EVENTS_METHODS, MODULES } from '../common/services/cache/cache.service';
import EventCollection from './models/event-collection.model';
import EventGroup from './interfaces/event-group.enum';

interface EventsManagerPublicInterface {
    /**
     * Returns NOT filtered events for provided date.
     * @param date
     */
    getEventCollection(date: Date): EventCollection | null

    /**
     * Returns filtered ALL events for provided date.
     * Holidays filtered by Holiday filter.
     * My, suggested and chain events filtered by Events filter but not by Status.
     * @param date
     */
    getEventsByDate(date: Date): EventsModel[] | null
}

export const EventsManagerServiceS = Symbol.for('EventsManagerServiceS');
@injectable(EventsManagerServiceS as unknown as string)
export default class EventsManagerService implements EventsManagerPublicInterface {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(EventsApiServiceS) private eventsApiService!: EventsApiService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(EventsFilterServiceS) private eventsFilterService!: EventsFilterService;
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(CacheServiceS) private cacheService!: CacheService;

    readonly storeState: EventsStore = this.storeFacade.getState('EventsStore');

    constructor() {
        this.storeFacade.watch(() => [
            this.documentFiltersService.storeState.settings.month,
            this.documentFiltersService.storeState.settings.year,
        ], ((newValue, oldValue) => {
            const [newMonth, newYear] = newValue;
            const [oldMonth, oldYear] = oldValue;

            if (newMonth === oldMonth
                && newYear === oldYear) {
                return;
            }

            this.storeState.loading.reset.call(this.storeState.loading);
        }));

        if (this.userService.isClusterUser || this.userService.isChainUser) {
            this.storeFacade.watch(() => [
                this.userService.viewAs,
                this.userService.currentHotelId,
            ], ((newValue, oldValue) => {
                const [oldViewAs, oldCurrentHotelId] = oldValue;
                const [newViewAs, newCurrentHotelId] = newValue;

                if (oldCurrentHotelId === newCurrentHotelId
                    && oldViewAs === newViewAs) {
                    return;
                }

                this.storeState.loading.reset.call(this.storeState.loading);
            }));
        }

        if (this.userService.isCarUser) {
            this.storeFacade.watch(() => this.carsFiltersService.settings, () => (
                this.storeState.loading.reset.call(this.storeState.loading)
            ));

            this.storeFacade.watch(() => [
                this.carsService.storeState.settings.pos,
                this.carsService.storeState.settings.isLoadEventByPOS,
            ], async () => (
                this.storeState.loadingByPOS.reset.call(this.storeState.loadingByPOS)
            ));
        }

        this.getEventsByMonth = this.cacheService
            .memorize(MODULES.EVENTS, EVENTS_METHODS.getEventsByMonth, this.getEventsByMonth.bind(this), 1);
    }

    get eventsMap() {
        const { isLoadEventByPOS } = this.carsService.storeState.settings;

        if (isLoadEventByPOS) {
            this.helperService.dynamicLoading(this.storeState.loading, this.loadPOSData.bind(this));
        } else {
            this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        }

        return this.storeState.eventMap;
    }

    get settings() {
        return this.storeState.settings;
    }

    get isLoading() {
        if (this.userService.isCarUser) {
            this.carsFiltersService.initPos();
        }

        return this.storeState.loading.isLoading();
    }

    /**
     * For CI and Cars (in events manager page)
     */
    private async loadData(): Promise<boolean> {
        const { settings } = this.documentFiltersService.storeState;
        const { previousMonthAndYear, nextMonthAndYear } = this.documentFiltersService;
        const { currentCompset } = this.compsetsService;
        const { isViewAsHotel, isChainOrClusterUser, isHotelUser } = this.userService;
        const { isCarUser } = this.userService;

        const isHotelLevel = isViewAsHotel || (!isChainOrClusterUser && isHotelUser);
        const isCompsetDefined = !!currentCompset;

        if (!isCarUser && isHotelLevel && !isCompsetDefined) {
            await this.compsetsService.storeState.loading.whenLoadingFinished();
        }

        this.resetEventMap();

        const [currentMonthEvents, previousMonthEvents, nextMonthEvents] = await Promise.all([
            this.loadMonthEvents((settings.month) as Month, settings.year),
            this.loadMonthEvents((previousMonthAndYear.month) as Month, previousMonthAndYear.year),
            this.loadMonthEvents((nextMonthAndYear.month) as Month, nextMonthAndYear.year),
        ]);

        this.storeState.currentMonthEvents = currentMonthEvents;
        this.storeState.previousMonthEvents = previousMonthEvents;
        this.storeState.nextMonthEvents = nextMonthEvents;

        await this.loadDefaultCountries();

        return true;
    }

    private async loadDefaultCountries() {
        const { isChainOrClusterUser, isCarUser, viewAs } = this.userService;
        const isClusterUser = isChainOrClusterUser && viewAs !== 'hotel';

        let defaultCountryCodes = [] as string[];

        if (isCarUser) {
            defaultCountryCodes = this.carsFiltersService.settings.pos || [];
        } else {
            defaultCountryCodes = isClusterUser
                ? await this.clusterService.getPosList()
                : this.compsetsService.poses;
        }

        this.eventsFilterService.setSettings({ countries: defaultCountryCodes || undefined });
    }

    /**
     * This is only for CARS
     */
    private async loadPOSData(): Promise<boolean> {
        const { settings } = this.documentFiltersService.storeState;
        const { previousMonthAndYear, nextMonthAndYear } = this.documentFiltersService;
        const { settings: carsSettings } = this.carsService.storeState;

        const { isLoadEventByPOS } = carsSettings;
        let { pos: posList } = this.carsFiltersService.settings;

        posList = posList || [];

        carsSettings.pos = carsSettings.pos || posList[0] || null;

        const { pos } = carsSettings;

        if (!pos || !isLoadEventByPOS) {
            return false;
        }

        this.resetEventMap();

        const [currentMonthEvents, previousMonthEvents, nextMonthEvents] = await Promise.all([
            this.loadMonthEvents((settings.month) as Month, settings.year, [pos]),
            this.loadMonthEvents((previousMonthAndYear.month) as Month, previousMonthAndYear.year, [pos]),
            this.loadMonthEvents((nextMonthAndYear.month) as Month, nextMonthAndYear.year, [pos]),
        ]);

        if (this.carsService.storeState.settings.pos) {
            this.storeState.currentMonthEventsByPOS = currentMonthEvents;
            this.storeState.previousMonthEventsByPOS = previousMonthEvents;
            this.storeState.nextMonthEventsByPOS = nextMonthEvents;
        }

        await this.loadDefaultCountries();

        return true;
    }

    /**
     * Collects loaded events into `EventCollection` and places in the store for each day
     */
    private registerEventsInCollections(events: EventsModel[], reactive = false) {
        const { eventMap, eventDictionary } = this.storeState;

        events.forEach(event => {
            const { startDate, endDate } = event;
            const counter = new Date(endDate!);

            if (!eventDictionary[event.id!]) {
                eventDictionary[event.id!] = event;
            } else {
                return;
            }

            while (counter >= startDate!) {
                const date = new Date(counter);
                date.setDate(date.getDate() - 1);

                const iso = date.toISOString().split('T')[0];
                const group = event.group as ((typeof EventGroup)[keyof typeof EventGroup]);

                eventMap[iso] = eventMap[iso] || new EventCollection(date);

                const collection = eventMap[iso];

                collection[group].push(event);

                if (!event.collections.includes(collection)) {
                    event.collections.push(collection);
                }

                counter.setDate(counter.getDate() - 1);
            }
        });

        if (reactive) {
            this.storeState.eventMap = {
                ...this.storeState.eventMap,
            };

            this.storeState.eventDictionary = {
                ...this.storeState.eventDictionary,
            };
        }
    }

    private resetEventMap() {
        this.storeState.eventMap = {};
        this.storeState.eventDictionary = {};
    }

    private async loadMonthEvents(month: Month, year: Year, pos?: string[]): Promise<EventCollection> {
        const { isViewAsChain, isViewAsCluster, currentHotelId } = this.userService;
        const monthEvents = new EventCollection();

        if (!currentHotelId) {
            return monthEvents;
        }

        const eventPromises = [
            this.eventsApiService.getHolidaysEvents((month + 1) as Month, year, pos),
        ];

        let holidayEvents = null as EventsModel[] | null;
        let myEvents = null as EventsModel[] | null;
        let chainEvents = null as EventsModel[] | null;

        if (isViewAsChain || isViewAsCluster) {
            const { chainId, viewAs } = this.userService;

            eventPromises.push(this.eventsApiService.getChainEvents((month + 1) as Month, year, chainId!, viewAs!));
            [holidayEvents, chainEvents] = await Promise.all(eventPromises);
        } else {
            eventPromises.push(
                this.eventsApiService.getMyEvents(currentHotelId, (month + 1) as Month, year),
            );
            [holidayEvents, myEvents] = await Promise.all(eventPromises);
        }

        monthEvents.holiday = holidayEvents || [];
        monthEvents.my = myEvents || [];
        monthEvents.chain = chainEvents || [];

        this.registerEventsInCollections(holidayEvents || []);
        this.registerEventsInCollections(myEvents || []);
        this.registerEventsInCollections(chainEvents || [], true);

        return monthEvents;
    }

    private events(month: Month, year: Year): EventCollection {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));

        const { month: selectedMonth, year: selectedYear } = this.documentFiltersService.storeState.settings;
        const dateType = checkDateIsFuture(month, year, selectedMonth, selectedYear);

        switch (dateType) {
            case -1:
                return this.storeState.previousMonthEvents;
            case 0:
                return this.storeState.currentMonthEvents;
            default:
                return this.storeState.nextMonthEvents;
        }
    }

    private eventsByPOS(month: Month, year: Year): EventCollection {
        this.helperService.dynamicLoading(this.storeState.loadingByPOS, this.loadPOSData.bind(this));

        const { month: selectedMonth, year: selectedYear } = this.documentFiltersService.storeState.settings;

        const dateType = checkDateIsFuture(month, year, selectedMonth, selectedYear);
        switch (dateType) {
            case -1:
                return this.storeState.previousMonthEventsByPOS;
            case 0:
                return this.storeState.currentMonthEventsByPOS;
            default:
                return this.storeState.nextMonthEventsByPOS;
        }
    }

    getMyEventsByMonth(m: Month, y: Year, isPOSFilter: boolean = false) {
        const s = this.storeState;

        const { types, status } = s.settings;
        if (status === EVENT_STATUS_SETTINGS.SUGGESTED) {
            return [];
        }

        let myEvents = isPOSFilter
            ? this.eventsByPOS(m, y).my.filter((event: EventsModel) => types.some(type => type === event.type))
            : this.events(m, y).my.filter((event: EventsModel) => types.some(type => type === event.type));
            // Needs to show old events with unsupported types like other;
        if (types.some(type => type === EVENT_TYPE_SETTINGS.OTHER)) {
            const myOldEvents = this.events(m, y).my
                .filter((event: EventsModel) => !$enum(EVENT_TYPE_SETTINGS).getValues().some(t => t === event.type));
            myEvents = [...myEvents, ...myOldEvents];
        }
        return myEvents;
    }

    getChainEventsByMonth(m: Month, y: Year, isPOSFilter: boolean = false) {
        const s = this.storeState;
        const { types } = s.settings;

        let chainEvents = isPOSFilter
            ? this.eventsByPOS(m, y).chain.filter((event: EventsModel) => types.some(type => type === event.type))
            : this.events(m, y).chain.filter((event: EventsModel) => types.some(type => type === event.type));
            // Needs to show old events with unsupported types like other;
        if (types.some(type => type === EVENT_TYPE_SETTINGS.OTHER)) {
            const chainOldEvents = this.events(m, y).chain
                .filter((event: EventsModel) => !$enum(EVENT_TYPE_SETTINGS).getValues().some(t => t === event.type));
            chainEvents = [...chainEvents, ...chainOldEvents];
        }

        return chainEvents;
    }

    getHolidayEventsByMonth(m: Month, y: Year, isPOSFilter: boolean = false) {
        const s = this.storeState;
        const { countries } = s.settings;

        if (isPOSFilter) {
            return this.eventsByPOS(m, y).holiday
                .filter((event: EventsModel) => countries.some(country => country === event.country || !event.country));
        }

        return this.events(m, y).holiday
            .filter((event: EventsModel) => countries.some(country => country === event.country || !event.country));
    }

    getSuggestedEventsByMoth(m: Month, y: Year, isPOSFilter: boolean = false) {
        const s = this.storeState;
        const { types, status } = s.settings;

        if (status === EVENT_STATUS_SETTINGS.APPROVED) {
            return [];
        }
        return isPOSFilter
            ? this.events(m, y).suggested.filter((event: EventsModel) => types.some(type => type === event.type))
            : this.eventsByPOS(m, y).suggested.filter((event: EventsModel) => types.some(type => type === event.type));
    }

    getCountryEventsByDay(params: IGetEventParams) {
        const {
            day, month, year, isPOSBased,
        } = params;
        const { month: currentMonth, year: currentYear } = this.documentFiltersService.storeState.settings;
        let dayEvents: EventCollection;
        if ((month || month === 0) && year) {
            dayEvents = this.getEventsByMonth(month, year, isPOSBased)[day];
            return dayEvents ? dayEvents.holiday : [];
        }
        dayEvents = this.getEventsByMonth(currentMonth, currentYear, isPOSBased)[day];
        return dayEvents ? dayEvents.holiday : [];
    }

    getMyEventsByDay(params: IGetEventParams) {
        const { day, month } = params;
        const { year, isPOSBased } = params;

        const { month: currentMonth, year: currentYear } = this.documentFiltersService.storeState.settings;

        let dayEvents: EventCollection;
        if ((month || month === 0) && year) {
            dayEvents = this.getEventsByMonth(month, year, isPOSBased)[day];
            return dayEvents ? dayEvents.my : [];
        }
        dayEvents = this.getEventsByMonth(currentMonth, currentYear, isPOSBased)[day];
        return dayEvents ? dayEvents.my : [];
    }

    getSuggestedEventsByDay(params: IGetEventParams) {
        const { day, month } = params;
        const { year, isPOSBased } = params;
        const { month: currentMonth, year: currentYear } = this.documentFiltersService.storeState.settings;

        let dayEvents: EventCollection;

        if ((month || month === 0) && year) {
            dayEvents = this.getEventsByMonth(month, year, isPOSBased)[day];
            return dayEvents ? dayEvents.suggested : [];
        }

        dayEvents = this.getEventsByMonth(currentMonth, currentYear, isPOSBased)[day];
        return dayEvents ? dayEvents.suggested : [];
    }

    getChainEventsByDay(params: IGetEventParams) {
        const { day, month } = params;
        const { year, isPOSBased } = params;
        const { month: currentMonth, year: currentYear } = this.documentFiltersService.storeState.settings;

        let dayEvents: EventCollection;

        if ((month || month === 0) && year) {
            dayEvents = this.getEventsByMonth(month, year, isPOSBased)[day];
            return dayEvents ? dayEvents.chain : [];
        }

        dayEvents = this.getEventsByMonth(currentMonth, currentYear, isPOSBased)[day];
        return dayEvents ? dayEvents.chain : [];
    }

    getHotelChainEvents(hotelChainId: number, day: Day, month?: Month, year?: Year) {
        return this.getChainEventsByDay({ day, month, year })
            .filter((event: EventsModel) => event.fornovaId === hotelChainId);
    }

    getEventById(eventId: string) {
        return this.storeState.eventDictionary[eventId];
    }

    getDayEventById(id: string): IDayEvent | null {
        const event = this.getEventById(id);
        if (!event) return null;

        return {
            event,
            isApproved: event.group === EventGroup.MAIN,
            isSuggested: event.group === EventGroup.SUGGESTED,
            isHoliday: event.group === EventGroup.HOLIDAY,
            isChain: event.group === EventGroup.CHAIN,
        } as IDayEvent;
    }

    async addEvent(newEvent: EventsModel): Promise<ValidationError[]> {
        let validationErrors: ValidationError[] = await validate(newEvent);

        if (validationErrors.length > 0) {
            return validationErrors;
        }

        try {
            const { user, currentHotelId, isCarUser } = this.userService;
            const { currentCompset } = this.compsetsService;
            const marketId = currentCompset ? +currentCompset.marketId : currentHotelId!;

            if (!user || (!marketId && !isCarUser) || (!currentHotelId && isCarUser)) {
                return validationErrors;
            }

            const creationParams = { ...newEvent, marketId };

            const event = await this.eventsApiService.createEvent(creationParams);

            if (event) {
                this.registerEventsInCollections([event], true);
            }
        } catch (error) {
            if (error instanceof BadRequestException) {
                validationErrors = this.updateValidationErrors(validationErrors, error.message);
            } else {
                throw error;
            }
        }
        return validationErrors;
    }

    /**
     * Updates data of the event
     *
     * @param event is actually copy of the original event with updated fields so original event will be removed
     */
    async updateEvent(event: EventsModel): Promise<ValidationError[]> {
        let validationErrors: ValidationError[] = await validate(event);

        if (validationErrors.length > 0) {
            return validationErrors;
        }

        try {
            const newEvent = await this.eventsApiService.updateEvent(event);
            if (!newEvent) {
                return validationErrors;
            }

            await this.removeEvent(event.id!, true);
            this.registerEventsInCollections([newEvent], true);
        } catch (error) {
            if (error instanceof BadRequestException) {
                validationErrors = this.updateValidationErrors(validationErrors, error.message);
            } else {
                throw error;
            }
        }

        return validationErrors;
    }

    /**
     * Removes the specified event
     * @param eventId
     * @param localyOnly means that it will be removed on client-side only
     */
    async removeEvent(eventId: string, localyOnly = false): Promise<ValidationError[]> {
        let validationErrors: ValidationError[] = [];
        try {
            const targetEvent = this.getEventById(eventId);

            if (!localyOnly) {
                await this.eventsApiService.removeLocalEvent(eventId);
            }

            this.removeEventFromCollections(targetEvent);
            delete this.storeState.eventDictionary[eventId];
        } catch (error) {
            if (error instanceof BadRequestException) {
                validationErrors = this.updateValidationErrors(validationErrors, error.message);
            } else {
                throw error;
            }
        }
        return validationErrors;
    }

    async approveEvent(eventId: string): Promise<ValidationError[]> {
        let validationErrors: ValidationError[] = [];
        try {
            const approvedEvent = await this.eventsApiService.approveEvents(eventId);
            if (!approvedEvent) return validationErrors;

            this.removeEvent(eventId, true);
            this.registerEventsInCollections([approvedEvent], true);
        } catch (error) {
            if (error instanceof BadRequestException) {
                validationErrors = this.updateValidationErrors(validationErrors, error.message);
            } else {
                throw error;
            }
        }
        return validationErrors;
    }

    async ignoreEvent(eventId: string): Promise<ValidationError[]> {
        let validationErrors: ValidationError[] = [];
        try {
            await this.eventsApiService.ignoreEvents([eventId]);
            this.removeEvent(eventId, true);
        } catch (error) {
            if (error instanceof BadRequestException) {
                validationErrors = this.updateValidationErrors(validationErrors, (error as BadRequestException).message);
            } else {
                throw error;
            }
        }
        return validationErrors;
    }

    removeMyEventFromStore(eventId: string) {
        if (this.userService.isViewAsCluster || this.userService.isViewAsChain) {
            this.storeState.currentMonthEvents.chain = this.storeState.currentMonthEvents.chain.filter(x => x.id !== eventId);
        } else {
            this.storeState.currentMonthEvents.my = this.storeState.currentMonthEvents.my.filter(x => x.id !== eventId);
        }
    }

    removeSuggestedEventFromStore(eventId: string) {
        this.storeState.currentMonthEvents.suggested = this.storeState.currentMonthEvents.suggested.filter(x => x.id !== eventId);
    }

    removeChainEventFromStore(eventId: string) {
        this.storeState.currentMonthEvents.chain = this.storeState.currentMonthEvents.chain.filter(x => x.id !== eventId);
    }

    moveSuggestedEventToMyEventFromStore(event: EventsModel) {
        this.storeState.currentMonthEvents.my = [...this.storeState.currentMonthEvents.my, ...[event]];
    }

    private getEventsByMonth(month: Month, year: Year, isPOSFilter: boolean = false) {
        const { settings } = this.documentFiltersService;
        const monthEvents: { [day: number]: EventCollection } = {};

        const myEvents = this.populateMonthEventsByDays(this.getMyEventsByMonth(month, year, isPOSFilter), month, year);
        const suggestedEvents = this.populateMonthEventsByDays(this.getSuggestedEventsByMoth(month, year, isPOSFilter), month, year);
        const holidayEvents = this.populateMonthEventsByDays(this.getHolidayEventsByMonth(month, year, isPOSFilter), month, year);
        const chainEvents = this.populateMonthEventsByDays(this.getChainEventsByMonth(month, year, isPOSFilter), month, year);

        const lastDayOfMonth = new Date(settings.year, month + 1, 0).getDate();

        for (let day = 1; day <= lastDayOfMonth; day += 1) {
            if (myEvents[day]) {
                if (!monthEvents[day]) {
                    monthEvents[day] = new EventCollection();
                }
                monthEvents[day].my = myEvents[day];
            }

            if (suggestedEvents[day]) {
                if (!monthEvents[day]) {
                    monthEvents[day] = new EventCollection();
                }
                monthEvents[day].suggested = suggestedEvents[day];
            }

            if (holidayEvents[day]) {
                if (!monthEvents[day]) {
                    monthEvents[day] = new EventCollection();
                }
                monthEvents[day].holiday = holidayEvents[day];
            }

            if (chainEvents[day]) {
                if (!monthEvents[day]) {
                    monthEvents[day] = new EventCollection();
                }
                monthEvents[day].chain = chainEvents[day];
            }
        }

        return monthEvents;
    }

    private populateMonthEventsByDays(events: EventsModel[], month: Month, year: Year) {
        const monthEvents: {[day: number]: EventsModel[]} = {};

        if (!Array.isArray(events)) return monthEvents;

        events.forEach(event => {
            if (event.startDate && event.endDate) {
                let startDay = new Date(event.startDate).getUTCDate();
                let endDay = new Date(event.endDate).getUTCDate();
                const startDateMonth = new Date(event.startDate).getUTCMonth();
                const startDateYear = new Date(event.startDate).getFullYear();
                const endDateYear = new Date(event.endDate).getFullYear();
                const endDateMonth = new Date(event.endDate).getUTCMonth();

                if (startDateYear < year || startDateMonth < month) {
                    startDay = 1;
                }

                if (endDateYear > year || endDateMonth > month) {
                    endDay = new Date(year, startDateMonth + 1, 0).getDate();
                }

                if (startDateMonth === month || endDateMonth === month) {
                    for (let i = startDay; i <= endDay; i += 1) {
                        if (!monthEvents[i]) {
                            monthEvents[i] = [];
                        }
                        monthEvents[i].push(event);
                    }
                }
            }
        });

        return monthEvents;
    }

    private removeEventFromCollections(event: EventsModel) {
        const group = event.group as ((typeof EventGroup)[keyof typeof EventGroup]);

        event.collections.forEach(eventCollection => {
            const evIndex = eventCollection[group].indexOf(event);
            eventCollection[group].splice(evIndex, 1);
        });

        for (let i = 0; i < event.collections.length; i++) {
            event.collections.splice(0, 1);
        }
    }

    updateValidationErrors(validationErrors: ValidationError[], message: string) :ValidationError[] {
        const error = new ValidationError();
        error.constraints = { message };

        return [...validationErrors, ...[error]];
    }

    getEventCollection(date: Date): EventCollection | null {
        const iso = date.toISOString().split('T')[0];
        return this.eventsMap[iso] || null;
    }

    getHolidayEvents(date: Date) {
        const { countries } = this.storeState.settings;
        const collection = this.getEventCollection(date);
        if (!collection) return [];

        return collection.holiday
            .filter(event => countries.includes(event.country!) || !event.country);
    }

    getOtherEvents(date: Date) {
        const { types } = this.storeState.settings;
        const { isCarUser, isViewAsHotel } = this.userService;
        const collection = this.getEventCollection(date);
        if (!collection) return [];

        const { chain, my } = collection;
        let { suggested } = collection;

        if (!isCarUser && !isViewAsHotel) {
            suggested = [];
        }

        return ([] as EventsModel[])
            .concat(chain, suggested, my)
            .filter(event => types.includes(event.type!));
    }

    getEventsByDate(date: Date) {
        const { types, countries } = this.storeState.settings;
        const { isCarUser, isViewAsHotel } = this.userService;
        const collection = this.getEventCollection(date);

        if (!collection) return [];
        const { chain, my } = collection;
        let { suggested, holiday } = collection;

        if (!isCarUser && !isViewAsHotel) {
            suggested = [];
        }

        holiday = holiday
            .filter(event => countries.includes(event.country!) || !event.country);

        return ([] as EventsModel[])
            .concat(holiday, my, suggested, chain)
            .filter(event => event.group === EventGroup.HOLIDAY || types.includes(event.type!));
    }

    hasDateHolidayEvents(date: Date): boolean {
        return !!this.getHolidayEvents(date).length;
    }

    hasDateOtherEvents(date: Date): boolean {
        return !!this.getOtherEvents(date).length;
    }

    saveIsLoadEventByPOS(value: boolean) {
        if (this.carsService.storeState.settings.isLoadEventByPOS !== value) {
            this.carsService.storeState.settings.isLoadEventByPOS = value;
        }
    }
}
