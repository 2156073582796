import { Expose, Transform } from 'class-transformer';
import { Validate } from 'class-validator';

type ProvidersPlain = {
    [provider: string]: {
        [fornovaId: number]: {
            [roomName: string]: number;
        }
    }
};

export default class HotelsRoomTypeModel {
    @Expose()
    @Transform((_, plain: ProvidersPlain) => {
        const model = {} as any;

        Object
            .entries(plain.providers)
            .forEach(([provider, hotels]) => {
                Object
                    .entries(hotels)
                    .forEach(([hotelId, roomList]) => {
                        model[provider] = model[provider] || {};
                        model[provider][+hotelId] = model[provider][+hotelId] || {};

                        Object
                            .entries(roomList)
                            .forEach(([roomName, roomTypeId]: [string, number]) => {
                                model[provider][+hotelId][roomTypeId] = model[provider][+hotelId][roomTypeId] || [];
                                model[provider][+hotelId][roomTypeId].push(roomName);
                            });
                    });
            });

        return model;
    })
    providers!: {
        [provider: string]: {
            [fornovaId: number]: {
                [roomTypeId: number]: string[];
            }
        }
    };

    @Expose()
    @Transform((_, plain: ProvidersPlain) => {
        const hotels = Object
            .values(plain.providers)
            .map(hotelList => Object.keys(hotelList))
            .flat();

        return Array.from(new Set(hotels));
    })
    hotels!: number[];
}
