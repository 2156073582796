import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import use from '@/router/use';
import { container } from 'inversify-props';
import PaginationMiddleware, { PaginationMiddlewareS } from '@/router/middlewares/pagination.middleware';
import PromotionsClusterLevelRoutes from '../cluster/promotions.routes';
import PromotionsHotelLevelRoutes from '../hotel/promotions.routes';

export default function PromotionsChainLevelRoutes() {
    return {
        path: 'promotions',
        beforeEnter: use([container.get<FeaturesGuard>(FeaturesGuardS), container.get<PaginationMiddleware>(PaginationMiddlewareS)]),
        component: () => import('@/modules/common/pages/transition.page.vue'),
        children: [
            ...PromotionsClusterLevelRoutes('', 'chain.promotions').children!,
            PromotionsHotelLevelRoutes(':hotelId', 'chain.promotions.hotel'),
        ],
    };
}
