import { Inject, injectable } from 'inversify-props';
import ANY_MEAL_TYPE from '@/modules/meal-types/constants/any-meal-type.constant';
import MealTypeModel from '@/modules/meal-types/models/meal-type.model';
import MealTypesApiService, { MealTypesApiServiceS } from '@/modules/meal-types/meal-types-api.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import MealTypesStore from '@/modules/meal-types/store/meal-types.store';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import HelperService, { HelperServiceS } from '../common/services/helper.service';

export const MealTypesServiceS = Symbol.for('MealTypesServiceS');
@injectable(MealTypesServiceS as unknown as string)
export default class MealTypesService implements Stateable {
    @Inject(MealTypesApiServiceS) private mealTypesApiService!: MealTypesApiService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;

    readonly storeState: MealTypesStore = this.storeFacade.getState('MealTypesStore');

    constructor() {
        this.storeFacade.watch(
            () => this.userService.storeState.user,
            this.storeState.loading.reset.bind(this.storeState.loading),
        );
    }

    get mealTypes() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return [ANY_MEAL_TYPE, ...this.storeState.mealTypes];
    }

    async loadData() {
        if (!this.userService.isHotelUser) {
            return false;
        }

        const mealTypes = await this.mealTypesApiService.getMealTypes();

        this.storeState.mealTypes = mealTypes || [];
        return true;
    }

    getMealType(id: number | string | null) {
        if (id === null) {
            const defaultMTName = this.userService.storeState.user!.settings.defaultFilters.mealType;
            const mealType = this.storeState.mealTypes
                .find(item => item.name === defaultMTName);

            return mealType || ANY_MEAL_TYPE;
        }

        if (typeof id === 'string') {
            return this.mealTypes.find(item => item.name === id);
        }

        return this.mealTypes.find(item => item.id === id);
    }

    // FIXME wrong method name. Perhaps duplicate of the function above
    getIdByName(name: string | null) {
        const n = name || this.userService.storeState.user!.settings.defaultFilters.mealType;
        return this.storeState.mealTypes.find(item => item.name === n);
    }

    get mealTypeItems() {
        if (!this.mealTypes) {
            return [];
        }
        return this.mealTypes.map((mealType: MealTypeModel) => ({
            value: mealType.name,
            name: mealType.displayName,
        }));
    }
}
