/* eslint-disable no-underscore-dangle */
import ClusterHotelsModel from '@/modules/cluster/models/cluster.model';
import CompsetModel from '@/modules/compsets/models/compset.model';
import PromotionsDocumentModel from '@/modules/promotions/models/promotions-document.model';
import { Expose, plainToClass, Transform } from 'class-transformer';

export default class ClusterPromotionsModel extends ClusterHotelsModel {
    @Expose()
    @Transform((_, plain) => plainToClass(PromotionsDocumentModel, plain.document || null))
    compsetMain!: PromotionsDocumentModel | null;
}
