import { Inject, injectable } from 'inversify-props';
import type Day from '@/modules/common/types/day.type';
import RatesStore from '@/modules/rates/store/rates.store';
import MarketsStore from '@/modules/markets/store/markets.store';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import DocumentFiltersStore from '@/modules/document-filters/store/document-filters.store';
import type Month from '@/modules/common/types/month.type';
import type Year from '@/modules/common/types/year.type';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import UserService, { UserServiceS } from '../user/user.service';
import Item from '../common/interfaces/item.interface';
import USER_LEVELS from '../user/constants/user-levels.constant';
import PRICE_SHOWN from '../rates/constants/price-shown.constant';
import UserSettingsService, { UserSettingsS } from '../user/user-settings.service';

export const DocumentFiltersServiceS = Symbol.for('DocumentFiltersServiceS');
@injectable(DocumentFiltersServiceS as unknown as string)
export default class DocumentFiltersService implements Stateable {
    @Inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    @Inject(StoreFacadeS)
    private storeFacade!: StoreFacade;

    @Inject(UserServiceS)
    private userService!: UserService;

    readonly storeState: DocumentFiltersStore = this.storeFacade.getState('DocumentFiltersStore');
    readonly ratesStoreState: RatesStore = this.storeFacade.getState('RatesStore');
    readonly marketsStoreState: MarketsStore = this.storeFacade.getState('MarketsStore');

    constructor() {
        // NOTE Set default filters

        let defaultFiltersWatcher: (() => void) | null = null;

        this.storeFacade.watch(
            () => this.userService.user,
            () => {
                this.saveLos(this.userService.user!.settings.defaultFilters.los);

                if (defaultFiltersWatcher) {
                    return;
                }

                defaultFiltersWatcher = this.storeFacade.watch(
                    () => this.userService.user!.settings.defaultFilters.los,
                    () => {
                        this.saveLos(this.userService.user!.settings.defaultFilters.los);
                    },
                );
            },
        );

        this.storeFacade.watch(
            () => this.storeState.settings.compsetId,
            () => {
                this.storeState.settings.competitors = this.compsetsService.currentCompset?.competitors || [];
            },
        );

        // NOTE: Dynamic loading here working thanks to init in app container
        if (!this.userService.isCarUser) {
            this.storeFacade.watch(() => this.compsetsService.storeState.compsets, this.resetCompset.bind(this));
        }
    }

    get compsetId() {
        return this.storeState.settings.compsetId;
    }

    set compsetId(id: string | null) {
        this.storeState.settings.compsetId = id;
    }

    set compsetIdFromHighLevel(id: string | null) {
        this.storeState.compsetIdFromHighLevel = id;
    }

    get compsetIdFromHighLevel() {
        return this.storeState.compsetIdFromHighLevel;
    }

    get competitors() {
        return this.storeState.settings.competitors;
    }

    set competitors(value: number[]) {
        this.storeState.settings.competitors = value;
    }

    get month() {
        return this.storeState.settings.month;
    }

    get year() {
        return this.storeState.settings.year;
    }

    get settings() {
        return this.storeState.settings;
    }

    /**
     * Returns `today's day index` (starts from zero)
     * If it's not current month, returns `null`
     */
    get todayDate() {
        const today = new Date();
        const isCurrentMonth = this.month === today.getMonth()
            && this.year === today.getFullYear();

        return isCurrentMonth ? today.getDate() - 1 : null;
    }

    get priceShown() {
        return this.storeState.settings.priceShown;
    }

    set priceShown(value: PRICE_SHOWN) {
        this.storeState.settings.priceShown = value;
    }

    get priceShownItems() {
        return this.storeState.options.priceShown;
    }

    updateCompset(compsetId: string) {
        const compset = this.compsetsService.getCompset(compsetId);

        if (!compset) {
            return;
        }

        const { los, pos, mainPos } = compset;

        this.storeState.settings.compsetId = compsetId;

        if (this.storeState.settings.los === null
            || !los.find(item => item === this.storeState.settings.los)) {
            this.storeState.settings.los = los && los.length
                ? this.userService.user!.settings.defaultFilters.los
                : this.storeState.settings.los;
        }

        if (mainPos) {
            this.storeState.settings.pos = mainPos;
        } else if (this.storeState.settings.pos === null
                || !pos.find(item => item === this.storeState.settings.pos)) {
            this.storeState.settings.pos = pos && pos.length
                ? pos[0]
                : this.storeState.settings.pos;
        }
    }

    resetCompset() {
        const { compsets } = this.compsetsService;

        if (!compsets || !compsets.length) {
            return;
        }

        const defaultCompsetId = compsets[0].id;
        let newCompsetId = defaultCompsetId;

        const { viewAs } = this.userService;
        if ((viewAs === USER_LEVELS.CHAIN || viewAs === USER_LEVELS.CLUSTER) && this.compsetIdFromHighLevel) {
            newCompsetId = this.compsetIdFromHighLevel;
        }

        this.updateCompset(newCompsetId);
    }

    saveYear(year: Year) {
        this.storeState.settings.year = year;
    }

    saveMonth(month: Month) {
        this.storeState.settings.month = month;
    }

    saveLos(los: number | null) {
        this.storeState.settings.los = los;
    }

    get los() {
        return this.storeState.settings.los;
    }

    get losItems(): Item<number>[] {
        if (!this.compsetsService.currentCompset) {
            return [];
        }

        return this.compsetsService.currentCompset.los.map((los: number) => ({
            value: los,
            name: `LOS${los}`,
        }));
    }

    savePos(pos: string | null) {
        this.storeState.settings.pos = pos;
    }

    isPreviousDay(day: Day): boolean {
        const { month, year } = this.storeState.settings;

        const today = new Date();
        const currentMonth = today.getMonth();
        const currentYear = today.getFullYear();
        const currentDay = today.getDate();

        if (year > currentYear) {
            return false;
        }

        return year < currentYear || month < currentMonth || (month === currentMonth && day < currentDay);
    }

    isCurrentDay(day: Day, month?: Month, year?: Year): boolean {
        const selectedMonth = month || this.storeState.settings.month;
        const selectedYear = year || this.storeState.settings.year;
        const today = new Date();
        const todayDay = today.getDate();
        const todayMonth = today.getMonth();
        const todayYear = today.getFullYear();
        return todayDay === day && todayYear === selectedYear && todayMonth === selectedMonth;
    }

    get nextMonthAndYear(): { month: Month, year: Year } {
        const { month, year } = this.storeState.settings;
        if (month + 1 > 11) {
            return { month: 0, year: year + 1 };
        }
        return { month: (month + 1) as Month, year };
    }

    get previousMonthAndYear(): { month: Month, year: Year } {
        const { month, year } = this.storeState.settings;
        if (month - 1 < 0) {
            return { month: 11, year: year - 1 };
        }
        return { month: (month - 1) as Month, year };
    }

    get isPreviousMonth(): boolean {
        const { month, year } = this.storeState.settings;

        const today = new Date();
        const currentMonth = today.getMonth();
        const currentYear = today.getFullYear();

        return year <= currentYear && month < currentMonth;
    }

    get days(): Day[] {
        const { settings: { year, month } } = this.storeState;

        const days: Day[] = [];
        const lastDayOfMonth = new Date(year, month + 1, 0).getDate();

        for (let day = 1; day <= lastDayOfMonth; day += 1) {
            days.push(day as Day);
        }

        return days;
    }

    get lastDayCurrentMonth() {
        return new Date(
            this.storeState.settings.year,
            this.storeState.settings.month + 1,
            0,
        ).getDate();
    }

    get posRatesItems(): Item<string>[] {
        const { provider } = this.ratesStoreState.settings;
        return this.getPosItems(provider ? [provider] : null);
    }

    // Only for table view
    get posMarketItems(): Item[] {
        const { provider } = this.marketsStoreState.settings;
        return this.getPosItems(provider ? [provider] : null);
    }

    getPosItems(providers: string[] | null) {
        const { currentCompset } = this.compsetsService;

        if (!currentCompset) {
            return [] as Item<string>[];
        }

        const defaultPoses = currentCompset.pos.map(pos => ({
            value: pos,
            name: String(pos),
        })) as Item<string>[];

        if (!providers || !providers.length) {
            return defaultPoses;
        }

        const { providersPos } = currentCompset;

        if (!providersPos || !providersPos.length) {
            return defaultPoses;
        }

        const allPossibleProviderPoses = Array.from(new Set(
            providersPos
                .filter(provider => providers.includes(provider.name))
                .reduce((acc, provider) => acc.concat(provider.pos), [] as string[]),
        ));

        if (!allPossibleProviderPoses.length) {
            return defaultPoses;
        }

        return allPossibleProviderPoses.map(pos => ({
            name: pos,
            value: String(pos),
        })) as Item<string>[];
    }
}
