import type EventsModel from '@/modules/events/models/events.model';

export default class EventCollection {
    my: EventsModel[] = [];
    suggested: EventsModel[] = [];
    holiday: EventsModel[] = [];
    chain: EventsModel[] = [];
    date?: Date;

    constructor(date?: Date) {
        this.date = date;
    }
}
