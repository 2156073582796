import { Inject, injectable } from 'inversify-props';

import RoomsTypeManagerApiService, { RMSStatePayload, RoomsTypeManagerApiServiceS } from '@/modules/rooms-type-manager/rooms-type-manager-api.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import HotelsRoomTypeStore from '@/modules/rooms-type-manager/store/hotels-room-type.store';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import CompsetsService, { CompsetsServiceS } from '../compsets/compsets.service';
import UserService, { UserServiceS } from '../user/user.service';
import RoomsRMSStateModel from './models/rooms-rms-state.model';
import downloadBlobAsFile from '../common/filters/download-file';
import OpenTelemetryService, { OpenTelemetryServiceS } from '../open-telemetry/open-telemetry.service';
import { LOGTYPE } from '../open-telemetry/constants';
import HotelsRoomTypeModel from './models/hotels-room-type.model';

interface RoomsTypeManagerPublicInterface {
    /** Getter with dynamic loading. Launch loadData method if data not actual, to load room types data. */
    readonly data: HotelsRoomTypeModel | null;

    /** Return list of provider from data, excluding all and cheapest */
    readonly providers: string[];

    /** Get hotel ids from data and map it with hotel names */
    readonly hotels: {
        id: number;
        name: string;
    }[];

    /** Show is archiving process running */
    readonly isArchiveListUpdating: boolean;

    /** Shows are there any changes, which not applied on BE */
    isDocumentChanged: boolean;

    /**
     * Load room type data
     * @returns true in case successfull loading
     */
    loadData: () => Promise<boolean>;

    /**
     * Get list of room names
     * @param hotelId any fornova id available in the compset
     * @param provider available in the compset source
     * @param roomTypeId id of room type
     * @returns room names from data by specified parameters
     */
    getRooms: (hotelId: number, provider: string, roomTypeId: number) => string[];

    /** Set provided source in the store */
    saveSource: (source: string | null) => void;

    /** Are there any rooms in room type. */
    isRoomTypeHaveRooms: (roomTypeId: number) => boolean;

    /** Set archived rooms in the store. Doesn't trigger the request. */
    updateArchivedRoomList: (newMap: Record<number, string[]>) => void;

    /**
     * Returns list of archived rooms
     * @param hotelId any fornova id available in the compset
     * @param cached if true list returned from the store. If false, new request is sent.
     * @returns list of names.
     */
    getArchivedRoomList: (hotelId: number, cached: boolean) => Promise<string[]>;

    /**
     * Update room's archive state (archived or not).
     * @param hotelId any fornova id available in the compset.
     * @param names list of room names to change state.
     * @param archiveState true if room names should be archived, false otherwise.
    */
    setRoomsArchiveState: (hotelId: number, names: string[], archiveState: boolean) => Promise<void>

    /** Resets loading, so calling data getter next time will trigger request. Also resets all changes and room type data in the store */
    reset: () => void;

    /**
     * Moves room into a new room type inside existing document without saving.
     * @param hotelId any fornova id available in the compset.
     * @param roomTypeId id of room type.
     * @param provider available in the compset source.
     * @param roomName name of room to move.
     * @param targetRoomTypeId id of room type to move room in.
     */
    moveRoom: (hotelId: number, roomTypeId: number, provider: string, roomName: string, targetRoomTypeId: number) => void;

    /**
     * Moves all rooms from one room type to another and send request to BE to apply changes.
     * @param mainHotelId user selected hotel's id.
     * @param roomTypeId room type id to move from.
     * @param targetRoomTypeId room type id to move in.
     */
    moveAllRoomsToRoomType: (mainHotelId: number, roomTypeId: number, targetRoomTypeId: number) => Promise<void>;

    /**
     * Send request to save changes on BE
     * @param mainHotelId user selected hotel's id.
     */
    saveDocument: (mainHotelId: number) => Promise<void>;

    /**
     * Check if there are rooms for provided source and hotel
     * @param provider available in the compset source.
     * @param hotelId any fornova id available in the compset.
     * @returns true if provider has room, false otherwise.
     */
    providerHaveRooms: (provider: string, hotelId: number) => boolean;

    /**
     * Send request to get info for which room types RMS blocked.
     * @param mainHotelId user selected hotel's id.
     * @returns list of blocked room type ids and fornova id.
     */
    getRoomsRMSState: (mainHotelId: number) => Promise<RoomsRMSStateModel[]>;

    /**
     * Send request to block RMS for provided fornova id and list of rooms.
     * @param mainHotelId user selected hotel's id.
     * @param newStates list of room types with related fornova id to block.
     */
    eliminateRoomsRMS: (mainHotelId: number, newStates: RMSStatePayload[]) => Promise<void>;

    /**
     * Send request to restore blocked RMS for provided fornova id and list of rooms.
     * @param mainHotelId user selected hotel's id.
     * @param newStates list of room types with related fornova id to restore.
     */
    restoreRoomsRMS: (mainHotelId: number, newStates: RMSStatePayload[]) => Promise<void>;

    /**
     * Downloads excel on setting room mapping.
     * Temporary solution, when dynamic excel on BE will be supported, should be
     * handled via notificationService.
     * @param hotelId
     * @param provider
     * @returns false if BE doesn't return any data, otherwise true.
     */
    downloadExcel: (hotelId: number, provider: string) => Promise<boolean>;
}

export const RoomsTypeManagerServiceS = Symbol.for('RoomsTypeManagerServiceS');
@injectable(RoomsTypeManagerServiceS as unknown as string)
export default class RoomsTypeManagerService implements RoomsTypeManagerPublicInterface {
    @Inject(RoomsTypeManagerApiServiceS)
    private hotelsRoomTypeApiService!: RoomsTypeManagerApiService;

    @Inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    @Inject(StoreFacadeS)
    private storeFacade!: StoreFacade;

    @Inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    @Inject(HelperServiceS)
    private helperService!: HelperService;

    @Inject(UserServiceS)
    private userService!: UserService;

    @Inject(RoomsTypeManagerApiServiceS)
    private roomsTypeManagerApiService!: RoomsTypeManagerApiService;

    @Inject(OpenTelemetryServiceS)
    private otelService!: OpenTelemetryService;

    readonly storeState: HotelsRoomTypeStore = this.storeFacade.getState('HotelsRoomTypeStore');

    constructor() {
        this.storeFacade.watch(
            () => this.compsetsService.storeState.compsets,
            this.reset.bind(this),
        );
    }

    private get hotelNames() {
        return this.storeState.hotelNames;
    }

    get providers() {
        if (!this.data) return [];

        return Object
            .keys(this.data.providers)
            .filter(provider => !['all', 'cheapest'].includes(provider));
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.hotelsRoomType;
    }

    get hotels() {
        if (!this.data) return [];

        return this.data.hotels
            .map(id => ({
                id,
                name: this.hotelNames[id],
            }));
    }

    get isDocumentChanged() {
        return this.storeState.isDocumentChanged;
    }

    set isDocumentChanged(value: boolean) {
        this.storeState.isDocumentChanged = value;
    }

    get isArchiveListUpdating() {
        return this.storeState.isArchiveListUpdating;
    }

    getRooms(hotelId: number, provider: string, roomTypeId: number) {
        if (!this.data) return [];

        return this.data.providers[provider][hotelId][roomTypeId] || [];
    }

    async loadData() {
        const hotelsRoomType = await this.hotelsRoomTypeApiService.getHotelsRoomType();
        this.storeState.hotelsRoomType = hotelsRoomType;
        this.storeState.hotelNames = await this.hotelsService
            .getHotelNames(this.data!.hotels);

        return true;
    }

    saveSource(source: string | null) {
        this.storeState.source = source;
    }

    isRoomTypeHaveRooms(roomTypeId: number) {
        if (!this.data) return false;

        return Object.entries(this.data.providers)
            .filter(([provider]) => !['all', 'cheapest'].includes(provider))
            .some(([, hotels]) => Object.values(hotels)
                .some(roomTypes => !!(roomTypes[roomTypeId] || []).length));
    }

    updateArchivedRoomList(newMap: Record<number, string[]>) {
        this.storeState.archivedRoomNames = {
            ...this.storeState.archivedRoomNames,
            ...newMap,
        };
    }

    async getArchivedRoomList(hotelId: number, cached = false) {
        if (cached) {
            return this.storeState.archivedRoomNames[hotelId] || [];
        }

        const list = await this.hotelsRoomTypeApiService
            .getArchivedRoomList(hotelId);

        this.updateArchivedRoomList(list);

        return this.storeState.archivedRoomNames[hotelId] || [];
    }

    async setRoomsArchiveState(hotelId: number, names: string[], archiveState: boolean) {
        const { chainId } = this.userService;

        this.storeState.isArchiveListUpdating = true;

        try {
            const oldList = await this.getArchivedRoomList(hotelId, true);
            const updatedList = archiveState
                ? [...oldList, ...names]
                : [...oldList].filter(name => !names.includes(name));

            const newRoomMap = await this.hotelsRoomTypeApiService
                .updateArchivedRoomList(chainId!, hotelId, updatedList);

            this.updateArchivedRoomList(newRoomMap);

            await this.loadData();
        } finally {
            this.storeState.isArchiveListUpdating = false;
        }
    }

    reset() {
        this.storeState.loading.reset();
        this.storeState.hotelsRoomType = null;
        this.isDocumentChanged = false;
        this.storeState.changeData = {};
    }

    moveRoom(hotelId: number, roomTypeId: number, provider: string, roomName: string, targetRoomTypeId: number) {
        if (!this.data) return;

        this.isDocumentChanged = true;

        const hotelData = this.data.providers[provider][hotelId];
        const sourceRooms = hotelData[roomTypeId];

        hotelData[targetRoomTypeId] = hotelData[targetRoomTypeId] || [];

        const targetRooms = hotelData[targetRoomTypeId];
        const sourceIndex = sourceRooms.indexOf(roomName);

        if (sourceIndex < 0) return;

        sourceRooms.splice(sourceIndex, 1);
        targetRooms.push(roomName);

        this.addChangeData(
            hotelId,
            roomName,
            provider,
            targetRoomTypeId,
        );
    }

    private addChangeData(hotelId: number, roomName: string, provider: string, roomTypeId: number) {
        const { changeData } = this.storeState;

        changeData[hotelId] = changeData[hotelId] || {};
        changeData[hotelId][provider] = changeData[hotelId][provider] || {};
        changeData[hotelId][provider][roomName] = roomTypeId;
    }

    async moveAllRoomsToRoomType(mainHotelId: number, roomTypeId: number, targetRoomTypeId: number) {
        this.hotels.forEach(({ id: hotelId }) => {
            this.providers.forEach(provider => {
                const rooms = [...this.getRooms(hotelId, provider, roomTypeId)];

                rooms.forEach(roomName => {
                    this.moveRoom(hotelId, roomTypeId, provider, roomName, targetRoomTypeId);
                });
            });
        });

        await this.saveDocument(mainHotelId);
    }

    async saveDocument(mainHotelId: number) {
        if (!this.data) return;

        await this.hotelsRoomTypeApiService
            .updateHotelsRoomType(mainHotelId, this.storeState.changeData);

        this.storeState.changeData = {};
    }

    providerHaveRooms(provider: string, hotelId: number) {
        if (!this.data) return false;

        const providerData = this.data.providers[provider];

        return !!Object.keys(providerData && providerData[hotelId] || {}).length;
    }

    getRoomsRMSState(mainHotelId: number) {
        return this.hotelsRoomTypeApiService.getRoomsRMSState(mainHotelId);
    }

    eliminateRoomsRMS(mainHotelId: number, newStates: RMSStatePayload[]) {
        return this.hotelsRoomTypeApiService
            .eliminateRoomsRMS(mainHotelId, newStates);
    }

    restoreRoomsRMS(mainHotelId: number, newStates: RMSStatePayload[]) {
        return this.hotelsRoomTypeApiService
            .restoreRoomsRMS(mainHotelId, newStates);
    }

    async downloadExcel(hotelId: number, provider: string) {
        this.otelService.startSpan({ name: 'room-mapping', prefix: LOGTYPE.DOWNLOAD });
        const res = await this.roomsTypeManagerApiService.getMappingExcel(hotelId, provider);
        this.otelService.endSpan({ name: 'room-mapping', prefix: LOGTYPE.DOWNLOAD }, { sendLogs: true });

        if (!res || !res.data) {
            return false;
        }

        const blobData = res.data as Blob;
        downloadBlobAsFile(`${provider}-${hotelId}-room-mapping.xlsx`, blobData);
        return true;
    }
}
