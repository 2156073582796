import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import type Day from '@/modules/common/types/day.type';
import ResponseValidationException from '@/modules/common/modules/exception-handler/exceptions/response-validation.exception';
import ValidatorService, { ValidatorServiceS } from '@/modules/common/services/validator.service';
import MarketsHistoryModel from './models/markets-history.model';

export const MarketsHistoryApiServiceS = Symbol.for('MarketsHistoryApiServiceS');
@injectable(MarketsHistoryApiServiceS as unknown as string)
export default class MarketsHistoryApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;
    @Inject(ValidatorServiceS) private validatorService!: ValidatorService;

    async getMarketsHistoryByDay(day: Day, documentId: number) {
        const { data } = await this.apiService.get(`/market/single-date-trend/${day}/${documentId}`);

        if (!data) {
            return null;
        }

        const historyDocument = plainToClass(MarketsHistoryModel, <MarketsHistoryModel> data, { excludeExtraneousValues: true });

        // const error = await this.validatorService.validateResponse(priceHistoryDocument);
        // if (error) {
        //     throw new ResponseValidationException(error);
        // }

        return historyDocument;
    }
}
