import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import use from '@/router/use';
import { container } from 'inversify-props';
import PaginationMiddleware, { PaginationMiddlewareS } from '@/router/middlewares/pagination.middleware';
import DiLiteClusterLevelRoutes from '../cluster/di-lite.routes';
import DiLiteHotelLevelRoutes from '../hotel/di-lite.routes';

export default function DiLiteChainLevelRoutes() {
    return {
        path: 'di-lite',
        beforeEnter: use([container.get<FeaturesGuard>(FeaturesGuardS), container.get<PaginationMiddleware>(PaginationMiddlewareS)]),
        component: () => import('@/modules/common/pages/transition.page.vue'),
        children: [
            ...DiLiteClusterLevelRoutes('', 'chain.di-lite').children!,
            DiLiteHotelLevelRoutes(':hotelId', 'chain.di-lite.hotel'),
        ],
    };
}
