import { container } from 'inversify-props';
import use from '../../use';

import ChainGuard, { ChainGuardS } from '../../guards/chain.guard';
import HomepageRedirectMiddleware, { HomepageRedirectMiddlewareS } from '../../middlewares/homepage-redirect.middleware';

import { CommonMenuRoutes } from '../common.routes';
import MarketsChainLevelRoutes from './markets.routes';
import RankingChainLevelRoutes from './ranking.routes';
import RatesChainLevelRoutes from './rates.routes';
import DeepAnalysisChainLevelRoutes from './deep-analysis.routes';
import EventsManagerRoutes from '../event-manager.routes';
import PromotionsChainLevelRoutes from './promotions.routes';
import DiLiteChainLevelRoutes from './di-lite.routes';

export default function ChainBranch() {
    return {
        path: 'chain',
        beforeEnter: use([
            container.get<ChainGuard>(ChainGuardS),
        ]),
        component: () => import('@/modules/common/pages/transition.page.vue'),
        children: [
            {
                name: 'chain-home',
                path: '',
                component: () => import('@/modules/cluster/pages/home.page.vue'),
                beforeEnter: use([
                    container.get<HomepageRedirectMiddleware>(HomepageRedirectMiddlewareS),
                ]),
                children: [
                    ...CommonMenuRoutes('chain-home'),
                ],
            },
            RatesChainLevelRoutes(),
            MarketsChainLevelRoutes(),
            RankingChainLevelRoutes(),
            DeepAnalysisChainLevelRoutes(),
            EventsManagerRoutes(
                'events-manager',
                'chain.events-manager',
            ),
            PromotionsChainLevelRoutes(),
            DiLiteChainLevelRoutes(),
        ],
    };
}
