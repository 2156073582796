import { Expose, plainToClass, Transform } from 'class-transformer';
import {
    IsNotEmpty, IsOptional, Length, IsPositive,
} from 'class-validator';
import EventsLocationModel from '@/modules/events/models/events-location.model';
import EventGroup from '../interfaces/event-group.enum';
import type EventCollection from './event-collection.model';
import EVENT_TYPE_SETTINGS from '../constants/event-types-settings.constant';

export default class EventsModel {
    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain._id)
    id: string | null = null;

    @Expose()
    @Transform((_, plain) => plain.marketId)
    marketId : number | null = null;

    @Expose()
    @Transform((_, plain) => plain.name)
    @Length(2, 20)
    @IsNotEmpty({ message: 'Name should not be empty' })
    name: string | null = null;

    @Expose()
    @Transform((_, plain) => plain.type && plain.type.toLowerCase())
    @IsNotEmpty({ message: 'Event type should not be empty' })
    type: EVENT_TYPE_SETTINGS | null = null;

    @Expose()
    @Transform((_, plain) => new Date(plain.starts))
    @IsNotEmpty({ message: 'Start Date should not be empty' })
    startDate: Date | null = null;

    @Expose()
    @Transform((_, plain) => new Date(plain.ends))
    @IsNotEmpty({ message: 'End Date should not be empty' })
    endDate: Date | null = null;

    @Expose()
    @Transform((_, plain) => plain.country)
    country: string | null = null;

    @Expose()
    @Transform((_, plain) => plain.country_code && plain.country_code.toLowerCase())
    countryCode: string | null = null;

    @Expose()
    @Transform((_, plain) => plain.description)
    @Length(2, 100)
    @IsNotEmpty()
    description: string | null = null;

    @Expose()
    @Transform((_, plain) => plain.no_of_guests)
    @IsPositive({ message: 'Attendees Should Be A Number More Than 0' })
    @IsNotEmpty({ message: 'Attendees is required and should be a number' })
    numberOfGuests: number | null = null;

    @Expose()
    @Transform((_, plain) => plain.creator_id)
    creatorId: string | number | null = null;

    @Expose()
    @Transform((_, plain) => plain.fornovaId)
    fornovaId: number | null = null;

    @Expose()
    @Transform((_, plain) => plainToClass(EventsLocationModel, <EventsLocationModel> plain.location, { excludeExtraneousValues: true }))
    location: EventsLocationModel | null = null;

    @Expose()
    @IsOptional()
    @Transform((_, plain) => plain.privacy)
    privacy?: string;

    @Expose()
    @Transform((_, plain) => plain.entity_type)
    entityType: string | null = null;

    @Expose()
    @Transform((_, plain) => plain.entity_id)
    entityId: string | number | null = null;

    group: EventGroup = EventGroup.MAIN;

    /**
     * Collections that the event belongs to
     */
    collections: EventCollection[] = [];
}

export class HolidayEventModel extends EventsModel {
    group = EventGroup.HOLIDAY;
}

export class SuggestedEventModel extends EventsModel {
    group = EventGroup.SUGGESTED;
}

export class ChainEventModel extends EventsModel {
    group = EventGroup.CHAIN;
}
