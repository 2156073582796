import { Module, VuexModule } from 'vuex-module-decorators';
import ClusterHotelsMarketsModel from '@/modules/cluster/models/cluster-markets.model';
import ClusterHotelsRatesModel from '@/modules/cluster/models/cluster-rates.model';
import RatesCompsetMainModel from '@/modules/cluster/models/rates-compset-main.model';
import LoadingModel from '@/modules/common/models/loading.model';
import RatesSettingsModel from '@/modules/rates/models/rates-settings.model';
import PromotionsSettingsModel from '@/modules/promotions/models/promotions-settings.model';
import RankingChainItemModel from '@/modules/cluster/models/ranking-cluster-item.model';
import ClusterRankingModel from '@/modules/cluster/models/cluster-ranking.model';
import ClusterDiLiteModel from '@/modules/cluster/models/cluster-di-lite.model';
import ClusterDeepAnalysisModel from '@/modules/cluster/models/cluster-deep-analysis.model';
import COMPARE_TO from '@/modules/deep-analysis/constants/compare-to-filter.constant';
import VIEW_TYPE from '@/modules/deep-analysis/constants/view-type-filter.constant';
import STATISTIC_TYPE from '@/modules/deep-analysis/constants/statistic-type.constant';
import TREND_TYPE from '@/modules/deep-analysis/constants/trend-type.constant';
import SHOW_BY from '@/modules/deep-analysis/constants/show-by-filter.constant';
import ClusterPromotionsModel from '../models/cluster-promotions.model';
import type { ClusterHotelModel } from '../interfaces';
import ClusterHotelsModel from '../models/cluster.model';
import { DEEP_ANALYSIS_PROVIDERS } from '../constants';

@Module
export default class ClusterStore extends VuexModule {
    clusterHotels: ClusterHotelModel[] | null = null;
    provider: string | null = null;
    currentHotelId: number | null = null;

    // Deep analysis providers are static and options are kept in const
    daProvider: typeof DEEP_ANALYSIS_PROVIDERS[number] = DEEP_ANALYSIS_PROVIDERS[0];

    // Skip, limit for pagination
    hotelsSkip = 0;
    hotelsLimitPerPage = 30;

    totalCount: number | null = null;
    hotelNameSorting: (1 | -1 | 0) = 1;
    ratesSorting: (1 | -1 | 0) = 0;
    marketsSorting: (1 | -1) = 1;
    activePage: 'list' | 'table' = 'list';
    documentHash: string | null = null;

    // Value for searchbar query from all cluster page headers
    searchQuery: string | null = null;

    // FIXME Rename to ratesSettings
    settings: RatesSettingsModel = new RatesSettingsModel();
    promotionsSettings: PromotionsSettingsModel = new PromotionsSettingsModel();
    promotionsPrograms: {
        loading: LoadingModel
        list: Record<string, string[]>;
    } = {
        loading: new LoadingModel(),
        list: {},
    };

    deepAnalysisSorting: {
        key: STATISTIC_TYPE | null;
        value: 1 | -1;
    } = {
        key: null,
        value: 1,
    };

    deepAnalysisSettings: {
        showBy: SHOW_BY;
        compareTo: COMPARE_TO;
        view: VIEW_TYPE;
        filterByStatistic: [STATISTIC_TYPE | null, TREND_TYPE | null];
    } = {
        showBy: 'stay.7',
        compareTo: COMPARE_TO.COMPSET_AVG,
        view: VIEW_TYPE.ABSOLUTE,
        filterByStatistic: [null, null],
    };

    ratesClusterCurrentDocument: RatesCompsetMainModel | null = null;

    loading: LoadingModel = new LoadingModel();

    hotels: any = [];
    rankingChainItems: { [hotelId: number]: { [compsetId: string]: RankingChainItemModel }; } = {};

    hotelsMap: {
        [hotelId: number]: ClusterHotelsModel | undefined,
    } = {};

    hotelNamesMap: {
        [hotelId: number]: string,
    } = {};

    // all cluster POS, separate request
    pos: {
        list: string[],
        loading: LoadingModel,
    } = {
        list: [],
        loading: new LoadingModel(),
    };

    // Di lite cluster color-picker-group color thresholds
    diLiteColorThresholds: [number, number] = [5, 10];
    // Have to be separated, because it has different intervals with di lite
    ratesColorThresholds: [number, number] = [5, 10];
}
