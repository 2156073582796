import { Expose, plainToClass, Transform } from 'class-transformer';

export default class InsightTypeModel {
    @Expose()
    @Transform((_, plain) => plain.name)
    name!: string;

    @Expose()
    @Transform((_, plain) => plain.type)
    value!: string;
}
