import { Inject, injectable } from 'inversify-props';
import PROVIDERS_REGEXP from '@/modules/alerts/constants/providers-regexp.const';
import AlertsApiService, { AlertsApiServiceS } from '@/modules/alerts/alerts-api.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import AlertsStore from '@/modules/alerts/store/alerts-store';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import AlertModel from '@/modules/alerts/models/alert.model';
import HelperService, { HelperServiceS } from '../common/services/helper.service';
import CacheService, { ALERT_METHODS, CacheServiceS, MODULES } from '../common/services/cache/cache.service';
import { USER_LEVELS } from '../user/constants';
import UserModel from '../user/models/user.model';

export const AlertsServiceS = Symbol.for('AlertsServiceS');
@injectable(AlertsServiceS as unknown as string)
export default class AlertsService implements Stateable {
    @Inject(AlertsApiServiceS)
    private alertsApiService!: AlertsApiService;

    @Inject(UserServiceS)
    private userService!: UserService;

    @Inject(StoreFacadeS)
    private storeFacade!: StoreFacade;

    @Inject(HelperServiceS)
    private helperService!: HelperService;

    @Inject(CacheServiceS)
    private cacheService!: CacheService;

    readonly storeState: AlertsStore = this.storeFacade.getState('AlertsStore');

    constructor() {
        this.storeFacade.watch(
            () => this.userService.storeState.user,
            this.resetService.bind(this),
        );

        this.loadData = this.cacheService
            .memorize(MODULES.ALERTS, ALERT_METHODS.getAlerts, this.loadData.bind(this), 1);
    }

    get alerts() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.alerts.concat().sort((a, b) => b.date.getTime() - a.date.getTime());
    }

    async loadData() {
        const { routeBranch } = this.userService;

        let alerts: AlertModel[] | null;
        this.storeState.alerts = [];

        switch (routeBranch) {
            case USER_LEVELS.CHAIN:
            case USER_LEVELS.CLUSTER:
                alerts = await this.alertsApiService.getClusterAlerts();
                break;
            case USER_LEVELS.CAR:
                alerts = await this.alertsApiService.getCarAlerts();
                break;
            default:
                alerts = await this.alertsApiService.getCiAlerts();
                break;
        }

        this.storeState.alerts = alerts || [];
        return true;
    }

    async markAlertAsRead(id: string) {
        this.alerts
            .find(a => a.id === id)!
            .read = true;

        await this.alertsApiService.markAlertAsRead(id);
    }

    async markAlertAsUnread(id: string) {
        const alertData = this.alerts.find(a => a.id === id);

        if (alertData) {
            alertData.read = false;
        }

        await this.alertsApiService.markAlertAsUnread(id);
    }

    async markAllAlertsAsRead() {
        const alertIds = this.alerts.map(a => a.id);
        await this.alertsApiService.markAlertsAsRead(alertIds);

        this.storeState.loading.reset();
    }

    async deleteAlert(id: string) {
        this.storeState.alerts = this.storeState.alerts.filter(a => a.id !== id);
        await this.alertsApiService.deleteAlert(id);
    }

    private resetService(newUser: UserModel, oldUser: UserModel) {
        if (
            newUser.currentHotelId === oldUser.currentHotelId
            && newUser.viewAs === oldUser.viewAs
        ) {
            return;
        }

        this.storeState.loading.reset();
        this.cacheService.erase(MODULES.ALERTS);
    }

    getProviderName(message: string) {
        const result = message.match(PROVIDERS_REGEXP);
        return result ? result[0] : null;
    }

    isLoading() {
        return this.storeState.loading.isLoading();
    }
}
