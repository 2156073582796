import use from '@/router/use';
import { container } from 'inversify-props';
import Sipp from '@/router/march-demo/sipp';
import { Route } from 'vue-router';
import ClusterOrChainGuard, { ClusterOrChainGuardS } from '../guards/cluster-or-chaine.guard';

export function LockedPage(prefix: string) {
    return {
        path: 'locked',
        name: `${prefix}.locked`,
        component: () => import('@/modules/common/pages/locked.page.vue'),
        meta: { modal: true },
    };
}

export function HotelListRoute(prefix: string) {
    return {
        name: `${prefix}.hotel-list`,
        path: 'hotel-list',
        beforeEnter: use([container.get<ClusterOrChainGuard>(ClusterOrChainGuardS)]),
        component: () => import('@/modules/hotels/pages/hotel-list.modal.page.vue'),
        meta: { modal: true },
    };
}

export function CommonMenuRoutes(prefix: string) {
    return [
        HotelListRoute(prefix),
        Sipp(prefix),
        LockedPage(prefix),
    ];
}
