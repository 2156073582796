/* eslint-disable camelcase */
import { Expose, plainToClass, Transform } from 'class-transformer';
import { DayRateTrendsInsightData } from '../types';

interface BEHotel {
    rooms: {
        [roomTypeId: number]: {
            price: { shown_price: number };
            price_type: string;
            room_name: string;
            meal_type_id: string;
            currency: string;
        }[]
    }
}

export default class InsightDayRateTrendModel {
    @Expose()
    @Transform((_, plain) => {
        const dayRateData = {} as Record<string, DayRateTrendsInsightData>;
        Object.entries<BEHotel>(plain.todayData).forEach(([fId, hotel]) => {
            if (!hotel.rooms) {
                return;
            }

            const room = Object.keys(hotel.rooms).length ? hotel.rooms[parseInt(Object.keys(hotel.rooms)[0], 10)][0] : null;
            const price = room?.price.shown_price;

            dayRateData[fId] = {
                name: plain.hotelNames[fId] || fId,
                price: Number.isFinite(price) ? price as number : null,
                currency: room?.currency || null,
                prevPrice: null,
                prevCurrency: null,
                filters: {
                    roomName: room?.room_name || null,
                    mealTypeId: Number.isFinite(room?.meal_type_id) ? room!.meal_type_id : null,
                    priceType: room?.price_type || null,
                },
            };
        });

        Object.entries<BEHotel>(plain.yesterdayData).forEach(([fId, hotel]) => {
            if (!hotel.rooms) {
                return;
            }

            const room = Object.keys(hotel.rooms).length ? hotel.rooms[parseInt(Object.keys(hotel.rooms)[0], 10)][0] : null;
            const price = room?.price.shown_price;
            const prevPrice = {
                prevPrice: Number.isFinite(price) ? price as number : null,
                prevCurrency: room?.currency || null,
            };

            if (!dayRateData[fId]) {
                dayRateData[fId] = {
                    name: plain.hotelNames[fId] || fId,
                    price: null,
                    currency: null,
                    filters: {
                        roomName: room?.room_name || null,
                        mealTypeId: Number.isFinite(room?.meal_type_id) ? room!.meal_type_id : null,
                        priceType: room?.price_type || null,
                    },
                    ...prevPrice,
                };
            } else {
                dayRateData[fId] = {
                    ...dayRateData[fId],
                    ...prevPrice,
                };
            }
        });

        return dayRateData;
    })
    data!: Record<string, DayRateTrendsInsightData>;

    @Expose()
    filters!: Record<string, string | number>;

    @Expose()
    @Transform((_, plain) => plain.alertData?.message || '')
    message!: string;
}
