import { Module, VuexModule } from 'vuex-module-decorators';
import HotelsRoomTypeModel from '@/modules/rooms-type-manager/models/hotels-room-type.model';
import LoadingModel from '@/modules/common/models/loading.model';

@Module
export default class HotelsRoomTypeStore extends VuexModule {
    localHotelsRoomType: HotelsRoomTypeModel | null = null;
    hotelsRoomType: HotelsRoomTypeModel | null = null;
    isDocumentChanged = false;
    changeData: {
        [hotelId: number]: {
            [provider: string]: {
                [roomName: string]: number;
            };
        };
    } = {};

    loading: LoadingModel = new LoadingModel();

    source: string | null = null;
    hotelNames: Record<number, string> = {};

    archivedRoomNames: { [hotel: number]: string[] } = {};
    isArchiveListUpdating = false;
}
