import { Module, VuexModule } from 'vuex-module-decorators';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';

@Module
export default class DocumentFiltersStore extends VuexModule {
    settings: DocumentFiltersModel = new DocumentFiltersModel();
    options: {
        [key: string]: { name: string, value: string }[];
    } = {
        priceShown: [
            { value: PRICE_SHOWN.SHOWN, name: PRICE_SHOWN.SHOWN },
            { value: PRICE_SHOWN.NET, name: PRICE_SHOWN.NET },
            { value: PRICE_SHOWN.TOTAL, name: PRICE_SHOWN.TOTAL },
        ],
    };
    compsetIdFromHighLevel: string | null= null;
}
