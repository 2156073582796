import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import use from '@/router/use';
import { container } from 'inversify-props';
import PaginationMiddleware, { PaginationMiddlewareS } from '@/router/middlewares/pagination.middleware';
import MarketsClusterLevelRoutes from '../cluster/markets.routes';
import MarketsHotelLevelRoutes from '../hotel/markets.routes';

export default function MarketsChainLevelRoutes() {
    return {
        path: 'markets',
        beforeEnter: use([container.get<FeaturesGuard>(FeaturesGuardS), container.get<PaginationMiddleware>(PaginationMiddlewareS)]),
        component: () => import('@/modules/common/pages/transition.page.vue'),
        children: [
            // TODO: Create markets routes
            // {
            //     name: 'chain.markets',
            //     path: '',
            //     component: () => import('@/modules/chain/pages/markets.page.vue'),
            //     children: [
            //         ...CommonMenuRoutes('chain.markets'),
            //     ],
            // },
            ...MarketsClusterLevelRoutes('', 'chain.markets').children!,
            MarketsHotelLevelRoutes(':hotelId', 'chain.markets.hotel'),
        ],
    };
}
