/* eslint-disable no-param-reassign */
import { Expose, Transform } from 'class-transformer';
import STATISTIC_TYPE from '@/modules/deep-analysis/constants/statistic-type.constant';
import { camelCase, isDate } from 'lodash';
import { isDateString } from 'class-validator';

export default class DeepAnalysisDocumentModel {
    @Expose()
    @Transform((_, plain) => {
        if (!plain || !Object.keys(plain).length) {
            return null;
        }

        const transformValue = (val: string | number | undefined, statistic: STATISTIC_TYPE) => {
            if (val === undefined || val === 'n') return null;
            if (isDateString(val)) { return new Date(val); }

            return val;
        };

        const transformStatistics = ([days, statData]: [string, any]) => {
            const keys = [
                'comp_set',
                'last_year',
                'my_hotel',
                'absolute_comp_set',
                'absolute_last_year',
                'update_date',
                'performance_calculated_comp_set',
                'performance_calculated_last_year',
                'performance_comp_set',
                'performance_last_year',
                'performance_my_hotel',
            ];
            const statistics = Object.keys(statData || {}) as STATISTIC_TYPE[];

            const toStatisticEntry = (key: string) => {
                const valuesEntries = statistics
                    .map(statistic => [statistic, statData[statistic] ? transformValue(statData[statistic][key], statistic) : null]);
                if (key === 'comp_set') {
                    key = 'compset';
                }

                return [
                    camelCase(key),
                    Object.fromEntries(valuesEntries),
                ];
            };

            const statisticEntries = keys.map(toStatisticEntry);

            return [days, Object.fromEntries(statisticEntries)];
        };

        const transformProviderData = ([provider, data]: [string, any]) => {
            const { currency, customer_id: customerId } = data;
            const showBy = {
                book: null,
                stay: null,
            };

            if (data.last) {
                const bookEntries = Object
                    .entries(data.last)
                    .map(transformStatistics);
                showBy.book = Object.fromEntries(bookEntries);
            }

            if (data.next) {
                const stayEntries = Object
                    .entries(data.next)
                    .map(transformStatistics);
                showBy.stay = Object.fromEntries(stayEntries);
            }

            return [provider, {
                showBy,
                currency,
                customerId,
                updateDate: new Date(plain.update_date),
            }];
        };

        const providersEntries = Object
            .entries(plain)
            .map(transformProviderData);
        const providers = Object.fromEntries(providersEntries);
        return providers;
    })
    document!: {
        [provider: string]: {
            showBy: {
                [showBy: string]: {
                    [days: string]: {
                        absoluteLastYear: {
                            [statistic: string]: number;
                        };
                        absoluteCompSet: {
                            [statistic: string]: number;
                        };
                        compset: {
                            [statistic: string]: number;
                        };
                        lastYear: {
                            [statistic: string]: number;
                        };
                        myHotel: {
                            [statistic: string]: number;
                        };
                        updateDate: {
                            [statistic: string]: Date;
                        };
                        performanceCalculatedCompSet: {
                            [statistic: string]: number;
                        };
                        performanceCalculatedLastYear: {
                            [statistic: string]: number;
                        };
                        performanceCompSet: {
                            [statistic: string]: number;
                        };
                        performanceLastYear: {
                            [statistic: string]: number;
                        };
                        performanceMyHotel: {
                            [statistic: string]: number;
                        };
                    } | null;
                } | null;
            },
            currency: string,
            currentId: string,
            updateDate: Date,
        } | null;
    };
}
