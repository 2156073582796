import { Inject, injectable } from 'inversify-props';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import CLUSTER_SORTING_VALUES from '@/modules/deep-analysis/constants/cluster-sorting-values.constant';
import COMPARE_TO from '@/modules/deep-analysis/constants/compare-to-filter.constant';
import SHOW_BY from '@/modules/deep-analysis/constants/show-by-filter.constant';
import STATISTIC_NAMES from '@/modules/deep-analysis/constants/statistic-names.constant';
import STATISTIC_TYPE from '@/modules/deep-analysis/constants/statistic-type.constant';
import TREND_TYPE from '@/modules/deep-analysis/constants/trend-type.constant';
import VALUE_TYPE from '@/modules/deep-analysis/constants/value-type.constant';
import VIEW_TYPE from '@/modules/deep-analysis/constants/view-type-filter.constant';
import DeepCompsetAnalysisCommonService, { DeepCompsetAnalysisCommonServiceS } from '@/modules/deep-analysis/deep-analysis-common.service';
import TotalIndicatorData from '@/modules/deep-analysis/interfaces/total-indicator.interface';
import ClusterService, { ClusterServiceS } from './cluster.service';
import PAGES from '../common/constants/pages.constant';
import ClusterStore from './store/cluster.store';
import ClusterApiService, { ClusterApiServiceS } from './cluster-api.service';
import ClusterDeepAnalysisModel from './models/cluster-deep-analysis.model';
import { DEEP_ANALYSIS_PROVIDERS } from './constants';

export const DeepCompsetAnalysisClusterServiceS = Symbol.for('DeepCompsetAnalysisClusterServiceS');
@injectable(DeepCompsetAnalysisClusterServiceS as unknown as string)
export default class DeepCompsetAnalysisClusterService {
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(DeepCompsetAnalysisCommonServiceS) private deepCompsetAnalysisCommonService!: DeepCompsetAnalysisCommonService;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;
    @Inject(ClusterApiServiceS) private clusterApiService!: ClusterApiService;

    readonly storeState: ClusterStore = this.storeFacade.getState('ClusterStore');

    constructor() {
        this.storeFacade.watch(() => [
            this.storeState.provider,
        ], () => {
            this.clusterService.resetLoading();
        });
    }

    get hotels() {
        this.helperService.dynamicLoading(
            this.storeState.loading,
            this.clusterService.loadData.bind(this.clusterService, PAGES.DEEP_ANALYSIS),
        );

        return this.storeState.clusterHotels as ClusterDeepAnalysisModel[];
    }

    get hotelsCount() {
        this.helperService.dynamicLoading(
            this.storeState.loading,
            this.clusterService.loadData.bind(this.clusterService, PAGES.DEEP_ANALYSIS),
        );

        return this.storeState.totalCount as number;
    }

    get sortingKey() {
        return this.storeState.deepAnalysisSorting.key;
    }

    set sortingKey(value : STATISTIC_TYPE | null) {
        this.storeState.deepAnalysisSorting.key = value;
    }

    get sortingType() {
        return this.storeState.deepAnalysisSorting.value;
    }

    set sortingType(sort: 1 | -1) {
        this.storeState.deepAnalysisSorting.value = sort;
    }

    setStatisticFilter(statistic: STATISTIC_TYPE | null, trend: TREND_TYPE | null) {
        this.storeState.deepAnalysisSettings.filterByStatistic = [statistic, trend];
    }

    getFormattedValue(val: number | string | null, statistic: STATISTIC_TYPE) {
        return this.deepCompsetAnalysisCommonService
            .getFormattedValue(val, statistic, this.isPerformance);
    }

    getAbsoluteValue(val: number | string | null) {
        return this.deepCompsetAnalysisCommonService
            .getAbsoluteValue(val);
    }

    sort(sortingType: -1 | 1, sortingKey: STATISTIC_TYPE) {
        if (this.sortingKey === sortingKey && this.sortingType === sortingType) {
            this.sortingKey = null;
            this.sortingType = 1;
        } else {
            this.sortingKey = sortingKey;
            this.sortingType = sortingType;
        }
        this.resetData();
    }

    resetData() {
        this.clusterService.resetLoading();
    }

    getTrend(statistic: STATISTIC_TYPE, value: number | null) {
        const exceptions = [STATISTIC_TYPE.CANCELLATION, STATISTIC_TYPE.AVERAGE_BOOKING_WINDOW];
        if (value === null) {
            return null;
        } if (value > 0) {
            return !exceptions.includes(statistic)
                ? TREND_TYPE.HIGH
                : TREND_TYPE.LOW;
        }
        if (value < 0) {
            return !exceptions.includes(statistic)
                ? TREND_TYPE.LOW
                : TREND_TYPE.HIGH;
        }
        return TREND_TYPE.NO_CHANGE;
    }

    async downloadExcel() {
        // TODO add notifications
        const excelData = await this.clusterApiService
            .getDeepAnalysisClusterExcel({ ...this.storeState.deepAnalysisSettings, provider: this.provider });
        const url = window.URL.createObjectURL(excelData);

        const name = 'compset-benchmark-hotel.xlsx';
        const link = document.createElement('a');
        link.style.display = 'none';
        link.setAttribute('download', name);
        link.href = url;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    get calculatedFields() {
        return this.deepCompsetAnalysisCommonService
            .getCalculatedFields(this.provider || DEEP_ANALYSIS_PROVIDERS[0], this.showBy, this.compareTo);
    }

    get statistics() {
        return this.deepCompsetAnalysisCommonService
            .getsStatistics(this.provider || DEEP_ANALYSIS_PROVIDERS[0], this.showBy);
    }

    get isPerformance() {
        return this.viewType === VIEW_TYPE.PERFORMANCE;
    }

    get viewType() {
        return this.storeState.deepAnalysisSettings.view;
    }

    set viewType(value: VIEW_TYPE) {
        this.storeState.deepAnalysisSettings.view = value;
    }

    get provider() {
        return this.storeState.daProvider;
    }

    set provider(value: typeof DEEP_ANALYSIS_PROVIDERS[number]) {
        this.storeState.daProvider = value;
    }

    get showBy() {
        return this.storeState.deepAnalysisSettings.showBy;
    }

    set showBy(value: SHOW_BY) {
        this.storeState.deepAnalysisSettings.showBy = value;
    }

    get compareTo() {
        return this.storeState.deepAnalysisSettings.compareTo;
    }

    set compareTo(value: COMPARE_TO) {
        this.storeState.deepAnalysisSettings.compareTo = value;
    }

    get updatedAt() {
        return this.hotels?.length ? this.hotels[0].updateDate : null;
    }
}
