import { Inject, injectable } from 'inversify-props';
import ClusterCompsetsService, { ClusterCompsetsServiceS } from '@/modules/cluster/cluster-compsets.service';
import { hexToRgb } from '@/modules/common/constants/default-graph-colors.constant';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import HotelsApiService, { HotelsApiServiceS } from '@/modules/hotels/hotels-api.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import HotelsStore from './store/hotels.store';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import HotelModel from './models/hotel.model';
import CacheService, { CacheServiceS, HOTELS_METHODS, MODULES } from '../common/services/cache/cache.service';

export const HotelsServiceS = Symbol.for('HotelsServiceS');
@injectable(HotelsServiceS as unknown as string)
export default class HotelsService implements Stateable {
    @Inject(HotelsApiServiceS) private hotelsApiService!: HotelsApiService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(ClusterCompsetsServiceS) private clusterCompsetsService!: ClusterCompsetsService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(CacheServiceS) private cacheService!: CacheService;

    readonly storeState: HotelsStore = this.storeFacade.getState('HotelsStore');

    missedHotelIds: number[] = [];

    constructor() {
        this.storeFacade.watch(
            () => this.userService.storeState.user,
            this.storeState.loading.reset.bind(this.storeState.loading),
        );

        this.getHotelNames = this.cacheService
            .memorize(MODULES.HOTELS, HOTELS_METHODS.getHotelNames, this.getHotelNames.bind(this));
    }

    async loadData() {
        if (!this.userService.currentUserHotelInited) {
            await this.userService.storeState.loading.whenLoadingFinished();
        }

        const { currentHotelId } = this.userService;
        if (currentHotelId === null) {
            return false;
        }

        const [hotels, myHotels] = await Promise.all([this.hotelsApiService.getHotels(currentHotelId), this.hotelsApiService.getMyHotels()]);
        this.storeState.hotels = hotels || [];
        this.storeState.myHotels = myHotels || [];
        this.missedHotelIds = [];

        return true;
    }

    get myHotels() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        let myHotels = [...this.storeState.myHotels];
        let currentHotel: HotelModel | null = null;
        if (this.userService.isViewAsHotel && this.storeState.myHotels[0]) {
            [currentHotel] = this.storeState.myHotels;
            myHotels.shift();
        }

        myHotels = myHotels.sort((x, y) => {
            if (x.name.toLowerCase() < y.name.toLowerCase()) { return -1; }
            if (x.name.toLowerCase() > y.name.toLowerCase()) { return 1; }
            return 0;
        });

        if (currentHotel) {
            myHotels.unshift(currentHotel);
        }
        return myHotels;
    }

    get allHotels() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.hotels;
    }

    addLocalHotel(hotel: HotelModel) {
        this.storeState.hotels.push(hotel);
    }

    getHotelName(hotelId: number, disableHotelLoading: boolean = false) {
        if (!hotelId) {
            return '';
        }

        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));

        const { hotels, myHotels, missedHotels } = this.storeState;
        const searchedHotel = [...myHotels, ...hotels, ...missedHotels].find(hotel => hotel.id === hotelId);

        // 1 request per 1 missing name. Temporary solution
        if (!searchedHotel && !disableHotelLoading && !this.missedHotelIds.some(id => id === hotelId)) {
            this.loadHotel(hotelId);
            this.missedHotelIds.push(hotelId);
            return `${hotelId}`;
        }

        return searchedHotel ? searchedHotel.name : String(hotelId);
    }

    async getHotelNames(hotelIds: number[]) {
        const data = await this.hotelsApiService.getHotelsById(hotelIds);

        if (!data) return {};

        return Object.fromEntries(data.map(hotelData => [hotelData.id, hotelData.name]));
    }

    getHotelGeoLocation(hotelId: number) {
        if (!hotelId) {
            return { lat: 0, lng: 0 };
        }

        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));

        const { hotels, myHotels, missedHotels } = this.storeState;
        const searchedHotel = [...hotels, ...myHotels, ...missedHotels].find(hotel => hotel.id === hotelId);

        // 1 request per 1 missing name. Temporary solution
        if (!searchedHotel && !this.missedHotelIds.some(id => id === hotelId)) {
            this.loadHotel(hotelId);
            this.missedHotelIds.push(hotelId);
            return { lat: 0, lng: 0 };
        }

        return (searchedHotel && searchedHotel.geoLocation) ? searchedHotel.geoLocation : { lat: 0, lng: 0 };
    }

    protected async loadHotel(notFoundHotelId: number) {
        const DEFAULT_HOTEL = {
            id: notFoundHotelId,
            name: String(notFoundHotelId),
        };

        const newHotels = await this.hotelsApiService
            .getHotelsById([notFoundHotelId]) || [DEFAULT_HOTEL];

        const missedHotels = [...this.storeState.missedHotels];
        const updatedHotels = missedHotels.concat(newHotels);

        const uniqueUpdatedHotelIds = Array
            .from(new Set(updatedHotels.map(hotel => hotel.id)));

        this.storeState.missedHotels = uniqueUpdatedHotelIds
            .map(hotelId => (
                updatedHotels.find(hotel => hotel.id === hotelId)
            ))
            .filter(hotel => hotel) as HotelModel[];
    }

    getHotelsGraphColor(compsetId?: string | null): { [hotelId: string]: string } {
        const { chartColors } = this.userService;
        const colors: { [hotelId: string]: string } = {};

        this.getHotelList(compsetId)
            .forEach((hotelId, index) => {
                colors[hotelId] = chartColors[index];
            });

        return colors;
    }

    getHotelsGraphColorRgb(compsetId?: string | null): { [hotelId: string]: string } {
        const colors: { [hotelId: string]: string } = {};
        const { chartColors } = this.userService;

        if (chartColors === null) {
            return colors;
        }

        this.getHotelList(compsetId).forEach((hotelId, index) => {
            colors[hotelId] = hexToRgb(chartColors[index]);
        });
        return colors;
    }

    getHotelList(compsetId?: string | null) {
        const compset = this.compsetsService.getCompset(compsetId!)
            || this.clusterCompsetsService.getCompsetById(compsetId!)
            || this.compsetsService.currentCompset;

        return compset
            ? compset.competitors
            : [];
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    async awaitLoading() {
        await this.storeState.loading.whenLoadingFinished();
    }
}
