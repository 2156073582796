import StorageService, { StorageServiceS } from '@/modules/common/services/storage.service';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import EVENT_STATUS_SETTINGS from '@/modules/events/constants/event-statuses.constant';
import EVENT_TYPE_SETTINGS from '@/modules/events/constants/event-types-settings.constant';
import EventsSettingsModel from '@/modules/events/models/events-setting.model';
import EventsModel from '@/modules/events/models/events.model';
import EventsStore from '@/modules/events/store/events.store';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { Inject, injectable } from 'inversify-props';
import { $enum } from 'ts-enum-util';
import HelperService, { HelperServiceS } from '../common/services/helper.service';
import EventsApiService, { EventsApiServiceS } from './events-api.service';

const SETTINGS_KEY = 'event-manager-settings';

interface IEventsFilterSettings {
    settings: EventsSettingsModel,
    userId: string
}

export const EventsFilterServiceS = Symbol.for('EventsFilterServiceS');
@injectable(EventsFilterServiceS as unknown as string)
export default class EventsFilterService {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(StorageServiceS) private storageService!: StorageService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(EventsApiServiceS) private eventsApiService!: EventsApiService;

    readonly storeState: EventsStore = this.storeFacade.getState('EventsStore');

    get settings(): EventsSettingsModel { return this.storeState.settings; }

    set settings(settings : EventsSettingsModel) {
        this.storeState.settings = settings;
        if (this.userService.user) {
            this.storageService
                .setItem(SETTINGS_KEY, { settings, userId: this.userService.user.id });
        }
    }

    setSettings(defaults: { countries?: string[] }) {
        const settingsData = this.storageService.getItem(SETTINGS_KEY) as IEventsFilterSettings;

        if (settingsData && this.userService.user && settingsData.userId === this.userService.user.id) {
            this.settings = settingsData.settings;
        } else {
            this.storageService.removeItem(SETTINGS_KEY);
            this.setDefaultSettings(defaults);
        }
    }

    private async loadCountries() {
        this.storeState.countries = await this.eventsApiService.loadAllCountries();
        return true;
    }

    get allCountries() {
        this.helperService.dynamicLoading(this.storeState.countriesLoading, this.loadCountries.bind(this));
        return this.storeState.countries;
    }

    private async setDefaultSettings(defaults: { countries?: string[] }) {
        this.storeState.settings.status = EVENT_STATUS_SETTINGS.ALL;

        if (defaults.countries) {
            const { holiday } = this.storeState.currentMonthEvents;

            const countries = holiday
                .filter(e => !!e.country && !!e.countryCode)
                .filter(e => {
                    const { countryCode } = e!;
                    const defaultCountries = defaults.countries as string[];

                    return !!defaultCountries
                        .find(code => code.toLowerCase() === countryCode!.toLowerCase());
                })
                .map((event: EventsModel) => event.country) as string[];

            this.storeState.settings.countries = Array.from(new Set(countries));
        } else {
            await this.storeState.countriesLoading.whenLoadingFinished();
            this.storeState.settings.countries = [...this.allCountries];
        }

        this.storeState.settings.types = $enum(EVENT_TYPE_SETTINGS).map(item => item);
    }
}
