import { Route, RouteConfig } from 'vue-router';
import { CommonMenuRoutes } from './common.routes';
import { ScheduledReports as RatesScheduledReports } from './hotel/rates.routes';
import { ScheduledReports as GuestReviewScheduledReports } from './hotel/ranking.routes';
import { ScheduledReports as MarketsScheduledReports } from './hotel/markets.routes';
import { ScheduledReports as DILiteScheduledReports } from './hotel/di-lite.routes';
import { ScheduledAnalysisReports as AnalysisScheduledReports } from './rates.routes';
import { ClusterScheduledReports as ClusterRatesScheduledReports } from './cluster/rates.routes';
import { ScheduledReports as ClusterGuestReviewsScheduledReports } from './cluster/ranking.routes';
import { ScheduledReports as ClusterMarketsScheduledReports } from './cluster/markets.routes';

function DownloadMappingExcel(prefix: string) {
    return {
        name: `${prefix}.download-excel-popup`,
        path: 'download-excel',
        component: () => import('@/modules/settings/components/download-mapping.popup.vue'),
        meta: { modal: true },
    };
}

export default function SettingsPageRoutes() {
    const toScheduledRoute = (method: (n: string) => RouteConfig, type: string) => {
        const child = method('');
        child.path = `edit-${type}-report/:reportId`;
        child.name = `settings.${type}-edit-report`;

        return child;
    };

    return {
        path: '/settings/:hotelId?',
        component: () => import('@/modules/common/pages/hotel-transition.page.vue'),
        props: (r: Route) => ({
            hotelId: Number(r.params.hotelId),
        }),

        children: [
            {
                name: 'settings',
                component: () => import('@/modules/settings/pages/index.page.vue'),
                path: '',
                children: [
                    ...CommonMenuRoutes('settings'),
                    DownloadMappingExcel('settings'),

                    toScheduledRoute(RatesScheduledReports, 'rates'),
                    toScheduledRoute(AnalysisScheduledReports, 'rates-compare'),
                    toScheduledRoute(GuestReviewScheduledReports, 'guest-reviews'),
                    toScheduledRoute(MarketsScheduledReports, 'visibility'),
                    toScheduledRoute(DILiteScheduledReports, 'di-lite'),

                    toScheduledRoute(ClusterRatesScheduledReports, 'cluster-rates'),
                    toScheduledRoute(ClusterGuestReviewsScheduledReports, 'cluster-guest-reviews'),
                    toScheduledRoute(ClusterMarketsScheduledReports, 'cluster-visibility'),
                ],
            },
        ],
    };
}
