
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomNotificationService, { CustomNotificationServiceS } from '@/modules/common/modules/custom-notification/custom-notification.service';
import ExcelDownload from '@/modules/common/modules/custom-notification/components/excel-download/excel-download.vue';
import NOTIFICATION_TYPES from '@/modules/common/modules/custom-notification/constants/notification-types.constant';

@Component({
    components: {
        ExcelDownload,
    },
})
export default class NotificationsContainer extends Vue {
    @Inject(CustomNotificationServiceS) private customNotificationService!: CustomNotificationService;

    get notifications() {
        return this.customNotificationService.notifications;
    }

    isExcelNotification(type: NOTIFICATION_TYPES) {
        return type === NOTIFICATION_TYPES.EXCEL;
    }
}
