import { Inject, injectable } from 'inversify-props';
import { AxiosRequestConfig } from 'axios';
import TokenSessionStorageService, { TokenSessionStorageServiceS } from '@/modules/auth/token-session-storage.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import ConfigService, { ConfigServiceS } from '@/modules/config/config.service';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import SocketService, { SocketServiceS } from '@/modules/common/modules/socket/socket.service';
import AuthStore from './store/auth.store';

export const AuthServiceS = Symbol.for('AuthServiceS');
@injectable(AuthServiceS as unknown as string)
export default class AuthService {
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(ApiServiceS) private apiService!: ApiService;
    @Inject(TokenSessionStorageServiceS) private tokenSessionStorageService!: TokenSessionStorageService;
    @Inject(ConfigServiceS) private configService!: ConfigService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(SocketServiceS) private socketService!: SocketService;

    readonly storeState: AuthStore = this.storeFacade.getState('AuthStore');

    constructor() {
        this.apiService.setRequestInterceptor(this.setTokenToHeaders.bind(this));
        this.socketService.onConnect(this.socketHandshake.bind(this));
    }

    setTokenToHeaders(req: AxiosRequestConfig) {
        if (!this.storeState.auth.token) {
            return req;
        }

        if (!req.headers) {
            // eslint-disable-next-line no-param-reassign
            req.headers = {};
        }

        // eslint-disable-next-line no-param-reassign
        req.headers.Authorization = `Bearer ${this.storeState.auth.token}`;
        return req;
    }

    socketHandshake() {
        if (!this.userService.currentHotelId || !this.storeState.auth.token) {
            return;
        }

        this.socketService.sendHandshake(this.userService.currentHotelId, this.storeState.auth.token);
    }

    async authentication(token: string | null = null) {
        if (token !== null) {
            this.tokenSessionStorageService.setToken(token);
        }

        if (this.tokenSessionStorageService.token !== null) {
            this.storeState.auth.token = this.tokenSessionStorageService.token;
            await this.userService.initUser(this.storeState.auth.token);
            this.socketHandshake();
        }

        await this.verifyAuth();
    }

    async isLogin() {
        if (this.userService.isLoadingAndInitialized) {
            return true;
        }

        if (!this.userService.user) {
            await this.authentication();
            return Boolean(this.userService.user);
        }
        return true;
    }

    logout() {
        this.tokenSessionStorageService.removeToken();
        localStorage.removeItem('currentChain');

        window.location.href = `${this.configService.ssoUrl}?logOut=true`;
    }

    async verifyAuth() {
        await this.apiService.get('/users/verification');
        return true;
    }

    get loginUrl() {
        const query = `?appName=Fornova${this.configService.fornovaApp || 'CI'}&redirectUrl=${window.location.origin}/auth`;
        return this.configService.ssoUrl + encodeURI(query);
    }

    get oldToken() {
        return this.storeState.token;
    }
}
