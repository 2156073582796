import { container } from 'inversify-props';
import use from '../../use';

import ClusterGuard, { ClusterGuardS } from '../../guards/cluster.guard';
import HomepageRedirectMiddleware, { HomepageRedirectMiddlewareS } from '../../middlewares/homepage-redirect.middleware';

import { CommonMenuRoutes } from '../common.routes';
import DeepAnalysisClusterLevelRoutes from './deep-analysis.routes';
import MarketsClusterLevelRoutes from './markets.routes';
import RankingClusterLevelRoutes from './ranking.routes';
import RatesClusterLevelRoutes from './rates.routes';
import EventsManagerRoutes from '../event-manager.routes';
import PromotionsClusterLevelRoutes from './promotions.routes';
import DiLiteClusterRoutes from './di-lite.routes';
import RatesHotelLevelRoutes from '../hotel/rates.routes';
import MarketsHotelLevelRoutes from '../hotel/markets.routes';
import RankingHotelLevelRoutes from '../hotel/ranking.routes';
import DiLiteHotelLevelRoutes from '../hotel/di-lite.routes';
import PromotionsHotelLevelRoutes from '../hotel/promotions.routes';
import DeepCompsetHotelLevelRoutes from '../hotel/deep-analysis.routes';

export default function ClusterBranch() {
    return {
        path: 'cluster',
        component: () => import('@/modules/common/pages/transition.page.vue'),
        beforeEnter: use([
            container.get<ClusterGuard>(ClusterGuardS),
        ]),
        children: [
            {
                name: 'cluster-home',
                path: '',
                component: () => import('@/modules/cluster/pages/home.page.vue'),
                beforeEnter: use([
                    container.get<HomepageRedirectMiddleware>(HomepageRedirectMiddlewareS),
                ]),
                children: [
                    ...CommonMenuRoutes('cluster-home'),
                ],
            },
            {
                name: 'cluster.insights',
                path: 'insights',
                redirect: '/',
            },

            RatesClusterLevelRoutes('rates', 'cluster.rates'),
            RatesHotelLevelRoutes('rates/:hotelId', 'cluster.rates.hotel'),
            MarketsClusterLevelRoutes('markets', 'cluster.markets'),
            MarketsHotelLevelRoutes('markets/:hotelId', 'cluster.markets.hotel'),
            RankingClusterLevelRoutes('ranking', 'cluster.ranking'),
            RankingHotelLevelRoutes('ranking/:hotelId', 'cluster.ranking.hotel'),
            DiLiteClusterRoutes('di-lite', 'cluster.di-lite'),
            DiLiteHotelLevelRoutes('di-lite/:hotelId', 'cluster.di-lite.hotel'),
            PromotionsClusterLevelRoutes('promotions', 'cluster.promotions'),
            PromotionsHotelLevelRoutes('promotions/:hotelId', 'cluster.promotions.hotel'),
            DeepAnalysisClusterLevelRoutes('compset-benchmark', 'cluster.compset-benchmark'),
            DeepCompsetHotelLevelRoutes('compset-benchmark/:hotelId', 'cluster.compset-benchmark.hotel'),

            EventsManagerRoutes('events-manager', 'cluster.events-manager'),
        ],
    };
}
