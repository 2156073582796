import { Inject, injectable } from 'inversify-props';
import moment from 'moment';

import type { ProviderData } from '@/modules/di-lite/interfaces/provider-data.interface';
import type Day from '@/modules/common/types/day.type';
import DiLiteStore from '@/modules/di-lite/store/di-lite.store';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import RatesDocumentAllModel from '@/modules/rates/models/rates-document-all.model';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import Price from '@/modules/common/types/price.type';
import ASSESSMENTS_TYPES from '@/modules/common/constants/assessments-types.constant';
import COMPSET_TYPE from '@/modules/compsets/constants/compset-type.constant';

import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import RatesApiService, { RatesApiServiceS, RatesRequestMode } from '@/modules/rates/rates-api.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import UserSettingsService, { UserSettingsS } from '../user/user-settings.service';
import MealTypesService, { MealTypesServiceS } from '../meal-types/meal-types.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '../document-filters/document-filters.service';
import DiLiteMarketService, { DiLiteMarketServiceS } from './di-lite-market.service';
import AllChannelsSettingsModel, { DILiteFilterDevice } from './models/all-channels-settings.model';
import RatesCommonService, { RatesCommonServiceS } from '../common/modules/rates/rates-common.service';

import UserSettings from '../user/store/user-settings.store';
import PRICE from '../common/modules/rates/constants/price.enum';
import PRICE_SHOWN from '../rates/constants/price-shown.constant';
import ANY_MEAL_TYPE from '../meal-types/constants/any-meal-type.constant';
import ANY_ROOM_TYPE from '../room-types/constants/any-room-type.constant';
import PRICE_TYPE from '../document-filters/constants/price-type.constant';

export const DiLiteAllChannelsServiceS = Symbol.for('DiLiteAllChannelsServiceS');
@injectable(DiLiteAllChannelsServiceS as unknown as string)
export default class DiLiteAllChannelsService implements Stateable {
    @Inject(DiLiteMarketServiceS)
    private diLiteMarketService!: DiLiteMarketService;

    @Inject(RatesApiServiceS)
    private ratesApiService!: RatesApiService;

    @Inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    @Inject(StoreFacadeS)
    private storeFacade!: StoreFacade;

    @Inject(HelperServiceS)
    private helperService!: HelperService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Inject(RatesCommonServiceS)
    private ratesCommonService!: RatesCommonService;

    @Inject(UserSettingsS)
    private userSettingsService!: UserSettingsService;

    @Inject(MealTypesServiceS)
    private mealTypeService!: MealTypesService;

    readonly storeState: DiLiteStore = this.storeFacade.getState('DiLiteStore');
    readonly settingsState: UserSettings = this.storeFacade.getState('UserSettings');

    readonly brand: string = 'bcom';

    private currentDocument!: RatesDocumentAllModel;

    constructor() {
        // NOTE Applying default filters
        const { settings } = this.storeState;

        this.storeFacade.watch(
            () => [
                this.userSettingsService.defaultFilters.price,
                this.userSettingsService.defaultFilters.numberOfGuests,
                this.userSettingsService.defaultFilters.mealType,
            ],
            () => {
                const { defaultFilters } = this.userSettingsService;

                settings.priceType = defaultFilters.price;
                settings.numberOfGuests = defaultFilters.numberOfGuests;

                const defaultMealType = this.mealTypeService.getMealType(defaultFilters.mealType);
                settings.mealTypeId = defaultMealType ? defaultMealType.id : -1;
            },
            { immediate: true },
        );

        this.storeFacade.watch(
            () => this.mealTypeService.mealTypes,
            () => {
                const { mealTypes } = this.mealTypeService;
                const { defaultFilters } = this.userSettingsService;

                if (mealTypes.length <= 1 && settings.mealTypeId !== -1) { return; }

                const neededMealType = this.mealTypeService.getMealType(defaultFilters.mealType)!;
                settings.mealTypeId = neededMealType ? neededMealType.id : -1;
            },
        );

        this.storeFacade.watch(
            () => this.userSettingsService.userStoreState.user?.currentHotelId,
            () => {
                const { user } = this.userSettingsService.userStoreState;
                this.resetFilters(user?.viewAs !== 'hotel');
            },
        );

        this.storeFacade.watch(() => [
            this.allProviders,
        ], ((newValue, oldValue) => {
            const [newCompset] = newValue;
            const [currentCompset] = oldValue;

            if (newCompset && JSON.stringify(currentCompset) !== JSON.stringify(newCompset)) {
                this.provider = null;
                this.diLiteMarketService.resetLoading();
                return this.resetLoading.call(this);
            }
            return {};
        }));

        this.storeFacade.watch(() => [
            this.documentFiltersService.storeState.settings.compsetId,
            this.settings.device,
        ], () => {
            this.shownProviders = [];
        });

        this.storeFacade.watch(() => [
            this.settingsState.currencies.displayCurrency,
            this.storeState.settings.mealTypeId,
            this.storeState.settings.roomTypeId,
            this.storeState.settings.priceType,
            this.storeState.settings.device,
            this.documentFiltersService.storeState.settings.los,
            this.documentFiltersService.storeState.settings.pos,
            this.documentFiltersService.storeState.settings.compsetId,
            this.storeState.settings.numberOfGuests,
            this.userSettingsService.userStoreState.user?.currentHotelId,
            this.documentFiltersService.storeState.settings.month,
            this.documentFiltersService.storeState.settings.year,
        ], () => {
            this.resetLoading();
        });

        this.storeFacade.watch(() => [
            this.storeState.settings.mealTypeId,
            this.storeState.settings.roomTypeId,
            this.storeState.settings.priceType,
            this.storeState.settings.device,
            this.documentFiltersService.priceShown,
        ], () => {
            this.mapData(this.currentDocument);
        });
    }

    resetLoading() {
        this.storeState.pricesData = null;
        this.storeState.loading.reset();
    }

    async loadData(): Promise<boolean> {
        const documentSettings = this.documentFiltersService.settings;

        if (!this.compset) {
            return false;
        }

        if (!this.availableLoses.includes(documentSettings.los!)) {
            [documentSettings.los] = this.availableLoses;
            this.storeState.loading.start();
            return false;
        }

        this.currentDocument = await this.loadDocument(documentSettings) as RatesDocumentAllModel;

        this.provider = this.mainProvider;
        this.mapData(this.currentDocument);

        return true;
    }

    private loadDocument(documentSettings: DocumentFiltersModel | null) {
        if (!documentSettings) {
            return null;
        }
        const { displayCurrency } = this.settingsState.currencies;
        const unitedSettings = {
            ...documentSettings,
            ...this.settings,
            provider: 'all',
        };

        return this.ratesApiService
            .getRatesDocument(unitedSettings, displayCurrency, {}, RatesRequestMode.DILITE);
    }

    get availableLoses() {
        const { compset } = this;

        const losList = compset
            ? compset.los
            : [1];

        return losList;
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.pricesData;
    }

    get isBrandAvailable() {
        return this.allProviders.includes(this.brand);
    }

    get compset() {
        return this.compsetsService.getCompset(this.filters.compsetId)
            || this.compsetsService.currentCompset || null;
    }

    get providers() {
        if (!this.allProviders.length) {
            return [];
        }

        const { device } = this.settings;

        const withoutCommonProviders = (provider: string) => !['all', 'cheapest'].includes(provider);
        const withoutBrand = (provider: string) => provider !== this.brand;
        const byDevice = (provider: string) => {
            switch (device) {
                case DILiteFilterDevice.MOBILE:
                    return provider.includes('mobile');

                case DILiteFilterDevice.DESKTOP:
                    return !provider.includes('mobile');

                default:
                    return true;
            }
        };

        let providers = this.allProviders
            .filter(withoutCommonProviders)
            .filter(withoutBrand);

        if (this.allProviders.includes('booking')) {
            providers = [...providers.filter((provider: string) => provider !== 'booking')];
            providers.unshift('booking');
        }

        return providers
            .filter(byDevice);
    }

    get shownProviders() {
        return this.settings.shownProviders;
    }

    set shownProviders(list: string[]) {
        this.settings.shownProviders = list;
    }

    get allProviders() {
        if (!this.compset) {
            return [];
        }

        return this.compset.rateProviders;
    }

    get mainProvider() {
        if (this.isBrandAvailable) {
            return this.brand;
        }
        if (this.providers.includes('booking')) {
            return 'booking';
        }
        return this.providers[0];
    }

    get provider() {
        return this.storeState.provider;
    }

    set provider(value: string | null) {
        this.storeState.provider = value;
    }

    get days() {
        return this.documentFiltersService.days;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    get demandData() {
        return this.storeState.demandData;
    }

    get currency() {
        return this.storeState.currency;
    }

    get filters() {
        return this.documentFiltersService.settings;
    }

    get settings() {
        return this.storeState.settings;
    }

    getDateByDay(day: Day) {
        return moment(new Date(this.documentFiltersService.year, this.documentFiltersService.month)).add(day - 1, 'day')
            .format('DD-MM-YYYY');
    }

    isBasic(provider: string): boolean {
        return !!provider && provider.includes('basic');
    }

    getPriceData(date: string, provider: string) {
        return (
            this.data
            && this.data[date]
            && this.data[date][provider]
        ) || null;
    }

    getPrice(date: string, provider: string): number | null {
        const priceData = this.getPriceData(date, provider);
        return priceData ? priceData.price : null;
    }

    getReferencePrice(day: string) {
        if (this.isBrandAvailable) {
            return this.getPrice(day, this.brand);
        }

        const { provider } = this.storeState;
        if (!provider) return null;

        return this.getPrice(day, provider);
    }

    getMaxDiff(day: string): { diff: number; diffRatio: number; } | null {
        const maxDiff : { diff: number; diffRatio: number; } | null = null;

        const providersPrices = this.shownProviders
            .map((provider: string) => {
                const providerData = this.getPriceData(day, provider);
                return providerData ? providerData.price : null;
            })
            .filter(price => !!price);

        let { provider } = this.storeState;

        if (provider !== 'bcom' && !this.shownProviders.includes(provider!)) {
            [this.storeState.provider] = this.shownProviders;
            provider = this.storeState.provider;
        }

        const mainPrice = provider
            ? this.getPrice(day, provider)
            : null;

        if (!mainPrice) {
            return maxDiff;
        }

        if (mainPrice && !providersPrices.length) {
            return {
                diff: 0,
                diffRatio: 0,
            };
        }

        const diffs = providersPrices
            .map(price => {
                let diff = 0;
                let diffRatio = 0;
                if (mainPrice && price) {
                    const averagePrice = (price + mainPrice) / 2;
                    diff = price - mainPrice;
                    diffRatio = (diff / averagePrice) * 100;
                }
                return {
                    diff: Math.abs(diff),
                    diffRatio: Math.round(diffRatio),
                };
            });
        const sortedDiffs = diffs
            .sort((a, b) => Math.abs(b.diff) - Math.abs(a.diff));

        return sortedDiffs[0];
    }

    private async resetFilters(isClusterMode: boolean) {
        const s = new AllChannelsSettingsModel();

        if (!isClusterMode) {
            s.priceType = PRICE_TYPE.LOWEST;
            s.mealTypeId = ANY_MEAL_TYPE.id;
            s.roomTypeId = ANY_ROOM_TYPE.id;
        } else {
            s.priceType = this.storeState.settings.priceType;
            s.mealTypeId = this.storeState.settings.mealTypeId;
            s.roomTypeId = this.storeState.settings.roomTypeId;
        }

        s.device = DILiteFilterDevice.ALL;
        s.numberOfGuests = 2;
        s.provider = this.providers.includes('booking')
            ? 'booking'
            : this.providers[0] || 'booking';

        this.storeState.settings = s;
    }

    private getDemandFromDocument(day: number, document: RatesDocumentAllModel) {
        if (document && document.demand && document.demand[day]) {
            return Number(document.demand[day]) * 100;
        }
        return null;
    }

    private getTableAssessment(price: Price | null, day: Day, document: RatesDocumentAllModel): ASSESSMENTS_TYPES.GOOD| ASSESSMENTS_TYPES.BAD | null {
        if (!this.compsetsService.currentCompset || !price || !this.provider) {
            return null;
        }

        const { type } = this.compsetsService.currentCompset;
        const mainPrice = this.getProviderPriceFromDocument(day, this.provider, document);

        if (mainPrice === null) {
            return null;
        }

        if (type === COMPSET_TYPE.HIGH || type === COMPSET_TYPE.MEDIAN) {
            return price > mainPrice ? ASSESSMENTS_TYPES.GOOD : ASSESSMENTS_TYPES.BAD;
        }

        return price <= mainPrice ? ASSESSMENTS_TYPES.GOOD : ASSESSMENTS_TYPES.BAD;
    }

    private mapData(document: RatesDocumentAllModel) {
        const providersData: {
            [date: string]: {
                [provider: string]: ProviderData
            }
        } = {};
        const demandData: (number | null)[] = [];

        this.storeState.currency = document && (document.currency || null);

        this.documentFiltersService.days.forEach(day => {
            const date = moment(day, 'DD-MM-YYYY');

            const demand = this.getDemandFromDocument(date.date(), document);
            demandData.push(demand);

            providersData[day] = {};

            const providers = this.isBrandAvailable
                ? [...this.providers, 'bcom']
                : this.providers;

            providers.forEach(provider => {
                providersData[day][provider] = this.calculatePriceData(day, provider, document);
            });
        });

        this.storeState.pricesData = providersData;
        this.storeState.demandData = demandData;

        if (!this.shownProviders.length) {
            this.shownProviders = this.providers;
        } else {
            this.shownProviders = this.shownProviders
                .filter(provider => this.providers.includes(provider));
        }
    }

    private calculatePriceData(day: Day, provider: string, document: RatesDocumentAllModel) {
        const data: ProviderData = {
            price: null,
            isOutOfRange: false,
            isNoData: false,
            isNA: false,
            isSoldOut: false,
            isBasic: false,
            assessmentType: null,
            currency: null,
            losRestriction: false,
            isNetCalc: false,
        };

        if (this.isOutOfRange(document)) {
            data.isOutOfRange = true;
        } else if (this.isNoData(day, document)) {
            data.isNoData = true;
        } else if (this.isNa(day, provider, document)) {
            data.isNA = true;
        } else if (this.isSoldOut(day, provider, document)) {
            data.isSoldOut = true;
        } else {
            data.price = this.getProviderPriceFromDocument(day, provider, document);
            data.assessmentType = this.getTableAssessment(data.price, day, document);

            const isNet = this.documentFiltersService.priceShown === PRICE_SHOWN.NET;

            if (isNet) {
                const { rooms } = document.checkinDates![day]![provider];
                data.isNetCalc = rooms[Number(Object.keys(rooms)[0])][0].isNetCalc;
            }
        }

        data.isBasic = this.isBasic(provider);
        data.currency = this.currency;

        if (document && document.checkinDates![day]) {
            const providerData = document.checkinDates![day]![provider];
            if (providerData) {
                data.losRestriction = providerData.losResctricted;
            }
        }

        return data;
    }

    private getProviderPriceFromDocument(day: Day, provider: string, document: RatesDocumentAllModel) {
        const data = this.checkinDates(day, document);

        if (!data[provider]) {
            return PRICE.NA;
        }

        const providerData = data[provider];

        const room = providerData
            ? Object.values(providerData.rooms || {})?.[0]?.[0] || null
            : null;

        if (!room) return PRICE.NA;

        return this.ratesCommonService.switchPrice(room);
    }

    private checkinDates(day: Day, document: RatesDocumentAllModel | null) {
        const checkinDate = document && document.checkinDates && document.checkinDates[day];
        if (!checkinDate) {
            return {};
        }
        return checkinDate;
    }

    private isNoData(day: Day, document: RatesDocumentAllModel | null) {
        if (!document || !document.checkinDates) {
            return true;
        }

        const allRooms = this.checkinDates(day, document);
        const isNoRooms = !Object.keys(allRooms).length;

        if (isNoRooms) {
            return true;
        }

        return !allRooms;
    }

    private isOutOfRange(document: RatesDocumentAllModel | null) {
        return !document;
    }

    private isSoldOut(day: Day, provider: string, document: RatesDocumentAllModel | null) {
        if (!document || !document.checkinDates) {
            return false;
        }

        const price = this.getProviderPriceFromDocument(day, provider, document);

        return !price;
    }

    private isNa(day: Day, provider: string, document: RatesDocumentAllModel | null) {
        if (!document) {
            return false;
        }

        return this.getProviderPriceFromDocument(day, provider, document) === PRICE.NA;
    }
}
