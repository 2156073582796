import { Route } from 'vue-router';
import { container } from 'inversify-props';
import use from '@/router/use';
import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import { InsightDayRoute } from '@/modules/insights/constants';
import { CommonMenuRoutes, LockedPage } from '../common.routes';

const InsightDayPopup = (prefix: string) => ({
    name: `${prefix}.day-insight`,
    path: 'group/:groupId',
    props: (route: Route) => ({ ...route.params }),
    component: () => import('@/modules/insights/components/popups/insight.popup.vue'),
    meta: { modal: true },
});

export default function InsightsHotelRoutes(prefix: string) {
    return {
        path: 'insights',
        props: (r: Route) => ({
            hotelId: Number(r.params.hotelId),
        }),
        beforeEnter: use([
            container.get<FeaturesGuard>(FeaturesGuardS),
        ]),
        component: () => import('@/modules/common/pages/hotel-transition.page.vue'),
        children: [{
            name: `${prefix}.insights`,
            path: '',
            component: () => import('@/modules/insights/pages/insights.page.vue'),
            children: [
                LockedPage(`${prefix}.insights`),
                InsightDayPopup(`${prefix}.insights`),
                ...CommonMenuRoutes(`${prefix}.insights`),
            ],
        }],
    };
}
