import 'reflect-metadata';
import 'es6-shim';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
// @ts-ignore
import ElementLocale from 'element-ui/lib/locale';
import VueRouter from 'vue-router';
import VueGtag from 'vue-gtag';
import VueProgressBar from 'vue-progressbar';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import VueObserveVisibility from 'vue-observe-visibility';

import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import './modules/common/assets/css/icon-element-ui.css';
import 'splitpanes/dist/splitpanes.css';
import 'leaflet/dist/leaflet.css';

import { bootstrapContainer, loadAsyncModules } from '@/app.container';
import App from './app.vue';
import './registerServiceWorker';
import initRouter from './router';
import messages, { i18n } from './languages';

class AppBootstrap {
    private router!: VueRouter;
    async init() {
        await this.loadDependencyContainer();
        this.router = initRouter();
        this.configApp();
        this.loadVueApp();
    }

    private async loadDependencyContainer() {
        bootstrapContainer();
        await loadAsyncModules();
    }

    private configApp(): void {
        /**
         * Here is no Vue.use(Vuex) cause Vuex is workable only with the Vue instance.
         * Vuex depends largely on Vue for its reactivity inner workings.
         */
        Vue.config.productionTip = false;
        Vue.use(VueRouter);
        Vue.use(VueI18n);
        Vue.use(VueProgressBar, {
            color: '#A1A2FF', // TODO get these colors from scss
            failedColor: 'red',
            height: '5px',
        });
        Vue.use(PerfectScrollbar);
        // User id is set in user.service
        Vue.use(VueGtag, {
            config: {
                id: 'G-VX7L6WZFM8',
            },
        });
        Vue.use(VueObserveVisibility);
        ElementLocale.use(messages.en);
    }

    private loadVueApp(): void {
        new Vue({
            router: this.router,
            i18n: new VueI18n({ locale: 'en', messages }),
            render: h => h(App),
            mounted() {
                i18n.$t = this.$t.bind(this) as any;
            },
        }).$mount('#app');
    }
}

(async () => {
    const app = new AppBootstrap();
    await app.init();
})();
