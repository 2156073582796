import { Inject, injectable } from 'inversify-props';
import moment from 'moment';

import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import MarketsApiService, { MarketsApiServiceS } from '@/modules/markets/markets-api.service';
import type Day from '@/modules/common/types/day.type';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import ClusterCompsetsService, { ClusterCompsetsServiceS } from '@/modules/cluster/cluster-compsets.service';
import MarketsDocumentModel from '@/modules/markets/models/markets-document.model';
import MarketSettingsModel from '@/modules/markets/models/market-settings.model';
import MarketsDocumentItemModel from '@/modules/markets/models/markets-document-item.model';
import MarketsCompsetMainModel from '@/modules/cluster/models/markets-compset-main.model';
import MarketsStore from '@/modules/markets/store/markets.store';
import MarketsHistoryApiService, { MarketsHistoryApiServiceS } from './markets-history-api.service';
import MarketsHistoryStore from './store/markets-history.store';

export const MarketsHistoryServiceS = Symbol.for('MarketsHistoryServiceS');
@injectable(MarketsHistoryServiceS as unknown as string)
export default class MarketsHistoryService implements Stateable {
    @Inject(MarketsHistoryApiServiceS) private MarketsHistoryApiService!: MarketsHistoryApiService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(ClusterCompsetsServiceS) private clusterCompsetsService!: ClusterCompsetsService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(HotelsServiceS) private hotelsService!: HotelsService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(MarketsApiServiceS) private marketsApiService!: MarketsApiService;

    readonly marketsStoreState: MarketsStore = this.storeFacade.getState('MarketsStore');
    readonly storeState: MarketsHistoryStore = this.storeFacade.getState('MarketsHistoryStore');
    private hotelId: number | null = null;
    private compsetId: string | null = null;
    private documentDay: Day | null = null;
    private documentFilters: DocumentFiltersModel | null = null;
    private competitors: number[] | null = null;

    constructor() {
        this.storeState.loading.reset();
        this.storeFacade.watch(
            () => [
                this.marketSettings?.provider,
                this.documentFilters?.compsetId,
            ],
            () => {
                this.storeState.loading.reset();
            },
        );
    }

    async loadData(clusterMode: boolean = false): Promise<boolean> {
        if (!clusterMode) {
            await this.loadMarketsDocument();
        }

        if (!this.documentDay || !this.marketsDocument) {
            return true;
        }

        const documentId = clusterMode
            ? +(this.marketsDocument as MarketsCompsetMainModel).documentId
            : +(this.marketsDocument as MarketsDocumentModel).id;

        this.storeState.document = await this.MarketsHistoryApiService.getMarketsHistoryByDay(this.documentDay, documentId);

        this.calculateLastScanDate();
        this.mapHistoryToMarkets();

        return true;
    }

    async loadMarketsDocument(): Promise<boolean> {
        if (this.documentFilters?.compsetId && this.marketSettings) {
            this.marketsDocument = await this.marketsApiService.getMarketsDocument(this.documentFilters, this.marketSettings);
        }
        return true;
    }

    set marketsDocument(value: MarketsDocumentModel | MarketsCompsetMainModel | null) {
        this.storeState.marketsDocument = value;
    }

    get marketsDocument() {
        return this.storeState.marketsDocument;
    }

    set marketSettings(value: MarketSettingsModel | null) {
        this.storeState.marketSettings = value;
    }

    get marketSettings() {
        return this.storeState.marketSettings;
    }

    get document() {
        return this.storeState.document;
    }

    get marketHistoryHotels() {
        let hotelIds: number[] = [];

        if (this.competitors && this.hotelId) {
            hotelIds = [...this.competitors, ...[this.hotelId]];
        }

        return hotelIds.length ? hotelIds : null;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    get scanDate() {
        return this.marketsDocument ? `${moment(this.lastScanDate).format('DD/MM/YY')}` : '';
    }

    get lastScanDate() {
        return this.storeState.lastScanDate;
    }

    get dayIndex() {
        return this.storeState.dayIndex;
    }

    calculateLastScanDate() {
        if (!this.document || !this.documentDay) {
            return;
        }

        const { month, year } = this.documentFiltersService;
        const selectedDate = new Date(year, month, this.documentDay);
        const date = Object.keys(this.document!.trendData)
            .filter(key => {
                const [dateDay, dateMonth, dateYear] = key.split('-');
                const historyDate = new Date(Number(dateYear), Number(dateMonth) - 1, Number(dateDay));
                return historyDate <= selectedDate;
            }).reverse()[0];

        const [dateDay, dateMonth, dateYear] = date.split('-');
        this.storeState.lastScanDate = new Date(Number(dateYear), Number(dateMonth) - 1, Number(dateDay));
    }

    setMarketsData(data: MarketsDocumentModel | null, settings: MarketSettingsModel) {
        this.marketsDocument = data;
        this.marketSettings = settings;
        this.storeState.loading.reset();
    }

    marketsHistory(
        actualDay: Day | null,
        hotelId: number | null,
        compsetId: string | null = null,
        documentFilters: DocumentFiltersModel | null = null,
    ): { [hotelId: number]: MarketsDocumentItemModel }[] | null {
        this.hotelId = hotelId || this.userService.currentHotelId;
        this.compsetId = compsetId || null;
        this.competitors = compsetId
            ? this.clusterCompsetsService.getCompetitors(compsetId)
            : this.compsetsService.competitors;
        this.documentFilters = documentFilters;

        this.checkIsDocumentUpToDate(actualDay);

        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this, !!compsetId));

        if (!this.marketsDocument || !this.marketsDocument.checkinDates) {
            return null;
        }

        return this.documentFiltersService.days
            .map(day => this.getSuitableMarketByDay(day - 1) || {} as {[hotelId: number]: MarketsDocumentItemModel});
    }

    getTrendDate(day: number) {
        const { lastScanDate } = this;
        if (!lastScanDate) return null;

        const date = new Date(lastScanDate);
        date.setDate(date.getDate() - day + 1);

        return date
            .toISOString()
            .split('T')
            .shift()!
            .split('-')
            .reverse()
            .join('-');
    }

    getSuitableMarketByDay(day: number) {
        const filteredHotels = {} as {[hotelId: number]: MarketsDocumentItemModel};

        if (!this.marketHistoryHotels || !this.document || !this.document.trendData) {
            return filteredHotels;
        }

        if (!this.lastScanDate) return filteredHotels;

        const trendDate = this.getTrendDate(day)!;
        const allHotels = this.document.trendData[trendDate];

        if (!allHotels) {
            return filteredHotels;
        }

        Object
            .entries(allHotels)
            .forEach(([hotelId, marketItem]) => {
                if (this.marketHistoryHotels && this.marketHistoryHotels.includes(+hotelId)) {
                    filteredHotels[Number(hotelId)] = marketItem;
                }
            });

        return filteredHotels;
    }

    mapHistoryToMarkets() {
        if (!this.marketsDocument) {
            return;
        }

        this.marketsDocument = {
            ...this.marketsDocument,
            checkinDates: {},
        };

        if (this.marketsDocument.checkinDates && this.storeState.document && this.lastScanDate) {
            for (let i = 0; i < this.documentFiltersService.days.length; i += 1) {
                const dateInstance = new Date(this.lastScanDate.getFullYear(), this.lastScanDate.getMonth(), this.lastScanDate.getDate() - i);

                const day = moment(dateInstance).format('DD');
                const month = moment(dateInstance).format('MM');
                const year = moment(dateInstance).format('YYYY');

                if (this.storeState.document.trendData[`${day}-${month}-${year}`]) {
                    this.marketsDocument.checkinDates[Number(day)] = this.storeState.document.trendData[`${day}-${month}-${year}`] || {};
                }
            }
        }
    }

    getHotelColor(hotelId: number, compsetId?: string | null) {
        return this.hotelsService.getHotelsGraphColor(compsetId)[String(hotelId)] || '#000000';
    }

    getMarketsHistoryHotelData(hotelId: number) {
        const history = this.documentDay
            ? this.marketsHistory(this.documentDay, this.hotelId, this.compsetId, this.documentFilters)
            : null;

        return history
            ? history
                .map((day: {[hotelId: number]: MarketsDocumentItemModel} | null) => {
                    if (!day || !Object.keys(day).length) {
                        return null;
                    }

                    const marketItem = day[Number(hotelId)];

                    if (!marketItem) {
                        return null;
                    }

                    return day[Number(hotelId)];
                })
            : [];
    }

    getMarketHistoryHotelPositions(hotelId: number) {
        const daysCount = this.documentFiltersService.days.length;
        const positions = [] as (number | null)[];

        for (let dayIndex = 0; dayIndex < daysCount; dayIndex++) {
            const marketData = this.getSuitableMarketByDay(dayIndex - 1)[hotelId];

            if (marketData) {
                positions.push(marketData.position);
            } else {
                positions.push(null);
            }
        }

        return positions.reverse();
    }

    checkIsDocumentUpToDate(actualDay: Day | null) {
        const { finishDate, startDate } = this.storeState.loading;

        if (this.documentDay !== actualDay
            && ((finishDate === null && startDate === null) || (finishDate !== null && startDate !== null))) {
            this.documentDay = actualDay;
            this.storeState.loading.reset();
        }
    }

    async setTableDay(label?: string) {
        if (!this.marketsDocument || !this.marketsDocument.finishScanDate) {
            await this.marketsStoreState.loading.whenLoadingFinished();
        }

        if (!label) {
            this.storeState.dayIndex = 0;
            return;
        }

        const matches = label.match(/(\d+)/);
        const dayIndex = matches ? matches[0] : null;

        if (dayIndex !== null) {
            this.storeState.dayIndex = Number(dayIndex);
        }
    }

    get sortedDaysList() {
        if (!this.marketsDocument || !this.lastScanDate) {
            return [];
        }
        const scanDay = this.lastScanDate.getDate();
        const { month, year, days } = this.documentFiltersService;
        const maxDaysPrevMonth = new Date(year, month, 0).getDate();
        const currentMonthSegment = Array(scanDay).fill(null).map((_, i) => i + 1);
        const prevMonthSegment = days.length === scanDay
            ? []
            : Array(Math.abs(maxDaysPrevMonth - scanDay)).fill(null).map((_, i) => i + scanDay + 1);
        return [...prevMonthSegment, ...currentMonthSegment].reverse();
    }
}
