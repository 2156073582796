import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import use from '@/router/use';
import { container } from 'inversify-props';
import PaginationMiddleware, { PaginationMiddlewareS } from '@/router/middlewares/pagination.middleware';
import RatesHotelLevelRoutes from '../hotel/rates.routes';
import RatesClusterLevelRoutes from '../cluster/rates.routes';

export default function RatesChainLevelRoutes() {
    return {
        path: 'rates',
        component: () => import('@/modules/common/pages/transition.page.vue'),
        beforeEnter: use([container.get<FeaturesGuard>(FeaturesGuardS), container.get<PaginationMiddleware>(PaginationMiddlewareS)]),
        children: [
            // NOTE: Will be enabled when the chain level will enabled
            // {
            //     name: 'chain.rates',
            //     path: '',
            //     component: () => import('@/modules/chain/pages/rates.page.vue'),
            //     children: [
            //         ...CommonMenuRoutes('chain.rates'),
            //     ],
            // },
            // RatesClusterLevelRoutes(':group/:value/', 'chain.rates.cluster'),
            ...RatesClusterLevelRoutes('', 'chain.rates').children!,
            RatesHotelLevelRoutes(':hotelId', 'chain.rates.hotel'),
        ],
    };
}
