import { Module, VuexModule } from 'vuex-module-decorators';
import EventsSettingsModel from '@/modules/events/models/events-setting.model';
import LoadingModel from '@/modules/common/models/loading.model';
import EventsModel from '../models/events.model';
import EventCollection from '../models/event-collection.model';

@Module
export default class EventsStore extends VuexModule {
    previousMonthEvents: EventCollection = new EventCollection();
    currentMonthEvents: EventCollection = new EventCollection();
    nextMonthEvents: EventCollection = new EventCollection();
    previousMonthEventsByPOS: EventCollection = new EventCollection();
    currentMonthEventsByPOS: EventCollection = new EventCollection();
    nextMonthEventsByPOS: EventCollection = new EventCollection();

    loadingByPOS: LoadingModel = new LoadingModel();
    loading: LoadingModel = new LoadingModel();
    settings: EventsSettingsModel = new EventsSettingsModel();

    countries: string[] = [];
    countriesLoading: LoadingModel = new LoadingModel();

    eventMap: {
        [isoDate: string]: EventCollection;
    } = {};

    eventDictionary: {
        [eventId: string]: EventsModel;
    } = {};
}
