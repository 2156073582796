import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import use from '@/router/use';
import { container } from 'inversify-props';
import { RouteConfig } from 'vue-router';
import PaginationMiddleware, { PaginationMiddlewareS } from '@/router/middlewares/pagination.middleware';
import RankingClusterLevelRoutes from '../cluster/ranking.routes';
import RankingHotelLevelRoutes from '../hotel/ranking.routes';

export default function RankingChainLevelRoutes(): RouteConfig {
    return {
        path: 'ranking',
        component: () => import('@/modules/common/pages/transition.page.vue'),
        beforeEnter: use([container.get<FeaturesGuard>(FeaturesGuardS), container.get<PaginationMiddleware>(PaginationMiddlewareS)]),
        children: [
            // NOTE: Make chain ranking
            // {
            //     path: '',
            //     name: 'chain.ranking',
            //     component: () => import('@/modules/cluster/pages/ranking-cluster.page.vue'),
            //     children: [
            //         ...CommonMenuRoutes('chain.ranking'),
            //     ],
            // },

            ...RankingClusterLevelRoutes('', 'chain.ranking').children!,
            RankingHotelLevelRoutes(':hotelId', 'chain.ranking.hotel'),
        ],
    };
}
