/* eslint-disable camelcase */
import { Inject, injectable } from 'inversify-props';
import { union } from 'lodash';
import { Params } from '@/modules/common/services/api.service';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import CompsetModel from '@/modules/compsets/models/compset.model';
import ClusterHotelsMarketsModel from '@/modules/cluster/models/cluster-markets.model';
import ClusterHotelsRatesModel from '@/modules/cluster/models/cluster-rates.model';
import CompsetMainModel from '@/modules/cluster/models/compset-main.model';
import ClusterCompsetsService, { ClusterCompsetsServiceS } from '@/modules/cluster/cluster-compsets.service';
import ClusterApiService, { ClusterApiServiceS } from '@/modules/cluster/cluster-api.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import HotelsApiService, { HotelsApiServiceS } from '@/modules/hotels/hotels-api.service';
import HotelModel from '@/modules/hotels/models/hotel.model';
import RankingChainItemModel from '@/modules/cluster/models/ranking-cluster-item.model';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import ClusterStore from './store/cluster.store';
import ProvidersService, { ProvidersServiceS } from '../providers/providers.service';
import COMPSET_TYPE from '../compsets/constants/compset-type.constant';
import UserService, { UserServiceS } from '../user/user.service';
import MealTypesService, { MealTypesServiceS } from '../meal-types/meal-types.service';
import RoomTypesService, { RoomTypesServiceS } from '../room-types/room-types.service';
import HelperService, { HelperServiceS } from '../common/services/helper.service';
import ChainGroup from '../chain/interfaces/chain-group.enum';
import ChainService, { ChainServiceS } from '../chain/chain.service';
import PAGES from '../common/constants/pages.constant';
import type { ClusterHotelModel, ClusterDocument } from './interfaces';
import RatesCompsetMainModel from './models/rates-compset-main.model';
import CLUSTER_SORTING_VALUES from '../deep-analysis/constants/cluster-sorting-values.constant';

interface ClusterServicePublicInterface {
    /** Current loading state */
    readonly isLoading: boolean;

    /**
     * Method to get list of all hotels of the cluster for specific page.
     * @param mode page (rates/markets/etc).
     */
    loadData: (mode: PAGES) => Promise<boolean>;

    /**
     * Method to get document for specific hotelId and page.
     * @param mode page (rates/markets/etc).
     * @param hotelId fornovaId of the hotel to get document for.
     */
    loadDaysData: (mode: PAGES, hotelId: number) => Promise<boolean>;

    /**
     * Method to find specific hotel in hotels list by providing hotelId.
     * Shouldn't be used to get main document (Use getMainCompsetData instead).
     * @param hotelId id of a hotel is looked for.
     * @returns hotel's model or null if no hotels loaded yet or no hotel with provided id.
     */
    getHotelData: <T extends ClusterHotelModel>(hotelId: number) => T | null;

    /**
     * Method to find specific hotel's main document by providing hotelId.
     * @param hotelId id of a hotel is looked for.
     * @returns main document's model or null if no hotels loaded yet or hotel with provided id doesn't have a document.
     */
    getMainCompsetData: <T extends ClusterDocument>(hotelId: number) => T | null
}

export const ClusterServiceS = Symbol.for('ClusterServiceS');
@injectable(ClusterServiceS as unknown as string)
export default class ClusterService implements Stateable {
    @Inject(ChainServiceS) private chainService!: ChainService;
    @Inject(ClusterApiServiceS) private clusterApiService!: ClusterApiService;
    @Inject(ClusterCompsetsServiceS) private clusterCompsetsService!: ClusterCompsetsService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(HotelsApiServiceS) private hotelsApiService!: HotelsApiService;
    @Inject(ProvidersServiceS) private providerService!: ProvidersService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(MealTypesServiceS) private mealTypeService!: MealTypesService;
    @Inject(RoomTypesServiceS) private roomTypeService!: RoomTypesService;
    @Inject(HelperServiceS) private helperService!: HelperService;

    constructor() {
        this.storeFacade.watch(
            () => [this.clusterHotelSearchQuery, this.limit, this.skip],
            () => this.resetLoading(),
        );

        this.storeFacade.watch(
            () => [this.storeState.provider, this.storeState.promotionsSettings.provider, this.storeState.daProvider],
            () => { this.skip = 0; },
        );
    }

    readonly storeState: ClusterStore = this.storeFacade.getState('ClusterStore');

    get currentPromotionsProvider(): string | null {
        const { promotionsSettings } = this.storeState;

        return promotionsSettings.provider;
    }

    get currentRatesProvider(): string {
        const { provider } = this.storeState;
        const { chainRatesProviders } = this.providerService;

        if (!provider || !chainRatesProviders.includes(provider)) {
            const isHaveBooking = chainRatesProviders.includes('booking');

            if (isHaveBooking) {
                this.saveCurrentProvider('booking');
            } else {
                this.saveCurrentProvider(chainRatesProviders[0] || null);
            }
        }

        return this.storeState.provider!;
    }

    get currentMarketsProvider(): string {
        const { provider } = this.storeState;
        const { chainMarketsProviders } = this.providerService;

        if (!provider || !chainMarketsProviders.includes(provider)) {
            const isHaveBooking = chainMarketsProviders.includes('booking');

            if (isHaveBooking) {
                this.saveCurrentProvider('booking');
            } else {
                this.saveCurrentProvider(chainMarketsProviders[0]);
            }
        }

        return this.storeState.provider!;
    }

    get ratesSettings() {
        return this.storeState.settings;
    }

    get hotels() {
        return this.storeState.clusterHotels;
    }

    get pagesCount() {
        return this.storeState.totalCount;
    }

    set hotels(value) {
        this.storeState.clusterHotels = value;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    get isChainPairSpecified() {
        return !!this.chainService.chainPair;
    }

    get documentHash() {
        return this.storeState.documentHash;
    }

    set documentHash(value: string | null) {
        this.storeState.documentHash = value + [
            this.documentFiltersService.year,
            this.documentFiltersService.month,
            this.documentFiltersService.los,
        ].join('-');
    }

    get isAllDocumentsReady() {
        const hotels = this.hotels || [];
        return !hotels.some(hotel => !hotel.documentLoaded);
    }

    get poses(): string[] {
        this.helperService.dynamicLoading(this.storeState.pos.loading, this.loadClusterPosList.bind(this));
        return this.storeState.pos.list;
    }

    get clusterHotelSearchQuery() {
        return this.storeState.searchQuery;
    }

    set clusterHotelSearchQuery(q: string | null) {
        this.storeState.searchQuery = q;
    }

    async loadClusterPosList() {
        await this.clusterApiService.getClusterPos();
        return true;
    }

    async getPosList() {
        const res = await this.clusterApiService.getClusterPos();
        this.storeState.pos.list = res;
        return res;
    }

    get limit() {
        return this.storeState.hotelsLimitPerPage;
    }

    set limit(n: number) {
        this.storeState.hotelsLimitPerPage = n;
    }

    get skip() {
        return this.storeState.hotelsSkip;
    }

    set skip(n: number) {
        this.storeState.hotelsSkip = n;
    }

    resetChainItems() {
        this.storeState.rankingChainItems = {} as {
            [hotelId: number]: {
                [compsetId: string]: RankingChainItemModel
            }
        };
    }

    resetLoading() {
        this.storeState.loading.reset();
    }

    getActualParamsForDocument(mode: PAGES) {
        const params = {
            year: this.documentFiltersService.year,
            month: this.documentFiltersService.month + 1,
            provider_name: 'booking',
            skip: this.skip,
            limit: this.limit,
        } as Params;

        switch (mode) {
            case PAGES.RATES:
            case PAGES.DILITE:
                params.provider_name = mode === PAGES.RATES
                    ? this.currentRatesProvider
                    : 'all';

                this.assignParamsForRates(params);
                break;
            case PAGES.MARKETS:
                params.provider_name = this.currentMarketsProvider;
                params['sort[score.mv]'] = this.storeState.marketsSorting;
                break;

            case PAGES.PROMOTION_DETECTION:
                params.provider_name = this.currentPromotionsProvider;
                params.los = this.documentFiltersService.settings.los;
                params.program = this.storeState.promotionsSettings.programView;
                break;

            case PAGES.DEEP_ANALYSIS:
                params.sort = (() => {
                    const { showBy: _showBy, compareTo, view } = this.storeState.deepAnalysisSettings;
                    const { key: _key, value } = this.storeState.deepAnalysisSorting;
                    const showBy = _showBy
                        .replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
                        .replace('book', 'last').replace('stay', 'next');

                    const valueType = CLUSTER_SORTING_VALUES[view][compareTo];
                    const defaultSortingKey = 'hotel_name';
                    const key = _key
                        ? `details.${this.storeState.daProvider}.${showBy}.${_key}.${valueType}`
                        : defaultSortingKey;

                    return { [key]: value };
                })();
                break;

            default:
                return params;
        }

        return params;
    }

    /**
     * **THIS METHOD MUTATES INPUT ARGUMENT**
     * Injects rates related parameters into specified `params` object
     */
    private assignParamsForRates(params: any) {
        const { ratesSorting, settings, hotelNameSorting } = this.storeState;
        const { los } = this.documentFiltersService.settings;
        const {
            priceType: price,
            roomTypeId,
            mealTypeId,
            numberOfGuests: number_of_guest,
        } = settings;

        const meal_type = this.mealTypeService.getMealType(mealTypeId);
        const room_type = this.roomTypeService.getRoomType(roomTypeId);

        const filters = {
            price,
            meal_type: meal_type && meal_type.name,
            room_type: room_type && room_type.name,
        };

        if (this.chainService.chainPair) {
            this.assignChainParamsInto(params);
        }

        // NOTE: Makes query params in `format[key]` format
        const filterParams = Object.fromEntries(
            Object
                .entries(filters)
                .filter(([, v]) => !!v)
                .map(([k, v]) => [`filters[${k}]`, v]),
        );

        Object.assign(params, {
            los,
            number_of_guest,
            ...filterParams,
        });

        if (ratesSorting) {
            Object.assign(params, {
                'sort[score.rate]': ratesSorting,
            });
        }

        if (hotelNameSorting) {
            Object.assign(params, {
                'sort[hotel_name]': hotelNameSorting,
            });
        }
    }

    /**
     * **THIS METHOD MUTATES INPUT ARGUMENT**
     * Injects chain parameters into specified `params` object
     */
    private assignChainParamsInto(params: any) {
        const { chainPair } = this.chainService;
        const { city, country } = this.chainService.settings;
        const { region, brand } = this.chainService.settings;

        const chainParams = {
            region: chainPair!.group === ChainGroup.REGION
                ? chainPair!.value
                : region,
            country: chainPair!.group === ChainGroup.COUNTRY
                ? chainPair!.value
                : country,
            brand,
            city,
            grouped_by: chainPair!.group,
            grouped_by_value: chainPair!.value,
        };

        Object
            .keys(chainParams)
            .forEach(key => {
                const typedKey = key as keyof typeof chainParams;
                if (!chainParams[typedKey]) {
                    delete chainParams[typedKey];
                }
            });

        Object.assign(params, chainParams);
    }

    getProviders(mode: PAGES) {
        switch (mode) {
            case PAGES.MARKETS:
            case PAGES.RANKING:
                return this.providerService.chainMarketsProviders;

            default:
                return this.providerService.chainRatesProviders;
        }
    }

    async loadData(mode: PAGES) {
        const { chainPair } = this.chainService;
        const { numberOfGuests, priceType } = this.storeState.settings;
        const { mealTypeId, roomTypeId } = this.storeState.settings;
        const { promotionsSettings, promotionsPrograms } = this.storeState;

        const params = this.getActualParamsForDocument(mode);

        if (!params) {
            return false;
        }

        if (this.clusterHotelSearchQuery !== null && this.clusterHotelSearchQuery !== '') {
            params.hotel_name = this.clusterHotelSearchQuery;
        }

        let data = null;

        switch (mode) {
            case PAGES.RATES:
            case PAGES.DILITE:
                if (mode === PAGES.RATES && !this.currentRatesProvider) {
                    return false;
                }

                if (mealTypeId === null
                    || numberOfGuests === null
                    || priceType === null
                    || roomTypeId === null
                    || this.documentFiltersService.los === null
                ) {
                    return false;
                }

                data = await this.clusterApiService.getRatesClusterData(params, chainPair);
                break;

            case PAGES.MARKETS:
                if (!this.currentMarketsProvider) {
                    return false;
                }
                data = await this.clusterApiService.getMarketsClusterData(params, chainPair);
                break;

            case PAGES.RANKING:
                data = await this.clusterApiService.getGuestReviewsData(params);
                break;

            case PAGES.PROMOTION_DETECTION:
                if (!this.currentPromotionsProvider) {
                    [promotionsSettings.provider] = this.providerService.promotionsProviders;
                    return false;
                }

                if (!promotionsSettings.programView) {
                    if (!promotionsPrograms.list[promotionsSettings.provider!]) {
                        return false;
                    }

                    [promotionsSettings.programView] = promotionsPrograms.list[promotionsSettings.provider!];
                    return false;
                }

                data = await this.clusterApiService.getPromotionHotels(params);
                break;
            case PAGES.DEEP_ANALYSIS:
                data = await this.clusterApiService.getDeepCompsetData(params);
                break;
            default: break;
        }

        // If user switch page after request sent but before responce returns, data should not be updated
        if (!(new RegExp(mode)).test(window.location.pathname)) {
            return false;
        }

        if (!data) return false;

        this.storeState.totalCount = data.total;
        this.storeState.clusterHotels = data.data;

        const hotelMap = {} as Record<number, ClusterHotelModel>;
        const hotelNamesMap = {} as Record<number, string>;
        this.storeState.clusterHotels.forEach(hotel => {
            hotelMap[hotel.hotelId] = hotel;
            hotelNamesMap[hotel.hotelId] = hotel.hotelName;
            Object.assign(hotelNamesMap, hotel.hotelNames);
        });
        this.storeState.hotelsMap = hotelMap;
        this.storeState.hotelNamesMap = hotelNamesMap;

        return true;
    }

    async loadDaysData(mode: PAGES, hotelId: number) {
        let hotelData = this.getHotelData(hotelId);

        if (!hotelData) return;

        if (hotelData.compsetMain) {
            return;
        }

        this.documentHash = mode;
        const oldHash = this.documentHash;

        const params = this.getActualParamsForDocument(mode);

        let data: ClusterHotelModel | null = null;
        switch (mode) {
            case PAGES.RATES:
            case PAGES.DILITE:
                data = await this.clusterApiService.getRatesCompsetData(hotelId, params);
                break;
            case PAGES.RANKING:
                data = await this.clusterApiService.getRankingCompsetData(hotelId, params);
                break;
            case PAGES.MARKETS:
                data = await this.clusterApiService.getMarketsCompsetData(hotelId, params);
                break;
            case PAGES.DEEP_ANALYSIS:
                data = await this.clusterApiService.getDeepCompsetDocument(hotelId);
                break;
            case PAGES.PROMOTION_DETECTION:
                data = await this.clusterApiService.getPromotionsDocument(hotelId, params);
                break;
            default:
                break;
        }

        const newHash = this.documentHash;

        if (oldHash !== newHash) {
            return;
        }

        hotelData = this.getHotelData(hotelId);

        if (!hotelData || !data) {
            if (hotelData) {
                hotelData.documentLoaded = true;
            }

            return;
        }

        hotelData.compsetMain = data.compsetMain || null;
        hotelData.hotelNames = data.hotelNames;
        hotelData.documentLoaded = true;
    }

    async getClusterHotels(hotelId: number) {
        const mainCompsetData = this.getMainCompsetData<RatesCompsetMainModel>(hotelId);
        const competitorsIds = mainCompsetData ? this.clusterCompsetsService.getCompetitors(mainCompsetData.id) : null;
        this.storeState.hotels = competitorsIds;
        if (competitorsIds) {
            const data = await this.hotelsApiService.getHotelsById(competitorsIds);
            this.storeState.hotels = data ? data.map((item: HotelModel) => item.name) : [];
        }
    }

    getCompset(compsetId: string, hotelId: number) {
        if (!this.hotels) {
            return null;
        }
        const cluster = this.getHotelData(hotelId);
        if (!cluster) {
            return null;
        }
        const compset = cluster.compsets.find(el => el.id === compsetId);
        if (!compset) {
            return null;
        }
        return compset;
    }

    /**
     * Returns cluster hotel's main document by hotelId
     * @param hotelId
     * @returns hotel data
     */
    getMainCompsetData<T extends ClusterDocument>(hotelId: number): T | null {
        if (!this.hotels) {
            return null;
        }
        const hotel = this.hotels.find(el => el.hotelId === hotelId);
        if (hotel && hotel.compsetMain) {
            return hotel.compsetMain as T;
        }
        return null;
    }

    /**
     * Returns cluster hotel by hotelId
     * @param hotelId
     * @returns hotel data
     */
    getHotelData<T extends ClusterHotelModel>(hotelId: number) {
        if (!this.hotels) return null;
        return this.hotels.find(hotel => Number(hotel.hotelId) === Number(hotelId)) as T || null;
    }

    /**
     * Return main compset (according document's id in main compset's document).
     * Return compset only for hotel models which support compsets.
     * @param hotelData hotel model or hotel id.
     * @returns main compset.
     */
    getMainCompset(hotel: ClusterHotelModel | number) {
        let hotelData = null;

        if (typeof hotel === 'number') {
            hotelData = this.getHotelData(hotel);
        } else {
            hotelData = hotel;
        }

        if (!hotelData) {
            return null;
        }

        const mainId = hotelData.compsetMain?.id || null;

        if (mainId) {
            return hotelData.compsets.find(c => c.id === mainId)!;
        }

        return hotelData.compsets.find(c => c.type === COMPSET_TYPE.MEDIAN)
            || hotelData.compsets[0];
    }

    getHotelName(hotelId: number) {
        return this.storeState.hotelNamesMap[hotelId];
    }

    saveCurrentProvider(provider: string | null) {
        this.storeState.provider = provider;
        this.storeState.settings.provider = provider;
    }

    switchCurrentHotel(hotelId: number) {
        this.storeState.currentHotelId = hotelId;
    }

    getHotelBy({ compsetId, hotelId }: { compsetId?: string, hotelId?: number }) {
        if ((!compsetId && !hotelId) || !this.hotels) {
            return null;
        }

        return this.hotels.find(hotel => {
            if (compsetId) {
                return !!hotel.compsets.find(compset => compset.id === compsetId);
            }

            return hotel.hotelId === hotelId;
        }) || null;
    }

    getHotelIdByCompset(compsetId: string) {
        const { isClusterUser, currentHotelId } = this.userService;

        if (!isClusterUser) {
            return currentHotelId;
        }

        const hotelChain = this.getHotelBy({ compsetId });
        return hotelChain && hotelChain.hotelId;
    }
}
