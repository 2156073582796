import ASSESSMENT_TYPES from './assessments-types.constant';
import { COLOR_NAMES } from './color-names';

export const ASSESSMENT_COLORS = {
    [ASSESSMENT_TYPES.GOOD]: COLOR_NAMES.GREEN,
    [ASSESSMENT_TYPES.NORMAL]: COLOR_NAMES.YELLOW,
    [ASSESSMENT_TYPES.BAD]: COLOR_NAMES.RED,
    [ASSESSMENT_TYPES.SOLD_OUT]: COLOR_NAMES.GRAY,
    [ASSESSMENT_TYPES.NO_DATA]: COLOR_NAMES.WHITE,
    [ASSESSMENT_TYPES.OUT_OF_RANGE]: COLOR_NAMES.WHITE,
    [ASSESSMENT_TYPES.NA]: COLOR_NAMES.RED,
} as Record<ASSESSMENT_TYPES, COLOR_NAMES>;
