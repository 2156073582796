import { Inject, injectable } from 'inversify-props';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import CompsetModel from '@/modules/compsets/models/compset.model';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import { ExtendedCompetitorInterface } from '@/modules/compsets/interfaces';
import CompsetsStore from '@/modules/compsets/store/compsets.store';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import HotelCatalogService, { HotelCatalogServiceS } from '../hotels/modules/hotel-catalog/hotel-catalog.service';

declare global {
    interface Window {
        initMap: Function;
    }
}

declare global {
    interface Window {
        initMap: Function;
    }
}

export const CompsetSettingsServiceS = Symbol.for('CompsetSettingsServiceS');
@injectable(CompsetSettingsServiceS as unknown as string)
export default class CompsetSettingsService implements Stateable {
    @Inject(UserServiceS)
    private userService!: UserService;

    @Inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    @Inject(StoreFacadeS)
    private storeFacade!: StoreFacade;

    @Inject(HotelCatalogServiceS)
    private hotelCatalogService!: HotelCatalogService;

    @Inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    readonly storeState: CompsetsStore = this.storeFacade.getState('CompsetsStore');

    get settingsActiveCompsetId() {
        if (!this.storeState.settingsActiveCompsetId) {
            if (this.compsetsService.compsets && this.compsetsService.compsets.length) {
                this.settingsActiveCompsetId = this.compsetsService.compsets[0].id;
            }
        }
        return this.storeState.settingsActiveCompsetId;
    }

    set settingsActiveCompsetId(value: string|null) {
        this.storeState.settingsActiveCompsetId = value;
    }

    get competitors(): ExtendedCompetitorInterface[] {
        const { compsets } = this.compsetsService;
        const { localCompsets } = this.storeState;

        let currentCompetitors: ExtendedCompetitorInterface[] = [];
        let oldCompetitors: ExtendedCompetitorInterface[] = [];
        let catalogHotels: ExtendedCompetitorInterface[] = [];
        let extendedCompetitors: ExtendedCompetitorInterface[] = [];
        let allCompetitorsHotels: ExtendedCompetitorInterface[] = [];

        if (!localCompsets && !compsets && !this.hotelCatalogService.data) {
            return extendedCompetitors;
        }

        const compset = this.settingsActiveCompsetId
            // @ts-ignore
            ? (compsets && compsets.find((c: CompsetModel) => c.id === this.settingsActiveCompsetId))
            : null;

        const updatedCompset = this.settingsActiveCompsetId
            // @ts-ignore
            ? (localCompsets && localCompsets.find((c: CompsetModel) => c.id === this.settingsActiveCompsetId))
            : null;

        if (updatedCompset) {
            currentCompetitors = updatedCompset.competitors.map(competitorId => {
                const location = this.hotelsService.getHotelGeoLocation(competitorId);
                return {
                    id: competitorId,
                    name: this.hotelsService.getHotelName(competitorId),
                    rating: null,
                    isMyHotel: false,
                    distance: this.calculateDistance(location),
                    position: null,
                    address: null,
                    isVisible: true,
                    geoLocation: location,
                };
            });

            extendedCompetitors = extendedCompetitors.concat(currentCompetitors);
        }

        if (compset) {
            oldCompetitors = compset.competitors.map(competitorId => {
                if (extendedCompetitors.find(competitor => competitor.id === competitorId)) {
                    return null;
                }

                const location = this.hotelsService.getHotelGeoLocation(competitorId);

                return {
                    id: competitorId,
                    name: this.hotelsService.getHotelName(competitorId),
                    rating: null,
                    isMyHotel: false,
                    distance: this.calculateDistance(location),
                    position: null,
                    address: null,
                    isVisible: false,
                    geoLocation: location,
                };
            }).filter(item => item) as ExtendedCompetitorInterface[];

            extendedCompetitors = extendedCompetitors.concat(oldCompetitors);
        }

        if (this.hotelCatalogService.data) {
            catalogHotels = this.hotelCatalogService.data.map(hotel => {
                if (extendedCompetitors.find(competitor => competitor.id === hotel.id)) {
                    return null;
                }

                return {
                    id: hotel.id,
                    name: hotel.hotelName,
                    rating: null,
                    isMyHotel: false,
                    distance: this.calculateDistance(hotel.geoLocation),
                    position: null,
                    address: null,
                    isVisible: false,
                    geoLocation: hotel.geoLocation,
                };
            }).filter(item => item) as ExtendedCompetitorInterface[];

            extendedCompetitors = extendedCompetitors.concat(catalogHotels);
        }

        if (localCompsets) {
            localCompsets.forEach(localCompset => {
                if (localCompset.id === this.settingsActiveCompsetId) {
                    return null;
                }
                allCompetitorsHotels = localCompset.competitors.map(competitorId => {
                    if (extendedCompetitors.find(competitor => competitor.id === competitorId)) {
                        return null;
                    }
                    const location = this.hotelsService.getHotelGeoLocation(competitorId);
                    return {
                        id: competitorId,
                        name: this.hotelsService.getHotelName(competitorId),
                        rating: null,
                        isMyHotel: false,
                        distance: this.calculateDistance(location),
                        position: null,
                        address: null,
                        isVisible: false,
                        geoLocation: location,
                    };
                }).filter(item => item) as ExtendedCompetitorInterface[];
                extendedCompetitors = extendedCompetitors.concat(allCompetitorsHotels);
                return extendedCompetitors;
            });
        }
        this.storeState.settingsExtendedCompetitors = extendedCompetitors;
        return extendedCompetitors;
    }

    get myHotelOnMap() {
        const myHotelId = this.userService.currentHotelId;
        const hotels = this.hotelsService.allHotels;

        const result = hotels.filter(item => item.id === myHotelId)
            .map(item => item) as ExtendedCompetitorInterface[];

        if (result.length > 0) {
            result[0].isMyHotel = true;
            return result[0];
        }

        return null;
    }

    calculateDistance(from: google.maps.LatLngLiteral | undefined) {
        const myHotel = this.myHotelOnMap;
        if (window.google && myHotel?.geoLocation && from) {
            const latLngA = new google.maps.LatLng(from);
            const latLngB = new google.maps.LatLng(myHotel.geoLocation);
            const distance = google.maps.geometry.spherical.computeDistanceBetween(latLngA, latLngB);
            return Math.round(distance / 1000);
        }
        return null;
    }

    changeVisibility(competitorId: string) {
        const { settingsExtendedCompetitors, localCompsets } = this.storeState;

        if (settingsExtendedCompetitors) {
            this.storeState.settingsExtendedCompetitors = settingsExtendedCompetitors.map((competitor: ExtendedCompetitorInterface) => {
                if (Number(competitor.id) === Number(competitorId)) {
                    const updatedCompetitor = {
                        ...competitor,
                        isVisible: !competitor.isVisible,
                    };

                    if (this.settingsActiveCompsetId && localCompsets) {
                        // @ts-ignore
                        const compsetToUpdateIndex = localCompsets.findIndex(compset => compset.id === this.settingsActiveCompsetId);
                        if (compsetToUpdateIndex !== -1 && localCompsets[compsetToUpdateIndex]) {
                            if (updatedCompetitor.isVisible) {
                                this.storeState.localCompsets![compsetToUpdateIndex].competitors = Array.from(
                                    new Set(localCompsets[compsetToUpdateIndex].competitors.concat([updatedCompetitor.id])),
                                );
                                this.hotelsService.addLocalHotel(
                                    {
                                        id: updatedCompetitor.id,
                                        name: updatedCompetitor.name,
                                        geoLocation: updatedCompetitor.geoLocation,
                                    },
                                );
                            } else {
                                this.storeState.localCompsets![compsetToUpdateIndex].competitors = localCompsets[compsetToUpdateIndex].competitors
                                    .filter(id => id !== updatedCompetitor.id);
                            }
                        }
                    }

                    return updatedCompetitor;
                }

                return competitor;
            });
        }
    }

    reorderLocalCompetitors(competitorIds: [number, number]) {
        const { settingsExtendedCompetitors, localCompsets } = this.storeState;

        if (!settingsExtendedCompetitors || !localCompsets) {
            return;
        }

        const compsetToUpdateIndex = localCompsets.findIndex(compset => compset.id === this.settingsActiveCompsetId);
        const activeLocalCompset = localCompsets[compsetToUpdateIndex];

        if (compsetToUpdateIndex === -1 || !activeLocalCompset) {
            return;
        }

        const indexesToReorder = (() => {
            const indexes = [];
            for (let i = 0; i < settingsExtendedCompetitors.length; i += 1) {
                const { id } = settingsExtendedCompetitors[i];

                if (id === competitorIds[0] || id === competitorIds[1]) {
                    indexes.push(i);

                    if (indexes.length > 1) {
                        break;
                    }
                }
            }
            return indexes;
        })();

        if (indexesToReorder.length < 2) {
            return;
        }

        const [i1, i2] = indexesToReorder;
        [settingsExtendedCompetitors[i1], settingsExtendedCompetitors[i2]] = [settingsExtendedCompetitors[i2], settingsExtendedCompetitors[i1]];
        [
            activeLocalCompset.competitors[i1],
            activeLocalCompset.competitors[i2],
        ] = [
            activeLocalCompset.competitors[i2],
            activeLocalCompset.competitors[i1],
        ];

        this.storeState.settingsExtendedCompetitors = settingsExtendedCompetitors;
        this.storeState.localCompsets![compsetToUpdateIndex].competitors = activeLocalCompset.competitors;
        this.storeState.localCompsets = [
            ...this.storeState.localCompsets!,
        ];
    }

    updateCompset(compsetId: string) {
        const { compsets } = this.compsetsService;

        if (!compsets) {
            return;
        }

        const newActiveCompset = compsets.find(compset => compset.id === compsetId);

        if (newActiveCompset) {
            this.storeState.settingsActiveCompsetId = newActiveCompset.id;
        }
    }
}
