import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import type Month from '@/modules/common/types/month.type';
import type Year from '@/modules/common/types/year.type';
import EventsDTO, { EventsDTOUpdate } from '@/modules/events/dto/events.dto';
import EventsModel, { HolidayEventModel, SuggestedEventModel } from '@/modules/events/models/events.model';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';

export const EventsApiServiceS = Symbol.for('EventsApiServiceS');
@injectable(EventsApiServiceS as unknown as string)
export default class EventsApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;

    async getChainEvents(month: Month, year: Year, chainId: string, chainType: string): Promise<EventsModel[] | null> {
        const starts = new Date(Date.UTC(year, month, 1, 0, 0, 0)).toISOString().split('T')[0];
        const ends = new Date(Date.UTC(year, month + 1, 0, 1, 0, 0, 0)).toISOString().split('T')[0];

        const { data: { data } } = await this.apiService.post('events/get-events', {
            filters: {
                starts,
                ends,
                entity_type: chainType,
                entity_id: chainId,
            },
            limit: 0,
            skip: 0,
        });

        if (!data) {
            return null;
        }

        if (!data.length) {
            return null;
        }

        return plainToClass(EventsModel, <EventsModel[]> data, { excludeExtraneousValues: true });
    }

    async getMyEvents(fornovaId: number, month: Month, year: Year): Promise<EventsModel[] | null> {
        const starts = new Date(Date.UTC(year, month, 1, 0, 0, 0)).toISOString().split('T')[0];
        const ends = new Date(Date.UTC(year, month + 1, 0, 1, 0, 0, 0)).toISOString().split('T')[0];

        const { data: { data } } = await this.apiService.post('events/get-events', {
            filters: {
                starts,
                ends,
                entity_type: 'hotel',
                entity_id: +fornovaId,
            },
            limit: 0,
            skip: 0,
        });

        if (!data) {
            return null;
        }
        return plainToClass(EventsModel, <EventsDTO[]> data, { excludeExtraneousValues: true });
    }

    async getSuggestedEvents(marketId: number, month: Month, year: Year): Promise<EventsModel[] | null> {
        const starts = new Date(Date.UTC(year, month, 1, 0, 0, 0)).toISOString().split('T')[0];
        const ends = new Date(Date.UTC(year, month + 1, 0, 1, 0, 0, 0)).toISOString().split('T')[0];

        const { data: { data } } = await this.apiService.post('events/get-events', {
            filters: {
                starts,
                ends,
                entity_type: 'market',
                entity_id: marketId,
            },
            limit: 100,
            skip: 0,
        });

        if (!data) {
            return null;
        }

        return plainToClass(SuggestedEventModel, <SuggestedEventModel[]> data, { excludeExtraneousValues: true });
    }

    async getHolidaysEvents(month: Month, year: Year, countryCodes?: string[]): Promise<EventsModel[] | null> {
        const codes = countryCodes ? countryCodes.join(',') : undefined;
        const { data } = await this.apiService.get('holiday', { month, year, country_code: codes });

        if (!data) {
            return null;
        }
        // TODO: Add month filter when it will be enabled on server
        return plainToClass(HolidayEventModel, <HolidayEventModel[]> data, { excludeExtraneousValues: true });
    }

    async createEvent(event: EventsModel): Promise<EventsModel | null> {
        const eventDto = plainToClass(EventsDTO, <EventsModel> event, { excludeExtraneousValues: true });

        const { data: newEventId } = await this.apiService.post('events', eventDto);

        if (!newEventId) {
            return null;
        }

        // eslint-disable-next-line no-param-reassign
        event.id = newEventId;

        return event;
    }

    async updateEvent(event: EventsModel): Promise<EventsModel | null> {
        const { id } = event;
        const eventDto = plainToClass(EventsDTOUpdate, <EventsModel> event, { excludeExtraneousValues: true, groups: ['update'] });

        const { data } = await this.apiService.put(`events/${id}`, eventDto);

        if (!data) {
            return null;
        }

        const updatedEvent = plainToClass(EventsModel, <EventsModel> data, { excludeExtraneousValues: true });
        updatedEvent.group = event.group;

        return event;
    }

    async removeLocalEvent(eventId: string): Promise<void> {
        await this.apiService.delete(`events/${eventId}`);
    }

    async approveEvents(eventId: string) {
        const { data } = await this.apiService.put(`event/${eventId}`);
        if (!data) return null;

        return plainToClass(EventsModel, data, { excludeExtraneousValues: true });
    }

    async ignoreEvents(eventIds: string[]): Promise<void> {
        await this.apiService.post('event/ignore', { eventIds });
    }

    /**
     * Get list of all countries from BE, last item in countryNames is name which is used in holiday events.
     */
    async loadAllCountries() {
        const { data }: { data: { countryNames: string[] }[] } = await this.apiService.get('event/ev-hol-countries');
        return data.map(country => country.countryNames[country.countryNames.length - 1]).sort();
    }
}
