/**
 * Color constants which are used as color modificators
 * for tooltip/calendar/tables. Can work in pair with color assessments
 */
export enum COLOR_NAMES {
    GREEN = 'green',
    DARK_GREEN = 'dark-green',
    RED = 'red',
    YELLOW = 'yellow',
    GRAY = 'gray',
    WHITE = 'white',
}
