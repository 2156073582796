import { Inject, injectable } from 'inversify-props';
import moment from 'moment';

import type Day from '@/modules/common/types/day.type';
import Stateable from '@/modules/common/interfaces/stateable.interface';

import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import RatesPriceHistoryCommonService, { RatesPriceHistoryCommonServiceS }
    from '@/modules/common/modules/rates-price-history/rates-price-history-common.service';

import RatesDocumentItemAllModel from '@/modules/rates/models/rates-document-item-all.model';
import RatesPriceHistoryStore from '@/modules/common/modules/rates-price-history/store/rates-price-history.store';
import RatesPriceHistoryFilterAllModel from '@/modules/common/modules/rates-price-history/models/rates-price-history-filter-all.model';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import { PRICE_SHOWN } from '@/modules/rates/constants';

export const RatesPriceHistoryAllServiceS = Symbol.for('RatesPriceHistoryAllServiceS');
@injectable(RatesPriceHistoryAllServiceS as unknown as string)
export default class RatesPriceHistoryAllService implements Stateable {
    @Inject(StoreFacadeS)
    private storeFacade!: StoreFacade;

    @Inject(RatesPriceHistoryCommonServiceS)
    private ratesPriceHistoryCommonService!: RatesPriceHistoryCommonService;

    @Inject(RatesCommonServiceS)
    private ratesCommonService!: RatesCommonService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    readonly storeState: RatesPriceHistoryStore = this.storeFacade.getState('RatesPriceHistoryStore');

    private addAverageToHistory(history: { [provider: string]: RatesDocumentItemAllModel }[]) {
        history.map(item => {
            const historyItem = item;
            const [netPrice, totalPrice, shownPrice] = [PRICE_SHOWN.NET, PRICE_SHOWN.TOTAL, PRICE_SHOWN.SHOWN]
                .map(priceShown => {
                    let numberOfPrices = 0;
                    const providers = Object.keys(historyItem).filter(provider => provider !== 'average');
                    const price = providers.reduce((totalPrice, provider) => {
                        const price = this.ratesCommonService.switchPrice(historyItem[provider].room, priceShown);
                        const isValidPrice = price && price !== -1;

                        if (!isValidPrice) {
                            return totalPrice;
                        }

                        numberOfPrices += 1;
                        return totalPrice + price!;
                    }, 0);

                    if (numberOfPrices === 0) {
                        return null;
                    }

                    return price / numberOfPrices;
                });

            historyItem.average = {
                room: {
                    price: {
                        netPrice,
                        totalPrice,
                        shownPrice,
                    },
                },
                link: '',
            };
            return historyItem;
        });

        return history;
    }

    get lastScanDate() {
        return this.storeState.lastScanDate;
    }

    get providers() {
        const doc = this.storeState.documents.main as RatesPriceHistoryFilterAllModel;

        if (!doc || !doc.trendData) {
            return [];
        }

        const providers = Object
            .entries(doc.trendData!)
            .map(([, el]) => (el ? Object.keys(el) : []))
            .flat();

        const filteredProviders = [...new Set(providers)]
            .filter(item => item !== 'day_statistics');

        return filteredProviders.length
            ? filteredProviders
            : null;
    }

    getSuitableProviderByDay(dayParam: number) {
        const filteredProviders = {} as {[provider: string] : RatesDocumentItemAllModel};
        const doc = this.storeState.documents.main as RatesPriceHistoryFilterAllModel;

        if (!doc) {
            return filteredProviders;
        }

        if (!this.lastScanDate) {
            return filteredProviders;
        }

        const dateInstance = new Date(this.lastScanDate.getFullYear(), this.lastScanDate.getMonth(), this.lastScanDate.getDate() - dayParam);

        const day = moment(dateInstance).format('DD');
        const month = moment(dateInstance).format('MM');
        const year = moment(dateInstance).format('YYYY');
        const data = doc.trendData[`${day}-${month}-${year}`];

        if (!data) {
            return filteredProviders;
        }

        return data;
    }

    getPriceHistoryProvidersData(provider: string, priceShown?: PRICE_SHOWN) {
        const { docDay, hotelId, compsetId } = this.ratesPriceHistoryCommonService;
        const { documentFilters } = this.ratesPriceHistoryCommonService;
        const actualPriceShown = priceShown || this.documentFiltersService.priceShown;

        const priceHistory = docDay
            ? this.priceHistory(docDay as Day, hotelId, compsetId, documentFilters) as Record<string, RatesDocumentItemAllModel>[]
            : null;

        if (!priceHistory) return [];

        return priceHistory
            .map(day => {
                if (!day || !Object.keys(day).length || !day[provider]) {
                    return null;
                }

                return this.ratesCommonService
                    .switchPrice(day[provider].room, actualPriceShown);
            })
            .reverse();
    }

    priceHistory(
        actualDay: Day | null,
        hotelId: number | null,
        compsetId: string | null = null,
        documentFilters: DocumentFiltersModel | null = null,
    ): {[provider: string] : RatesDocumentItemAllModel}[] | null {
        this.ratesPriceHistoryCommonService.setData(actualDay, hotelId, compsetId, documentFilters);

        const newDays = this.ratesPriceHistoryCommonService.sortedDaysList
            .map((_: number, index: number) => this.getSuitableProviderByDay(index)
                || {} as {[provider: string]: RatesDocumentItemAllModel});
        return this.addAverageToHistory(newDays);
    }
}
