import { Inject, injectable } from 'inversify-props';

import { MAIN_DOCUMENT } from '@/modules/rates/constants';
import type Day from '@/modules/common/types/day.type';
import Price from '@/modules/common/types/price.type';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import COMPSET_TYPE from '@/modules/compsets/constants/compset-type.constant';

import UserService, { UserServiceS } from '@/modules/user/user.service';
import CompsetMainModel from '@/modules/cluster/models/compset-main.model';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import ClusterCompsetsService, { ClusterCompsetsServiceS } from '@/modules/cluster/cluster-compsets.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';

import RatesSettingsModel from '@/modules/rates/models/rates-settings.model';
import RatesDocumentModel from '@/modules/rates/models/rates-document.model';
import RatesDocumentAllModel from '@/modules/rates/models/rates-document-all.model';
import RatesAnalysisService, { RatesAnalysisServiceS } from '@/modules/rates/rates-analysis.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import RatesStore from '@/modules/rates/store/rates.store';
import ClusterStore from '@/modules/cluster/store/cluster.store';

import RatesPriceHistoryApiService, { RatesPriceHistoryApiServiceS } from './rates-price-history-api.service';
import RatesPriceHistoryModel from './models/rates-price-history.model';
import RatesPriceHistoryFilterAllModel from './models/rates-price-history-filter-all.model';
import RatesPriceHistoryStore from './store/rates-price-history.store';

export const RatesPriceHistoryCommonServiceS = Symbol.for('RatesPriceHistoryCommonServiceS');
@injectable(RatesPriceHistoryCommonServiceS as unknown as string)
export default class RatesPriceHistoryCommonService implements Stateable {
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade; @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(ClusterCompsetsServiceS) private clusterCompsetsService!: ClusterCompsetsService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(RatesPriceHistoryApiServiceS) private ratesPriceHistoryApiService!: RatesPriceHistoryApiService;
    @Inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @Inject(RatesAnalysisServiceS) private ratesAnalysisService!: RatesAnalysisService;
    @Inject(RatesAnalysisFiltersServiceS) private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;
    @Inject(UserSettingsS) private userSettingsService!: UserSettingsService;

    readonly ratesStoreState: RatesStore = this.storeFacade.getState('RatesStore');
    readonly clusterStoreState: ClusterStore = this.storeFacade.getState('ClusterStore');
    readonly storeState: RatesPriceHistoryStore = this.storeFacade.getState('RatesPriceHistoryStore');
    hotelId: number | null = null;
    compsetId: string | null = null;
    documentFilters: DocumentFiltersModel | null = null;
    competitors: number[] | null = null;

    constructor() {
        this.storeState.loading.reset();
    }

    async loadData(): Promise<boolean> {
        const isClusterPage = !window.location.pathname.includes('hotel');
        const isAnalysisMode = window.location.pathname.includes('analysis');

        if (!this.docDay || !this.documentId || !this.ratesSettings) {
            if (!isClusterPage) {
                await this.ratesStoreState.loading.whenLoadingFinished();
                const { document, settings } = this.ratesStoreState;
                this.initRatesData(document, settings);
            }
        }

        if (!this.docDay || !this.documentId || !this.ratesSettings) {
            return false;
        }

        if (!isClusterPage) {
            const { displayCurrency } = this.userSettingsService;

            this.currency = displayCurrency || this.currency;
        }

        const day = this.docDay as Day;

        const mainDocPromise = this.ratesPriceHistoryApiService
            .getRatesPriceHistoryByDay(day, this.documentId, this.ratesSettings, this.currency);

        const promises = [mainDocPromise];

        if (isAnalysisMode) {
            this.ratesAnalysisService.data.forEach((doc, index) => {
                const compareSettings = this.ratesAnalysisService.getSettings(index);
                if (!doc) {
                    promises.push(Promise.resolve(null));
                    return;
                }

                const reqPromise = this.ratesPriceHistoryApiService
                    .getRatesPriceHistoryByDay(day, doc.id, compareSettings, this.currency);

                promises.push(reqPromise);
            });
        }

        const data = await Promise.all(promises);

        data.forEach((doc, index) => {
            const docIndex = index - 1;

            if (docIndex === MAIN_DOCUMENT) {
                this.storeState.documents.main = doc;
                return;
            }

            const compareName = this.ratesAnalysisFiltersService.comparisonValues[docIndex].name;

            this.storeState.documents[compareName] = doc;
        });

        this.calculateLastScanDate();

        return true;
    }

    setData(
        actualDay: Day | null,
        hotelId: number | null,
        compsetId: string | null = null,
        documentFilters: DocumentFiltersModel | null = null,
    ) {
        this.hotelId = hotelId || this.userService.currentHotelId;
        this.compsetId = compsetId || null;
        this.competitors = compsetId
            ? this.clusterCompsetsService.getCompetitors(compsetId)
            : this.compsetsService.competitors;
        this.documentFilters = documentFilters;

        this.checkIsDocumentUpToDate(actualDay);
        this.helperService.dynamicLoading(
            this.storeState.loading,
            this.loadData.bind(this),
        );
    }

    calculateLastScanDate() {
        const { main } = this.storeState.documents;
        if (!main || !this.docDay) {
            return;
        }

        const lastScanDateKey = this.getScanDate(main, this.docDay);

        if (!lastScanDateKey) {
            return;
        }

        const [dateDay, dateMonth, dateYear] = lastScanDateKey.split('-');
        this.storeState.lastScanDate = new Date(Number(dateYear), Number(dateMonth) - 1, Number(dateDay));
    }

    getScanDate(document: RatesPriceHistoryModel | RatesPriceHistoryFilterAllModel, documentDay: number): string | null {
        const { month, year } = this.documentFiltersService;
        const selectedDate = new Date(year, month, documentDay);
        if (!document.trendData) {
            return null;
        }

        return Object.keys(document.trendData)
            .filter(key => {
                const data = document ? document.trendData[key] : null;

                if (!data) {
                    return false;
                }

                let hasCompetitors = false;

                if (!data.hotels) {
                    hasCompetitors = !!Object.keys(data).length;
                } else {
                    hasCompetitors = !!Object.keys(data.hotels).length;
                }

                if (hasCompetitors) {
                    const [dateDay, dateMonth, dateYear] = key.split('-');
                    const historyDate = new Date(Number(dateYear), Number(dateMonth) - 1, Number(dateDay));
                    return historyDate <= selectedDate;
                }
                return false;
            }).sort((key1, key2) => {
                const [dateDay1, dateMonth1, dateYear1] = key1.split('-');
                const [dateDay2, dateMonth2, dateYear2] = key2.split('-');
                const date1 = new Date(Number(dateYear1), Number(dateMonth1) - 1, Number(dateDay1));
                const date2 = new Date(Number(dateYear2), Number(dateMonth2) - 1, Number(dateDay2));
                if (date1 > date2) {
                    return 1;
                }
                if (date1 < date2) {
                    return -1;
                }
                return 0;
            }).reverse()[0];
    }

    get ratesDoc() {
        return this.storeState.ratesDoc;
    }

    set ratesDoc(value) {
        this.storeState.ratesDoc = value;
    }

    get isAllChannels() {
        return this.ratesDoc?.providerName === 'all';
    }

    get documentId() {
        let id = this.ratesDoc ? Number(this.ratesDoc.id) : null;
        if (this.ratesDoc instanceof CompsetMainModel) {
            id = Number(this.ratesDoc.documentId);
        }
        return id;
    }

    get comparisonValues() {
        return this.ratesAnalysisFiltersService.comparisonValues;
    }

    get sortedDaysList() {
        if (!this.lastScanDate) {
            return [];
        }
        const scanDay = this.lastScanDate.getDate();
        const {
            month,
            year,
            days,
        } = this.documentFiltersService;
        const maxDaysPrevMonth = new Date(year, month + 1, 0).getDate();
        const currentMonthSegment = Array(scanDay)
            .fill(null)
            .map((_, i) => i + 1);
        const prevMonthSegment = days.length === scanDay ? [] : Array(Math.abs(maxDaysPrevMonth - scanDay))
            .fill(null)
            .map((_, i) => i + scanDay + 1);
        return [...prevMonthSegment, ...currentMonthSegment].reverse();
    }

    set ratesSettings(value: RatesSettingsModel| null) {
        this.storeState.ratesSettings = value;
    }

    get ratesSettings() {
        return this.storeState.ratesSettings;
    }

    get lastScanDate() {
        return this.storeState.lastScanDate;
    }

    get loading() {
        return this.storeState.loading;
    }

    set documents(value) {
        this.storeState.documents = value;
    }

    get documents() {
        return this.storeState.documents;
    }

    get currency() {
        return this.storeState.currency;
    }

    set currency(value: string | null) {
        this.storeState.currency = value;
    }

    get docDay() {
        return this.storeState.documentDay;
    }

    set docDay(value: number | null) {
        this.storeState.documentDay = value;
    }

    initRatesData(data: RatesDocumentModel | RatesDocumentAllModel | CompsetMainModel | null, settings: RatesSettingsModel | null) {
        this.currency = data && data.currency ? data.currency : null;
        this.ratesSettings = settings;
        this.ratesDoc = data;
        this.documents = { main: null };
        this.storeState.loading.reset();
    }

    checkIsDocumentUpToDate(actualDay: Day | null) {
        const { finishDate, startDate } = this.storeState.loading;
        if (this.docDay as Day !== actualDay
            && ((finishDate === null && startDate === null) || (finishDate !== null && startDate !== null))) {
            this.docDay = actualDay;
            this.storeState.loading.reset();
        }
    }

    setTableDay(label?: string) {
        if (!label) {
            this.storeState.dayIndex = 0;
            return;
        }

        const matches = label.match(/(\d+)/);
        const dayIndex = matches ? matches[0] : null;

        if (dayIndex !== null) {
            this.storeState.dayIndex = Number(dayIndex);
        }
    }

    getCompsetPriceHistory(
        day: Day,
        doc: RatesDocumentModel,
        settings: RatesSettingsModel,
        compsetId: string,
    ): Price | null {
        const neededCompset = compsetId ? this.compsetsService.getCompset(compsetId) : this.compsetsService.currentCompset;
        if (!neededCompset) {
            return null;
        }

        return this.getPriceHistoryByCompsetType(day, doc, settings, neededCompset.type);
    }

    getPriceHistoryByCompsetType(
        day: Day,
        doc: RatesDocumentModel,
        settings: RatesSettingsModel,
        compsetType: COMPSET_TYPE,
    ): Price | null {
        const newSettings = {
            ...settings,
            competitors: this.competitors,
        };

        if (!this.hotelId) return null;

        const rooms = this.ratesCommonService
            .getCompetitorsRooms(day, newSettings, doc);

        delete rooms[this.hotelId];

        const { priceShown } = this.documentFiltersService;

        return this.ratesCommonService
            .getCompsetPrice(rooms, compsetType, priceShown);
    }
}
