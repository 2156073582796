import { Module, VuexModule } from 'vuex-module-decorators';
import { POS_VALUES } from '@/modules/user/constants';
import { Notifications } from '@/modules/user/types';
import COMPARED_TO_SETTINGS from '@/modules/score/constants/compared-to.settings.constant';
import LoadingModel from '@/modules/common/models/loading.model';
import CurrencyListModel from '../models/currency-list.model';

@Module
export default class UserSettings extends VuexModule {
    pos: string = POS_VALUES.US;
    notifications: Notifications[] = [];
    comparedTo: COMPARED_TO_SETTINGS = 1;

    saveRequestLoading = new LoadingModel();
    compsetSettingsLoading = new LoadingModel();
    compsetSettings = {
        htmlReportDefaultProvider: 'booking',
    };
    compsetAllowedReportProviders = [];

    subscriptions: UserAPI.Subscriptions = {
        htmlReports: true,
        scheduledReports: true,
    };

    intraday!: UserAPI.IntradaySettings;
    alertsAndNotifications!: UserAPI.AlertsAndNotifications;
    alertsAndNotificationsByGroup!: UserAPI.AlertsNotifcationsGroups;

    hotelAlertsAndNotifications: {
        hotels: UserAPI.IUserHotelAlerts | null,
        loading: LoadingModel,
    } = {
        hotels: null,
        loading: new LoadingModel(),
    };

    currencies: {
        list: CurrencyListModel | null,
        displayCurrency: string | null,
        loading: LoadingModel,
    } = {
        list: null,
        displayCurrency: null,
        loading: new LoadingModel(),
    };

    chartColors: {
        list: string[] | null,
        loading: LoadingModel,
    } = {
        list: null,
        loading: new LoadingModel(),
    };

    defaultMapProvider = window.localStorage.getItem('ci.defaultMapProvider') || 'GoogleMap';
}
