import CompsetMainModel from '@/modules/cluster/models/compset-main.model';
import { Expose, plainToClass, Transform } from 'class-transformer';
import {
    IsOptional, Validate,
} from 'class-validator';
import RatesDocumentItemModel from '@/modules/rates/models/rates-document-item.model';
import RatesCheckinDatesValidator from '@/modules/rates/models/validators/checkin-dates.validator';
import { SCAN_STATUS } from '@/modules/rates/constants';

export default class RatesCompsetMainModel extends CompsetMainModel {
    @Expose()
    @Transform((_, plain) => {
        const checkinDates: any = {};

        if (!plain.checkin_dates) {
            return plain.checkin_dates;
        }

        Object.keys(plain.checkin_dates).forEach(day => {
            checkinDates[Number(day)] = { hotels: {}, demand: null };
            const checkinDay = checkinDates[Number(day)];
            const plainCheckinDay = plain.checkin_dates[Number(day)];

            Object.keys(plain.checkin_dates[Number(day)]).forEach(hotelId => {
                if (hotelId === 'update_date') {
                    checkinDay.updateDate = plainCheckinDay.update_date
                        ? plainCheckinDay.update_date
                        : null;

                    return;
                }

                if (hotelId === 'day_statistics') {
                    const { day_statistics: dayStatistics } = plainCheckinDay;

                    checkinDay.demand = null;
                    checkinDay.occupancy = null;

                    if (!dayStatistics) {
                        return;
                    }

                    if (Number.isFinite(dayStatistics.demand)) {
                        if (dayStatistics.demand < 0) {
                            checkinDay.demand = 0;
                        } else {
                            checkinDay.demand = Math.round(dayStatistics.demand * 100);
                        }
                    }

                    if (Number.isFinite(dayStatistics.occupancy)) {
                        if (dayStatistics.occupancy < 0) {
                            checkinDay.occupancy = 0;
                        } else {
                            checkinDay.occupancy = Math.round(dayStatistics.occupancy);
                        }
                    }
                }

                checkinDay.hotels[hotelId] = { rooms: {}, updateDate: undefined, link: null };

                const hotel = checkinDay.hotels[hotelId];
                const plainHotel = plainCheckinDay[hotelId];
                const plainRooms = plainHotel.rooms || {};

                Object.keys(plainRooms).forEach(roomTypeId => {
                    hotel.rooms[roomTypeId] = [];

                    const plainRoomTypeRooms = plainRooms[roomTypeId] || {};
                    const ratesDocumentItems: RatesDocumentItemModel[] = [];

                    Object.entries(plainRoomTypeRooms)
                        .forEach(([__, ratesDocumentItem]) => {
                            ratesDocumentItems.push(
                                plainToClass(
                                    RatesDocumentItemModel,
                                    <RatesDocumentItemModel> ratesDocumentItem,
                                    { excludeExtraneousValues: true },
                                ),
                            );
                        });

                    hotel.rooms[roomTypeId] = ratesDocumentItems;
                    hotel.updateDate = plainCheckinDay[hotelId].update_date;
                    hotel.losRestriction = plainCheckinDay[hotelId].los_restricted;

                    if (hotel.updateDate) {
                        hotel.updateDate = new Date(hotel.updateDate);
                    }

                    hotel.link = plainCheckinDay[hotelId].link;
                    hotel.taskId = plainCheckinDay[hotelId].task_id;
                    hotel.screenshot = plainCheckinDay[hotelId].screenshot;
                });
            });
        });

        return checkinDates;
    })
    @IsOptional()
    @Validate(RatesCheckinDatesValidator)
    checkinDates?: {
        [day: number]: {
            hotels: {
                [hotelId in (number | string)]: {
                    rooms: {
                        [roomTypeId: number]: RatesDocumentItemModel[];
                    };
                    updateDate?: Date;
                    link: string | null;
                    losRestriction?: number;
                };
            };
            demand?: number | null;
            occupancy?: number | null;
        } | null;
    };

    @Expose()
    @Transform((_, plain) => plain.status || SCAN_STATUS.FINISHED)
    scanStatus?: SCAN_STATUS;
}
