
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class NotificationWrapper extends Vue {
    @Prop({
        type: Object,
        required: false,
    })
    positionStyle!: Object;

    handleClose() {
        this.$emit('close');
    }
}
